import { SET_CURRENT_CONTRACT, ContractsTypes } from './constants';

const ContractStatusReducer = (state = null, { payload, type }) => {
	switch (type) {
		case ContractsTypes[SET_CURRENT_CONTRACT]:
			return payload;
		default:
			return state;
	}
};

export default ContractStatusReducer;
