import React, {Component} from "react";
import CreateEditPopup from "./CreateEditPopup";
import WHTable from "../../components/WHTable";
import {toast} from "react-toastify";
import {Card, CardHeader, CardBody, Badge, Button} from "reactstrap";
import * as memberService from "../../services/member";
import * as contractExportService from "../../services/contract/export";
import ContractsPopup from "./Popup/ContractsPopup";
import ExportPopup from "../Contract/Export/ExportPopup";
import {connect} from "react-redux";
import {getIsOnline} from "../../components/OnlineStatus/redux";
import {getUser, getUserRoles} from "../Authentication/redux";
import {redirectToDashboard, redirectToPage404} from "../../utils/redirectTo";
import Swal from "sweetalert2";
import * as AppointmentService from "../../services/appointment";

class Members extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			col: [],
			pages: null,
			loaded: true
		};
	}

	columns = () => {
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color="orange"
							onClick={() =>
								this.refs.ContractsPopup.handleOpen(row.value)
							}
						>
							<i className="fa fa-eye"/>
						</Button>
						<Button
							color="info"
							onClick={() =>
								this.refs.createEditPopup.handleOpen(row.value)
							}
						>
							<i className="fa fa-edit"/>
						</Button>
						<Button
							color="success"
							onClick={() => this.sendNewPassword(row.value)}
						>
							<i className="fa fa-send"/>
						</Button>
						<Button
							color="danger"
							onClick={() => this.remove(row.value)}
						>
							<i className="fa fa-trash"/>
						</Button>
					</div>
				),
				maxWidth: 170
			},
			{
				Header: "Login",
				accessor: "username"
			},
			{
				Header: "Email",
				accessor: "email"
			},
			{
				Header: "Status",
				accessor: "enabled",
				filterable: false,
				Cell: row => (
					<div>
						{row.value && <Badge color="success">Active</Badge>}
						{!row.value && <Badge color="danger">Désactivé</Badge>}
					</div>
				)
			},
			{
				Header: "Role(s)",
				accessor: "roles",
				Cell: row => <div>{row.value.join()}</div>
			}
		];
	};

	load = state => {
		this.setState({
			loaded: false
		});

		return memberService
			.search({
				...state,
				filtered: [
					...state.filtered,
					{id: "enabled", value: this.props.enabledUsers}
				]
			})
			.then(data => {
				this.setState(
					{
						data: []
					},
					() => {
						this.setState({
							data: data["hydra:member"],
							pages: Math.ceil(
								data["hydra:totalItems"] / state.pageSize
							),
							loaded: true
						});
					}
				);
			});
	};

	export = () => {
		contractExportService.exportAllCSV().then(data => {
		});
	};

	sendNewPassword = id => {

		Swal.fire({
			title: "Nouveau Mot de Passe ",
			text: "Êtes-vous sûr de vouloir envoyer un nouveau mot de passe ?",
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#3085d6',
			denyButtonColor: '#d33',
			confirmButtonText: 'Envoyer',
			denyButtonText: 'Annuler',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					"Le nouveau mot de passe a bien été envoyé",
					'',
					'success'
				)
				this.setState({
					loaded: false
				});

				memberService
					.sendNewPassword(id)
					.then(() => {
						this.setState({
							loaded: true
						});
					})
			} else if (result.isDenied) {
				Swal.fire(
					"Le nouveau mot de passe n'a pas été envoyé",
					'',
					'error'
				)
			}
		})

		/*if (
			window.confirm(
				"Êtes-vous sûr de vouloir envoyer un nouveau mot de passe ?"
			)
		) {
			this.setState({
				loaded: false
			});

			memberService
				.sendNewPassword(id)
				.then(() => {
					this.setState({
						loaded: true
					});

					toast.success("Un noueau mot de passe a été envoyé");
				})
				.catch(() => {
					this.setState({
						loaded: true
					});

					toast.error("Une erreur est survenue");
				});
		}*/
	};

	remove = toDelete => {

		Swal.fire({
			title: "Supprimer",
			text: "Êtes vous sûr de vouloir supprimer ce membre ?",
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#3085d6',
			denyButtonColor: '#d33',
			confirmButtonText: 'Supprimer',
			denyButtonText: 'Annuler',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					"Le membre a bien été supprimé",
					'',
					'success'
				)

				this.setState({
					loaded: false
				});

				memberService
					.remove(toDelete)
					.then(() => {
						const lst = this.state.data;

						const index = lst.findIndex(el => el.id === toDelete);

						if (index > -1) {
							lst.splice(index, 1);

							this.setState({
								data: lst,
								loaded: true
							});
						}
					})

			} else if (result.isDenied) {
				Swal.fire(
					"Le membre n'a pas été supprimé",
					'',
					'error'
				)
			}
		})

		/*if (window.confirm("Êtes-vous sûr de vouloir supprimer ce membre test ?")) {
			this.setState({
				loaded: false
			});

			memberService
				.remove(toDelete)
				.then(() => {
					const lst = this.state.data;

					const index = lst.findIndex(el => el.id === toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({
							data: lst,
							loaded: true
						});
					}
				})
				.catch(() => {
					alert(
						"Il existe des dépendances sur membre, il ne peut donc être supprimé"
					);
				});
		}*/
	};

	newData = data => {
		const lst = this.state.data;

		const index = lst.findIndex(el => el.id === data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({
			data: lst
		});
	};

	componentDidMount() {
		if (!(this.props.roles.includes('ROLE_ADMIN'))) {
			if (this.props.roles.includes('ROLE_SELLER') ||
				this.props.roles.includes('ROLE_TECHNICIEN_SAV') ||
				this.props.roles.includes('ROLE_TECHNICO_COMMERCIAL')) {
				redirectToPage404()
							}
		}
	}

	render() {
		const {data, pages} = this.state;


		const {enabledUsers} = this.props;

		return (
			<div className="animated fadeIn">
				<CreateEditPopup ref="createEditPopup" newData={this.newData}/>
				<ContractsPopup ref="ContractsPopup"/>
				<Card className="border-card">
					<CardHeader className="card-header-contract">
						<h2>Gestion des membres{" "}
						{enabledUsers ? "activés" : "désactivés"}</h2>
						<div className="card-actions">
							<button
								onClick={() =>
									this.refs.createEditPopup.handleOpen()
								}
								className="button-create button-text"
							>
								<i className="fa fa-plus fa-lg icone-button-text"/>
								&nbsp;Nouveau
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	}
}

export const mapStateToProps = state => ({
	roles: getUserRoles(state),
	user: getUser(state),
});

export default connect(
	mapStateToProps,
)(Members);
