import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CatalogTemplate from '..';
import getColumns from './utils';
import Form from './PopupForm';
import {
    getPricesList,
    getPricesPages,
    loadPrices,
    deletePrice,
    createPrice,
    updatePrice,
    fetchPrice,
    getPricesEditedItem,
} from './redux';
import {resetPrice} from "./redux/actions";

const Prices = ({ onLoad, onRemove, data, pages, onCreate, onUpdate, onFetch, className,onReset }) => (
    <CatalogTemplate
        removeMessage="Êtes-vous sûr de vouloir supprimer cette grille tarifaire ?"
		removeMessageObject="La grille tarifaire"
        title="Gestion des tarifs"
        onLoad={onLoad}
        onRemove={onRemove}
        data={data}
        pages={pages}
        getPopupData={getPricesEditedItem}
        getColumns={getColumns}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onFetch={onFetch}
		onReset={onReset}
        className={className}
        FormComponent={Form}
    />
);

Prices.defaultProps = { className: '' };

Prices.propTypes = {
    onLoad: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    pages: PropTypes.number.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onFetch: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export const mapStateToProps = state => ({
    data: getPricesList(state),
    pages: getPricesPages(state),
});

export const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            onLoad: loadPrices,
            onRemove: deletePrice,
            onCreate: createPrice,
            onUpdate: updatePrice,
            onFetch: fetchPrice,
			onReset: resetPrice
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Prices);
