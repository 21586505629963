import pricesTypes from './types';
import { SET_PRICES } from './constants';
import { SET_LOADED, SET_DATA, SET_PAGES, SET_EDITED, REMOVE_ITEM } from '../../redux/constants';
import { init, search, view, create, update, remove } from '../../../../services/catalog/price';
import { createAction } from 'redux-friends';
import formatDataForRedux from '../../../../utils/formatDataForRedux';
export const setPrices = createAction(pricesTypes[SET_PRICES]);
export const setPricesLoadedState = createAction(pricesTypes[SET_LOADED]);
export const setPricesList = createAction(pricesTypes[SET_DATA]);
export const setPricesPages = createAction(pricesTypes[SET_PAGES]);
export const setEditedPrice = createAction(pricesTypes[SET_EDITED]);
export const removePrice = createAction(pricesTypes[REMOVE_ITEM]);

export const fetchPrices = () => async dispatch => {
    try {
        const data = await init();
        dispatch(setPrices(formatDataForRedux(data, 20)));
    } catch (error) {
        console.error('fetchPrices', { error });
        throw new Error(error);
    }
};

export const loadPrices = (state = {}) => async dispatch => {
    try {
        const data = await search(state);
        dispatch(setPrices(formatDataForRedux(data, state.pageSize)));
    } catch (error) {
        console.error({ error });
    }
};

export const deletePrice = toDelete => async dispatch => {
    try {
        await remove(toDelete);
        dispatch(removePrice(toDelete));
		dispatch(loadPrices());
    } catch (error) {
        console.error({ error });
        alert(
            'Il existe des dépendances sur cette grille tarifaire, elle ne peut donc pas être supprimée'
        );
    }
};

const formatPriceDates = data => {
    if (data.monthly) {
        data.monthly = parseFloat(data.monthly);
    }
    if (data.remise) {
        data.remise = parseFloat(data.remise);
    }

    return data;
};

const createOrUpdate = (isCreating = true) => item => async dispatch => {
    try {
        await (isCreating ? create : update)(formatPriceDates(item));
        dispatch(loadPrices());
    } catch (error) {
        console.error({ error });
    }
};

export const createPrice = createOrUpdate();
export const updatePrice = createOrUpdate(false);

export const fetchPrice = id => async dispatch => {
    try {
        const data = await view(id);
        data.id = `equipment=${data.equipment.id};levelService=${data.levelService.id}`;
        data.equipment = data.equipment['@id'];
        data.levelService = data.levelService['@id'];
        dispatch(setEditedPrice(data));
    } catch (error) {
        console.error({ error });
    }
};
export const resetPrice = () => async dispatch => {
	try {
		const data = {};
		dispatch(setEditedPrice(data));
	} catch (error) {
		console.error({ error });
	}
};
