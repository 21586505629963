import React, { Component } from 'react';
import {
	ModalBody,
	Button,
	Input,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	CardText,
} from 'reactstrap';

import PropTypes from 'prop-types';

import * as customerService from '../../../../services/customer';
import Loading from '../../../../components/Loading';

class SearchForm extends Component {
	state = {
		loaded: true,
		search: null,
		results: [],
	};

	handleChange = async event => {
		event.preventDefault();
		this.setState({ loaded: false });
		const { value } = event.target;
		this.setState({ search: value });
		if (value == '') {
			this.setState({ results: [], loaded: true });
			return;
		}

		try {
			const data = await customerService.search({
				filtered: [{ id: 'lastName', value }],
				sorted: [{ id: 'lastName' }],
				pageSize: 5,
			});
			this.setState({ results: data['hydra:member'], loaded: true });
		} catch (error) {
			console.error(error);
		}
	};

	onCreate = value => () => this.props.create(value);

	render() {
		const { loaded, search } = this.state;

		const createPayload = search ? { lastName: search } : null;
		const shouldDisplay = this.state.search && this.state.search.length > 2;
		return (
			<div>
				<ModalBody>
					<Input
						placeholder="saisir un nom"
						bsSize="lg"
						value={this.state.value}
						onChange={this.handleChange}
					/>
					{shouldDisplay && (
						<div>
							{!loaded && <Loading />}
							{this.state.results.map((el, index) => (
								<Card key={index}>
									<CardBody>
										<CardTitle>
											{el.lastName}
											<Button
												className="pull-right"
												color="primary"
												onClick={this.onCreate(el)}>
												Sélectionner
											</Button>
										</CardTitle>
										<CardSubtitle>{el.saler && el.saler.name} </CardSubtitle>
										<CardText>
											{el.lastName && <span>{el.lastName} - </span>}
											{el.fisrtName && <span>{el.fisrtName} - </span>}
											{el.address && <span>{el.address}</span>}
											{el.zipCode && <span>{el.zipCode}</span>}
											{el.city && <span>{el.city}</span>}
										</CardText>
									</CardBody>
								</Card>
							))}
						</div>
					)}
				</ModalBody>

				<div>
					<Button size="lg" block color="primary" onClick={this.onCreate(createPayload)}>
						Créer un nouveau contrat
					</Button>
				</div>
			</div>
		);
	}
}

SearchForm.propTypes = {
	create: PropTypes.func.isRequired,
};

export default SearchForm;
