const QueryBuilderNest = (data) => {
	if (!data) return {};

	const pageSize = data.filtered.find(x => x.id === 'pageSize')
	const page = data.filtered.find(x => x.id === 'page')
	data.filtered.map(({ id, value }) => {
		data['filter.' + id] = value;
	});
	delete data.filtered;
	page ? data.page = page.value : data.page = 1
	pageSize ? data.limit = pageSize.value : data.limit = 20

	return data;
};

export default QueryBuilderNest;
