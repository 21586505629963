import React from 'react';
import PropTypes from 'prop-types';

import Header from './partials/Header';
import Footer from './partials/Footer';
import InfoBlock from './partials/InfoBlock';
import Loading from '../../../components/Loading';

import './index.scss';

const SepaPreview = ({ contract }) => {
    if (!contract) {
        return <Loading />;
    }
    const { customer, bic, iban, reference, place, sepaSignature } = contract;
    return (
        <div className="fixed">
            <div size="A4" layout="portrait" className="sepa preview">
                <Header reference={reference} />
                <InfoBlock customer={customer} bic={bic} iban={iban} />
                <Footer place={place} signature={sepaSignature} />
            </div>
        </div>
    );
};

SepaPreview.defaultProps = {
    signature: '',
};

SepaPreview.propTypes = {
    contract: PropTypes.shape({
        signature: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        customer: PropTypes.shape({}),
        bic: PropTypes.string,
        iban: PropTypes.string,
        reference: PropTypes.string,
        place: PropTypes.string,
    }).isRequired,
};

export default SepaPreview;
