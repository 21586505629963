import React, {Component} from 'react';
import {Nav, NavItem, NavbarToggler, NavbarBrand, NavLink} from 'reactstrap';
import HeaderDropdownAccount from './HeaderDropdownAccount';
import {getUser, getUserRoles} from "../../Authentication/redux";
import {connect} from "react-redux";
import WHBreadcrumb from "../Breadcrumb";

class Header extends Component {

	constructor(props) {

		super(props);

		this.state = {
			user: null,
		}
	}

    sidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-hidden');
    }

    mobileSidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-mobile-show');
    }

    render() {
        return (
            <header className="app-header navbar shadow-header">

                <NavbarToggler
                    className="d-lg-none"
                    onClick={this.mobileSidebarToggle}
                >
                    <span className="navbar-toggler-icon"/>
                </NavbarToggler>


                <NavbarBrand href="#" ><img src={'img/logo-bandeau-menu.png'} style={{maxWidth : '75%'}} alt=""/></NavbarBrand>

                <NavbarToggler
                    className="d-md-down-none sidebar-minimizer h-100 b-r-1"
                    onClick={this.sidebarToggle}
                >
                    <span className="navbar-toggler-icon"/>
                </NavbarToggler>

                <Nav navbar>
                    <NavItem className="d-md-down-none">
						<WHBreadcrumb />
                    </NavItem>
                </Nav>

                <Nav className="ml-auto" navbar>
                    <HeaderDropdownAccount
						userName={this.props.user.name}
					/>
                </Nav>

            </header>
        );
    }
}

const mapStateToProps = state => {
	return {
	location :state.routing.location,
	user: getUser(state),

}};

export default connect(
	mapStateToProps,
)(Header);

