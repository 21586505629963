import React from 'react';
import { Row, Col, Input, InputGroup, Alert } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import PropTypes from 'prop-types';

import 'ladda/dist/ladda-themeless.min.css';

const renderFieldLogin = ({ input }) => (
    <InputGroup className="mb-3">
        <div className="input-group-prepend">
            <span className="input-group-text">
                <i className="icon-user" />
            </span>
        </div>
        <Input type="text" placeholder="Login" {...input} />
    </InputGroup>
);

const renderFieldPassword = ({ input }) => (
    <InputGroup className="mb-4">
        <div className="input-group-prepend">
            <span className="input-group-text">
                <i className="icon-lock" />
            </span>
        </div>
        <Input type="password" placeholder="Mot de passe" {...input} />
    </InputGroup>
);

const FormLogin = ({ error, handleSubmit, submitting }) => (
    <form onSubmit={handleSubmit}>
        {error && <Alert color="danger">{error._error}</Alert>}

        <Field name="username" component={renderFieldLogin} type="text" />

        <Field name="password" component={renderFieldPassword} type="text" />

        <Row>
            <Col xs="6">
                <LaddaButton
                    className="btn btn-success btn-ladda"
                    loading={submitting}
                    data-style={EXPAND_LEFT}
                    type="submit"
                >
                    Login
                </LaddaButton>
            </Col>
        </Row>
    </form>
);

FormLogin.defaultProps = {
    error: null,
};

FormLogin.propTypes = {
    error: PropTypes.shape({}),
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

const formName = 'FormLogin';

export default reduxForm({ form: formName })(FormLogin);
