import React, {useEffect, useState} from 'react';
import * as contractService from "../../services/contract";
import moment from "moment";
import ReactECharts from 'echarts-for-react';
import {DateField} from "../../components/Form/wh-field";


function Graph({startDate, endDate, periodeName, setStartDate, setEndDate, statistiques}) {

	//********** Options des 2 pie Echarts contract **********//
	const getOptionsContracts = (textTitle, colorSeries, nameSeries, dataSeries) => {

		return (
			{
				title: {
					text: textTitle,
					left: 'center',
					bottom: '40px',
				},
				tooltip: {
					trigger: 'item',
				},
				legend: {
					left: 'center',
					bottom: '10px',
					itemGap: 25,
				},
				series: [
					{
						color: colorSeries,
						name: nameSeries,
						center: ['50%', '40%'],
						type: 'pie',
						radius: '70%',
						selectedMode: 'single',
						data: dataSeries,
						label: {
							show: false,
							position: 'outer',
							alignTo: 'labelLine',
							bleedMargin: 5
						},
						legendHoverLink: false,
						emphasis: {
							itemStyle: {
								shadowBlur: 20,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.8)'
							}

						}

					}
				]
			}
		)
	}

	//********** Options des 2 pie Echarts material **********//
	const getOptionsMaterials = (textTitle, colorSeries, nameSeries, dataSeries) => {
		const lengthGraphMaterial = statistiques.contract.equipmentFamilies.cancelled.equipments.length
		return (
			{
				title: {
					textAlign: 'center',
					text: textTitle,
					left: '223px',
					bottom: 'center',
					textStyle: {
						fontSize: '50px',
					}
				},
				tooltip: {
					trigger: 'item',
				},
				legend: {
					left: 400,
					top: 'center',
					orient: 'vertical',
					itemGap: lengthGraphMaterial === 4 ? 58
							: lengthGraphMaterial === 5 ? 48
							: lengthGraphMaterial === 6 ? 40
							: lengthGraphMaterial === 7 ? 35
							: lengthGraphMaterial === 8 ? 30
							: lengthGraphMaterial === 9 ? 25
							: lengthGraphMaterial === 10 ? 20
							: lengthGraphMaterial === 11 ? 16
							: lengthGraphMaterial === 12 ? 14
							: 10,
					padding: [10, 10, 10, 10,],
					textStyle: {
						fontSize: '14px',
					}

				},
				series: [
					{
						color: colorSeries,
						name: nameSeries,
						type: 'pie',
						center: ['35%', '50%'],
						radius: ['60%', '90%'],
						avoidLabelOverlap: false,
						selectedMode: 'single',
						label: {
							show: false,
							position: 'center',
						},
						emphasis: {
							itemStyle: {
								shadowBlur: 20,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.8)',
							}
						},
						labelLine: {
							show: false,
						},
						data: dataSeries,
					}
				]
			}
		)
	}

	//********** Styles pie Echart contract **********//
	const styleContract = {
		height: "350px",
		width: "400px"
	};

	//********** Styles pie Echart material **********//
	const styleMaterial = {
		height: "350px",
		width: "650px",
	}

	if (statistiques) {
		return (
			<>

				<div className="graph-selector">
					<div className="graph-title ">
						{periodeName}
					</div>
					<div className="graph-align">
						<div className="date-dashboard-field">
							<span className="text-date-field text-date-field-Dashboard"><div style={{marginRight: 5}}>Du</div> <div>:</div>  </span>
							{
								//********** date field startDate **********//
							}
							<DateField
								className={"field-date-dashboard field-filters"}
								value={startDate}
								onChange={(date) => {
									setStartDate(date)
							}}/>
						</div>

						{
							//********** date field endDate **********//
						}
						<div className="date-dashboard-field">
							<span className="text-date-field text-date-field-Dashboard" ><div style={{marginRight: 5}}>Au</div> <div>:</div> </span>
							<DateField
								className={"field-date-dashboard field-filters"}
								value={endDate}
								onChange={(date) => {
								   setEndDate(date)
								}}/>
						</div>
					</div>
				</div>
				<div className="graph-tie">
					<div className="graph-types graph-margin-right box-shadow">
						<div className=" title-types">
							Types de Contrats
						</div>
						<div className="graph-tie ">

							{
								//********** Options pie Echart contract sold **********//
							}
							<ReactECharts

								option={getOptionsContracts(
									`Contrat vendus : ${statistiques.contract.sold.total}`,
									['#8CD494', '#518CC4',],
									'Contrats Vendus',
									[
										{
											value: `${statistiques.contract.sold.essentiel}`,
											name: `Contrats essentiels (${statistiques.contract.sold.essentiel})`
										},
										{
											value: `${statistiques.contract.sold.confort}`,
											name: `Contrats conforts (${statistiques.contract.sold.confort})`
										},
									],
								)}
								style={styleContract}
							/>

							{
								//********** Options pie Echart contract cancelled **********//
							}
							<ReactECharts
								option={getOptionsContracts(
									`Contrats annulés : ${statistiques.contract.cancelled.total}`,
									['#FF9B85', '#FFD97D'],
									'Contrats Annulés',
									[
										{
											value: `${statistiques.contract.cancelled.essentiel}`,
											name: `Contrats essentiels (${statistiques.contract.cancelled.essentiel})`
										},
										{
											value: `${statistiques.contract.cancelled.confort}`,
											name: `Contrats conforts (${statistiques.contract.cancelled.confort})`
										},
									],
								)}
								style={styleContract}
							/>
						</div>
					</div>
					<div className="graph-types-equipments grey-background">

						{
							//********** Tableau familles d'équipement **********//
						}
							{
								Object.keys(statistiques.contract.sold.equipmentFamilies).map(key => {
									if (statistiques) {
										return (
											<div className={`table-lign  box-shadow table-margin-${key}`} key={key}>
												<div
													className="case-products value-sold-essential">{statistiques.contract.sold.equipmentFamilies[key].essentiel}</div>
												<div
													className="case-products value-sold-confort">{statistiques.contract.sold.equipmentFamilies[key].confort}</div>
												<div
													className="name-products">{statistiques.contract.sold.equipmentFamilies[key].name}</div>
												<div
													className="case-products value-cancelled-essential">{statistiques.contract.cancelled.equipmentFamilies[key].essentiel}</div>
												<div
													className="case-products value-cancelled-confort">{statistiques.contract.cancelled.equipmentFamilies[key].confort}</div>
											</div>
										)
									}

								})
							}


					</div>
				</div>

				<div className="graph-tie">
					<div className="graph-types box-shadow">
						<div className="title-types">
							Types de Matériels
						</div>
						<div className="graph-tie ">
							{
								//********** Options pie Echart material sold **********//
							}
							<ReactECharts
								option={getOptionsMaterials(
									`${statistiques.contract.equipmentFamilies.sold.total}`,
									['#8CD494', '#5CB997', '#389D94', '#2A8088', '#2D6373', '#2F4858'],
									'Matériels Vendus',

									statistiques.contract.equipmentFamilies.sold.equipments.map((equipeFamily, key) => {
										return (
											{
												value: `${equipeFamily.total}`,
												name: `${equipeFamily.name} (${equipeFamily.total})`
											}
										)
									})
								)}
								style={styleMaterial}
							/>

							{
								//********** Options pie Echart material cancelled **********//
							}
							<ReactECharts
								option={getOptionsMaterials(
									`${statistiques.contract.equipmentFamilies.cancelled.total}`,
									['#FF9B85', '#DF8295', '#B0739B', '#7B6891', '#5F4C79', '#46345D'],
									'Matériels Annulés',

									statistiques.contract.equipmentFamilies.cancelled.equipments.map((equipeFamily, key) => {
										return (
											{
												value: `${equipeFamily.total}`,
												name: `${equipeFamily.name} (${equipeFamily.total})`
											}
										)
									})
								)}
								style={styleMaterial}
							/>
						</div>
					</div>
				</div>
			</>
		)
	} else return null
}

export default Graph;
