import TvaForm from "../Contract/Form/Views/TvaForm";
import React from "react";

function AttestationTVA() {


	const initialTVA = {
		"cac1": "1",
		"cac2": "1",
		"cac3": "1",
		"cac4": true,
		"cac5": true,
		"cac12": true,
		"cac13": true,
		"cac14": true,
	}



	return (
		<TvaForm initialValues={initialTVA} alone={true}/>
	)


}

export default AttestationTVA
