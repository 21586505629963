const formatDataNestForRedux = (data,pageSize = 20 ) => ({
    data: data.pagination.data,
    pages: data.pagination.meta.totalPages,
    loaded: true,
	totalContract: data.pagination.meta.totalItems,
	pageSize: data.pagination.meta.itemsPerPage,
	parc: data?.parc,
	pasParc: data?.pasParc,
});

export default formatDataNestForRedux;

