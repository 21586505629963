import saveAs from 'file-saver';

import configApi from '../../constants/ApiConfig';
import { getJwtToken } from '../../utils/session';



const endPoints = '/api/customers';

export const exportPropioCSV = data =>
    fetch(`${configApi.url}${endPoints}/export-proprietaires-csv`, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        },
        responseType: 'blob',
        body: JSON.stringify(data),
    })
        .then(response => response.blob())
        .then(blob => saveAs(blob, 'propritaires.csv'));

export const exportLocaCSV = () =>
    fetch(`${configApi.url}${endPoints}/export-locataires-csv`, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        },
        responseType: 'blob',
        body: JSON.stringify(),
    })
        .then(response => response.blob())
        .then(blob => saveAs(blob, 'locataires.csv'));

