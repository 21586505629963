import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FieldTxt } from '../../components/Form/wh-field';
import { Card, CardHeader, CardBody, CardFooter, Form, Alert } from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

const FormMyInformations = ({ error, handleSubmit, submitting }) => (
    <Card className="border-card">
        <Form onSubmit={handleSubmit} className="form-horizontal">
            <CardHeader className="card-header-contract">
				<h2>Mon compte</h2>
			</CardHeader>

            <CardBody>
                <Field label="Login : " name="username" component={FieldTxt} type="text" />
                <Field label="Email : " name="email" component={FieldTxt} type="text" />
                {error && <Alert color="danger">{error}</Alert>}

                <button type="submit" style={{ position: 'absolute', left: '-100000px' }} />
            </CardBody>

            <CardFooter>
                <LaddaButton
                    className="btn btn-success btn-ladda"
                    loading={submitting}
                    data-style={EXPAND_LEFT}
                    type="submit"
                >
                    <i className="fa fa-save" />
                    &nbsp;Enregistrer
                </LaddaButton>
            </CardFooter>
        </Form>
    </Card>
);

export default reduxForm({ form: 'FormMyInformations' })(FormMyInformations);
