import React from 'react';
import { Route, Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import routes from './routes';

const findRouteName = url => routes[url];
/* eslint-disable react/prop-types */

const getPaths = pathname => {
    const paths = ['/'];

    if (pathname === '/') return paths;

    pathname.split('/').reduce((prev, curr) => {
        const currPath = `${prev}/${curr}`;
        paths.push(currPath);
        return currPath;
    });
    return paths;
};

const BreadcrumbsItem = ({ match: { url, isExact } }) => {
    const routeName = findRouteName(url);
    if (routeName) {
        return isExact ? (
            <BreadcrumbItem active>{routeName}</BreadcrumbItem>
        ) : (
            <BreadcrumbItem>
                <Link to={url || ''}>{routeName}</Link>
            </BreadcrumbItem>
        );
    }
    return null;
};

const Breadcrumbs = ({ location: { pathname } }) => {
    const paths = getPaths(pathname);
    const items = paths.map((path, i) => (
        <Route key={i++} path={path} component={BreadcrumbsItem} />
    ));
    return <Breadcrumb>{items}</Breadcrumb>;
};

const WHBreadcrumb = props => (
    <div>
        <Route path="/:path" component={Breadcrumbs} {...props} />
    </div>
);
export default WHBreadcrumb;
