import React, { Component } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import SearchForm from './SearchForm';
import Loading from '../../../components/Loading';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { addContractToSync } from '../../Contract/redux';
import { getDefaultData } from '../../Contract/utils';

class CreateEditPopup extends Component {
	state = { loaded: true, open: false };

	handleOpen = () => this.setState({ open: true });

	handleClose = () => this.setState({ open: false });

	create = (data = null) => {
		this.setState({ loaded: false });
		const dataToSend = getDefaultData();
		if (data) {
			dataToSend.customer = data;
		}
		this.props.onSaveContract(dataToSend);
		this.props.edit(dataToSend);
		this.setState({ loaded: true });
	};

	render() {
		const { className } = this.props;
		const { loaded, open, edit } = this.state;
		const label = edit ? 'Editer' : 'Nouveau';
		return (
			<Modal isOpen={open} className={className} fade={false}>
				<ModalHeader toggle={this.handleClose}>{label}</ModalHeader>
				{loaded ? (
					<SearchForm create={this.create} close={this.handleClose} />
				) : (
					<Loading />
				)}
			</Modal>
		);
	}
}

CreateEditPopup.defaultProps = {
	className: '',
};

CreateEditPopup.propTypes = {
	className: PropTypes.string,
	edit: PropTypes.func.isRequired,
	onSaveContract: PropTypes.func.isRequired,
};

export const mapDispatchToProps = dispatch =>
	bindActionCreators({ onSaveContract: addContractToSync }, dispatch);

export default connect(
	null,
	mapDispatchToProps,
	null,
	{ withRef: true }
)(CreateEditPopup);
