import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../../../img/logo.png';

const Header = ({ reference }) => (
	<table cellSpacing="0" cellPadding="0">
		<tbody>
			<tr>
				<td width="10%" />
				<td className="text-center bold">
					MANDAT de <br /> Prélèvement <br />
					SEPA
				</td>
				<td className="text-center">
					<img width="300px" src={logo} />
				</td>
			</tr>
			<tr>
				<td width="10%" />
				<td width="30%">Réference unique du mandate</td>
				<td className="text-center bold">{reference.toUpperCase()}</td>
			</tr>
		</tbody>
	</table>
);

Header.propTypes = {
	reference: PropTypes.string.isRequired,
};

export default Header;
