import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import * as customerService from '../../../services/customer';
import {SubmissionError} from 'redux-form';
import {toast} from 'react-toastify';
import Loading from '../../../components/Loading';
import * as customerExportService from '../../../services/customer/export';


class ExportPopup extends Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			data: {},
			loaded: true,
			edit: false,
			customer: false,
		};
	}

	handleOpen = (id) => {
		this.setState({
			open: true,
		});

	}

	handleClose() {
		this.setState({
			open: false,
			data: {},
		});
	}

	exportProprio = () => {
		customerExportService.exportPropioCSV().then((data) => {
		})
	}

	exportLoca = () => {
		customerExportService.exportLocaCSV().then((data) => {
		})
	}


	render() {
		const {className} = this.props;
		const {loaded} = this.state;

		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Exporter
				</ModalHeader>
				<ModalBody>
					<button className="btn btn-success" onClick={() => this.exportProprio()}>Propriétaires</button>
					<br/><br/>
					<button className="btn btn-success" onClick={() => this.exportLoca()}>Locataires</button>
				</ModalBody>

			</Modal>
		);
	}


}

export default ExportPopup;
