import React, { Component } from 'react';
import { Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { FieldTxt, FieldSelect2, FieldSelect } from '../../../../components/Form/wh-field';
import { ModalBody, ModalFooter, Alert } from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import * as equipmentService from '../../../../services/catalog/equipment';
import * as levelService from '../../../../services/catalog/service';

class UserForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            equipmentOptionsLoaded: false,
            equipmentOptions: [],
            levelServiceOptionsLoaded: false,
            levelServiceOptions: [],
        };
    }

    componentDidMount() {
        this.loadEquipment();
        this.loadLevelService();
    }

    loadEquipment() {
        return equipmentService.search().then(data => {
            const equipmentOptions = [];

            equipmentOptions.push({ value: '', name: '' });
            data['hydra:member'].map(el => {
                equipmentOptions.push({ value: el['@id'], name: `${el.family.name} > ${el.name}` });
            });
            this.setState({
                equipmentOptionsLoaded: true,
                equipmentOptions,
            });
        });
    }

    loadLevelService() {
        return levelService.search().then(data => {
            const levelServiceOptions = [];

            levelServiceOptions.push({ value: '', name: '' });
            data['hydra:member'].map(el => {
                levelServiceOptions.push({ value: el['@id'], name: `${el.name}` });
            });
            this.setState({
                levelServiceOptionsLoaded: true,
                levelServiceOptions,
            });
        });
    }

    render() {
        const { error, handleSubmit, submitting } = this.props;

        return (
            <div>
                {this.state.equipmentOptionsLoaded &&
                    this.state.levelServiceOptionsLoaded && (
                        <Form onSubmit={handleSubmit} className="form-horizontal">
                            <ModalBody>
                                <Field
                                    label="Equipement :"
                                    name="equipment"
                                    component={FieldSelect}
                                    options={this.state.equipmentOptions}
                                />

                                <Field
                                    label="Préstation :"
                                    name="levelService"
                                    component={FieldSelect}
                                    options={this.state.levelServiceOptions}
                                />

                                <Field
                                    label="Tarif mensuel :"
                                    name="monthly"
                                    component={FieldTxt}
                                />

                                <Field label="Tarif Remise :" name="remise" component={FieldTxt} />

                                {error && (
                                    <Alert color="danger">
                                        <div dangerouslySetInnerHTML={{ __html: error }} />
                                    </Alert>
                                )}
                            </ModalBody>

                            <ModalFooter>
                                <LaddaButton
                                    className="btn btn-success btn-ladda"
                                    loading={submitting}
                                    data-style={EXPAND_LEFT}
                                    type="submit"
                                >
                                    <i className="fa fa-save" />
                                    &nbsp;Enregistrer
                                </LaddaButton>
                            </ModalFooter>
                        </Form>
                    )}
            </div>
        );
    }
}

const formName = 'priceEditForm';

export default reduxForm({
    form: formName,
})(UserForm);
