import { getActionCellTemplate } from '../CellTemplate/Action';
import React from "react";
import {Badge} from "reactstrap";
const getColumns = self => [
    {
        Header: '',
        accessor: 'id',
        filterable: false,
        Cell: row => getActionCellTemplate(row, self),
        maxWidth: 90,
    },
    {
        Header: 'Nom',
        accessor: 'name',
    },
	{
		Header: 'Activé/désactivé',
		accessor: 'enabled',
		Cell: row => (
			<div>
				<div>
					{row.value === true ?  <Badge color="success">Active</Badge> : <Badge color="danger">Désactivé</Badge>}
				</div>
			</div>
		),
	},
];

export default getColumns;
