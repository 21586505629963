import {createAction} from "redux-friends";
import {toast} from "react-toastify";

import {getAllContractsToSyncList, getOneContractToSync} from "./selectors";
import {
	create,
	update,
	fetchStatus,
	remove,
	view,
	getContractBase64, sendContractFiles, searchNest,
} from "../../../services/contract";
import ifContractIsToBeSynced from "../../../utils/ifContractIsToBeSynced";
import {
	REMOVE_CONTRACT_FROM_SYNC,
	ADD_CONTRACT_TO_SYNC,
	EDIT_CONTRACT_TO_SYNC,
	SET_CONTRACTS,
	SET_CONTRACTS_STATUS,
	SET_SIGNATURE,
	SET_CURRENT_CONTRACT,
	SET_STATUS_FILTER,
	SET_ARCHIVED_CONTRACTS,
	ContractsTypes,
} from "./constants";
import {formatContractForSave} from "../utils";
import {TMP_ID} from "../../../constants";
import formatDataNestForRedux from "../../../utils/formatDataNestForRedux";

export const setArchivedContracts = createAction(
	ContractsTypes[SET_ARCHIVED_CONTRACTS]
);
export const setContracts = createAction(ContractsTypes[SET_CONTRACTS]);

export const removeContractFromToSync = createAction(
	ContractsTypes[REMOVE_CONTRACT_FROM_SYNC]
);
export const addContractToSync = createAction(
	ContractsTypes[ADD_CONTRACT_TO_SYNC]
);
export const editContractToSync = createAction(
	ContractsTypes[EDIT_CONTRACT_TO_SYNC]
);
export const setContractsStatus = createAction(
	ContractsTypes[SET_CONTRACTS_STATUS]
);
export const setSignature = createAction(ContractsTypes[SET_SIGNATURE]);
export const setCurrentContract = createAction(
	ContractsTypes[SET_CURRENT_CONTRACT]
);
export const setStatusFilter = createAction(ContractsTypes[SET_STATUS_FILTER]);

const fetchContracts = (state, set = setContracts, initialPage = 0) => async dispatch => {
	try {
		const data = await searchNest(state, initialPage);
		dispatch(set(formatDataNestForRedux(data,  state.pageSize)));
	} catch (error) {
		console.error({error});
		throw new Error(error);
	}
};

const enhanceSearchWithArchivedStatus = (state, isArchived) => ({
	...state,
	filtered: [
		...(state.filtered ? state.filtered : []),
		{id: "preContrat", value: 0},
		{id: "archived", value: isArchived ? 1 : 0}
	]
});

export const fetchOnGoingContracts = (state = {}) =>{
	return fetchContracts(enhanceSearchWithArchivedStatus(state, false), setContracts);
}

export const fetchArchivedContracts = (state = {}) =>
	fetchContracts(
		enhanceSearchWithArchivedStatus(state, true),
		setArchivedContracts
	);

export const fetchContractsStatus = () => async dispatch => {
	try {
		const status = await fetchStatus();
		dispatch(setContractsStatus(status));
	} catch (error) {
		console.error({error});
	}
};

export const updateContract = contract => async dispatch => {
	try {
		const updatedContract = {
			...contract,
			oldContract: undefined,
			CGVSignature: undefined,
			sepaSignature: undefined,
			contractSignature: undefined,
			contractSellerSignature: undefined,
			ribCapture: undefined,
			identityCapture: undefined,
		}
		await update(updatedContract);
	} catch (error) {
		console.error({error});
	}
};

export const fetchContract = contractId => async dispatch => {
	try {
		const contract = await view(contractId);
		let getBase64 = null
		try {
			getBase64 = await getContractBase64(contractId)
		}catch {}

		dispatch(
			setCurrentContract({
				...contract,
				affectedSeller: contract.affectedSeller
					? contract.affectedSeller["@id"]
					: null,
				ribCapture: getBase64 ? getBase64.ribCapture : contract.ribCapture,
				identityCapture: getBase64 ? getBase64.identityCapture : contract.identityCapture,
				oldContract: getBase64 ? getBase64.oldContract : contract.oldContract,
				CGVSignature: getBase64 ? getBase64.CGVSignature : contract.CGVSignature,
				sepaSignature: getBase64 ? getBase64.sepaSignature : contract.sepaSignature,
				contractSignature: getBase64 ? getBase64.contractSignature : contract.contractSignature,
				contractSellerSignature: getBase64 ? getBase64.contractSellerSignature : contract.contractSellerSignature,
			})
		);
	} catch (error) {
		console.error({error});
	}
};

export const syncAllContracts = state => async (dispatch, getState) => {
	const contracts = getAllContractsToSyncList(getState()).filter(
		ifContractIsToBeSynced
	);
	if (contracts.length !== 0){
		for (const contract of contracts) {
			try {
				const contractSynch = await create(formatContractForSave(contract));
				await sendContractFiles(contractSynch);
				dispatch(removeContractFromToSync(contract[TMP_ID]));
			} catch (error) {
				console.error({error});
			}
		}
		try {
			await fetchOnGoingContracts(state)(dispatch);
			return true
		} catch (error) {
			console.error({error});
		}
	}else{
		return false
	}
};

export const syncOneContract = tmpID => async (dispatch, getState) => {
	const contract = getOneContractToSync(getState(), tmpID);
	try {
		const contractSynch = await create(formatContractForSave(contract));
		await sendContractFiles(contractSynch);
		dispatch(removeContractFromToSync(contract[TMP_ID]));
		toast.success("contrat synchronisé !");
	} catch (error) {
		console.error({error});
		toast.error("erreur lors de la synchronisation du contrat !");
	}
};

export const removeContract = deleteID => async dispatch => {
	try {
		await remove(deleteID);
		fetchOnGoingContracts()(dispatch);
		fetchArchivedContracts()(dispatch);
	} catch (error) {
		console.error({error});
		alert(
			"Il existe des dépendances sur ce contrat, il ne peut donc être supprimé"
		);
	}
};
