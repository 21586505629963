import React from 'react';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {Form, ModalBody, ModalFooter, Alert} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';
import PropTypes from 'prop-types';

import {FieldTxt, FieldSelect, FieldNumber} from '../../../../components/Form/wh-field';
import {required} from '../../../../components/Form/wh-validator';
import {getFamilyList} from '../../../Catalog/Family/redux';
import {getEquipmentsList} from '../../../Catalog/Equipment/redux';

const filterEquipmentsByFamily = (equipments, family) =>
	equipments.filter(el => Number(el.family.id) === Number(family.id) && el.enabled === true);

const filterFamily = (family) =>
	family.filter(el => el.enabled === true);


const MaterialForm = ({
						  error,
						  handleSubmit,
						  submitting,
						  equipmentFamily,
						  families,
						  equipments,
						  isArchived,
						  fiedDisabled,
						  initialValues,
						  type
					  }) => {
	const isPompeAA = parseInt(type) === 7

	return (<Form onSubmit={handleSubmit} className="form-vertical">
		{error && (
			<Alert color="danger">
				<div dangerouslySetInnerHTML={{__html: error}}/>
			</Alert>
		)}
		<ModalBody>
			<Field
				label="Type de material :"
				name="equipmentFamily.id"
				component={FieldSelect}
				nullValue="Choisir une installation"
				validate={[required]}
				options={filterFamily(families)}
				disabled={fiedDisabled}
			/>

			{equipmentFamily && (
				<div>
					<Field
						label="* Type :"
						name="type"
						component={FieldSelect}
						validate={[required]}
						nullValue="Choisir un type"
						options={filterEquipmentsByFamily(equipments, equipmentFamily)}
						disabled={fiedDisabled}
					/>
					{isPompeAA && (
						<div>
							<Field
								label="* Nombre d'unité extérieur de pompe Air/Air : "
								id="field-form"
								name="nbrUnite"
								component={FieldTxt}
								validate={[required]}
								disabled={fiedDisabled}
								placeholder="Nombre"
								type={"number"}
								value="1"
								min="1"
							/>
							<Field
								label="* Nombre d'unité intérieur de pompe Air/Air : "
								id="field-form"
								name="nbrUniteInt"
								component={FieldTxt}
								validate={[required]}
								disabled={fiedDisabled}
								type={"number"}
								placeholder="Nombre"
								value="1"
								min="1"
							/>
						</div>
					)}
					<Field
						label="* Marque :"
						id="field-form"
						name="brand"
						validate={[required]}
						component={FieldTxt}
						type="text"
						disabled={fiedDisabled}
						placeholder="Marque"
					/>
					<Field
						label="Puissance :"
						id="field-form"
						name="power"
						component={FieldTxt}
						type="text"
						disabled={fiedDisabled}
						placeholder="Kw"
					/>
					<Field
						label="Numéro de serie :"
						id="field-form"
						name="serialNumber"
						component={FieldTxt}
						type="text"
						disabled={fiedDisabled}
						placeholder="Identifiant"
					/>
					<Field
						label="Année de pose :"
						id="field-form"
						name="year" component={FieldTxt}
						type="text"
						disabled={fiedDisabled}
						placeholder="Année"
					/>
					{/*<Field*/}
					{/*	label="Date d'entretien prévue :"*/}
					{/*	name="date"*/}
					{/*	component={FieldTxt}*/}
					{/*	type="date"*/}
					{/*	disabled={isArchived}*/}
					{/*/>*/}
					<Field
						label="Commentaires :"
						name="note"
						component={FieldTxt}
						type="textarea"
					/>
				</div>
			)}
		</ModalBody>

		<ModalFooter>
			<LaddaButton
				className="btn btn-success btn-ladda"
				loading={submitting}
				data-style={EXPAND_LEFT}
				type="submit">
				<i className="fa fa-save"/> Enregistrer
			</LaddaButton>
		</ModalFooter>
	</Form>)
}
const formName = 'FormMaterial';
const reduxFormMaterial = reduxForm({form: formName})(MaterialForm);
const selector = formValueSelector(formName);

MaterialForm.defaultProps = {
	error: null,
	equipmentFamily: null,
	equipments: [],
};

MaterialForm.propTypes = {
	error: PropTypes.shape({}),
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	equipmentFamily: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({id: PropTypes.string.isRequired}),
	]),
	families: PropTypes.arrayOf(PropTypes.any).isRequired,
	equipments: PropTypes.arrayOf(PropTypes.shape({})),
	isArchived: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
	type: selector(state, 'type'),
	equipmentFamily: selector(state, 'equipmentFamily'),
	families: getFamilyList(state),
	equipments: getEquipmentsList(state),
});

export default connect(mapStateToProps)(reduxFormMaterial);
