import React, {Component} from 'react';
import {Button, Form} from 'reactstrap';
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {
	DatePickerField,
	FieldRadio, FieldSelect,
} from "../../../../components/Form/wh-field";
import {connect} from "react-redux";
import * as AppointmentService from "../../../../services/appointment";
import moment from "moment";

class AppointmentForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};
	}

	submit = () => {
		const contract = this.props.appointment.contract['@id']
		let appointment = {...this.props.appointment}
		let countCall = appointment.countCall
		if (this.props.clientHasBeenContacted === "0") {
			if (countCall === 1) {
				appointment = {
					...this.props.appointment,
					technician: appointment.technician ? appointment.technician['@id'] : null,
					clientHasBeenContacted: parseInt(this.props.clientHasBeenContacted),
					countCall: 2,
					contract: contract
				}
			} else {
				appointment = {
					...this.props.appointment,
					technician: appointment.technician ? appointment.technician['@id'] : null,
					countCall: 1,
					contract: contract
				}
			}
		}
		if (this.props.clientHasBeenContacted === "1" && this.props.isScheduled === "false") {
			appointment = {
				...this.props.appointment,
				technician: appointment.technician ? appointment.technician['@id'] : null,
				clientHasBeenContacted: parseInt(this.props.clientHasBeenContacted),
				contract: contract
			}
		}
		if (this.props.startHour !== undefined) {
			let startDate
			let endDate
			if (this.props.startHour === "Matin"){
				startDate = moment(this.props.startDate).set({hour:8,minute:0,second:0,millisecond:0}).locale('fr').format('YYYY-MM-DD HH:mm:ss')
				endDate = moment(this.props.startDate).set({hour:12,minute:0,second:0,millisecond:0}).locale('fr').format('YYYY-MM-DD HH:mm:ss')
			}else{
				startDate = moment(this.props.startDate).set({hour:13,minute:0,second:0,millisecond:0}).locale('fr').format('YYYY-MM-DD HH:mm:ss')
				endDate = moment(this.props.startDate).set({hour:17,minute:0,second:0,millisecond:0}).locale('fr').format('YYYY-MM-DD HH:mm:ss')
			}
			appointment = {
				...this.props.appointment,
				clientHasBeenContacted: parseInt(this.props.clientHasBeenContacted),
				isScheduled: true,
				technician: this.props.technicianId,
				startDate: startDate,
				endDate: endDate,
				contract: contract
			}
		}
		AppointmentService.update(appointment).then(this.props.handleClose)
	}

	render() {
		const {
			clientHasBeenContacted,
			isScheduled,
			startDate,
			technicianId,
			startHour,
			endHour,
			technicians
		} = this.props;
		const matinAprem = [{
			name : "Choisissez un horaire",
			value : ""
		},{
			name : "Matin",
			value : "Matin"
		},{
			name : "Après-midi",
			value :  "Après-midi"
		}]
		return (
			<Form>
				<div className="mt-3 ml-4">
					{this.props.appointment.countCall === 1 &&
						<p className="font-weight-bold h6">la personne n'a pas répondu la première fois</p>
					}
					<label className="font-weight-bold h6">A t'il répondu ?</label>
					<div>
						<Field
							id={"clientHasBeenContacted1"}
							value="1"
							label="oui"
							component={FieldRadio}
							type="radio"
							name="clientHasBeenContacted"
						/>
						<Field
							name="clientHasBeenContacted"
							id={"clientHasBeenContacted2"}
							label="non"
							value="0"
							component={FieldRadio}
							type="radio"
						/>
					</div>
					{clientHasBeenContacted === "1" && (
						<div>
							<label className="font-weight-bold h6">Avez-vous convenu d'une date d'entretien ?</label>
							<div>
								<Field
									id={"isScheduled1"}
									value="true"
									label="oui"
									component={FieldRadio}
									type="radio"
									name="isScheduled"
								/>
								<Field
									id={"isScheduled2"}
									value="false"
									name="isScheduled"
									label="non"
									component={FieldRadio}
									type="radio"
								/>
							</div>
						</div>
					)}
					{(isScheduled === "true" && clientHasBeenContacted === "1") && (
						<div className="w-50">
							<Field
								component={DatePickerField}
								label="Saisissez la date du prochain entretien ?"
								name="startDate"
								isClearable={true}
							/>
						</div>
					)}
					{(isScheduled === "true" && clientHasBeenContacted === "1" && startDate !== undefined) && (
						<div className="w-50">
							<Field
								name="technicianId"
								label="Technicien"
								component={FieldSelect}
								options={[{value: null, name: ''}, ...technicians.map(item => ({
									value: item['@id'],
									name: item.name
								}))]}/>
						</div>
					)}
					{(isScheduled === "true" && clientHasBeenContacted === "1" && technicianId !== undefined) && (
						<div className="w-50">
							<Field
								name="startHour"
								label="Horaire ?"
								component={FieldSelect}
								options={matinAprem}
							/>
						</div>
					)}
					{(clientHasBeenContacted === "0" || isScheduled === "false" || startHour !== undefined) && (
						<div className="text-center pb-3">
							<Button onClick={this.submit} className="btn btn-dark btn-lg">Enregistrer les
								modifications</Button>
						</div>

					)
					}
				</div>
			</Form>
		)
	}
}

const formName = 'FormAppointment';
const reduxFormAppointment = reduxForm({form: formName})(AppointmentForm);
const selector = formValueSelector(formName);

const mapStateToProps = state => ({
	clientHasBeenContacted: selector(state, 'clientHasBeenContacted'),
	isScheduled: selector(state, 'isScheduled'),
	startDate: selector(state, 'startDate'),
	technicianId: selector(state, 'technicianId'),
	startHour: selector(state, 'startHour'),
	endHour: selector(state, 'endHour'),
});

export default connect(mapStateToProps)(reduxFormAppointment);

