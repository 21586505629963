import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { me } from '../../utils/session';
import { myInformations } from '../../services/member';
import FormMyInformations from './FormMyInformations';
import { Row, Col } from 'reactstrap';
import { SubmissionError } from 'redux-form';

class MyInformations extends Component {
    state = {
        loaded: false,
        user: {},
    };

    async componentDidMount() {
        const user = me();
        this.setState({ loaded: true, user });
    }

    submit = async values => {
        try {
            await myInformations(this.state.user.id, values);
            toast.success('Enregistrement réussi');
        } catch (error) {
            throw new SubmissionError(error);
        }
    };

    render() {
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12" sm="6">
                        {this.state.loaded && (
                            <FormMyInformations
                                onSubmit={this.submit}
                                initialValues={this.state.user}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default MyInformations;
