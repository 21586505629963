import { combineReducers } from 'redux';

import { FamilyTypes, FAMILY_RK, SET_FAMILY } from '../Family/redux';
import { PricesTypes, PRICES_RK, SET_PRICES } from '../Price/redux';
import { EquipementsTypes, EQUIPMENTS_RK, SET_EQUIPMENTS } from '../Equipment/redux';
import { ServicesTypes, SERVICES_RK, SET_SERVICES } from '../Service/redux';

import { itemMapper, combineBuilder } from './utils';

const mapper = [
    itemMapper(FamilyTypes, FAMILY_RK, SET_FAMILY),
    itemMapper(PricesTypes, PRICES_RK, SET_PRICES),
    itemMapper(EquipementsTypes, EQUIPMENTS_RK, SET_EQUIPMENTS),
    itemMapper(ServicesTypes, SERVICES_RK, SET_SERVICES),
];

export default combineReducers(combineBuilder(mapper));
