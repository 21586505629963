export const formatLevelService = (contract, services) => ({
    ...contract,
    levelService: services.find(x => contract.levelService === x['@id']),
});

export const getSignatureStyle = url =>
    url
        ? {
              backgroundImage: `url(${url})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
          }
        : {};
