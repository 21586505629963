import React, {Component, createRef} from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getFormValues} from "redux-form";
import {redirectTo} from "../../utils/redirectTo";
import {isStartingWithUuidPrefix} from "../../utils/uuid";
import getParamIDFromProps from "../../utils/getParamIDFromProps";
import ContractForm from "./Form";
import Loading from "../../components/Loading";
import SignPopup from "./SignPopup";
import {
	sendCGV,
	sendContractDocuments,
} from "../../services/contract";
import FileSaver from 'file-saver'
import {addContractToSync, editContractToSync, getCurrentContract, getOneContractToSync, updateContract} from "./redux";
import {fetchContract} from "./redux/actions";
import {formName} from "./constants";
import {
	checkIfContractValid,
	checkIfSepaValid,
	formatContractForSave,
	formatInstallationDate,
	getDefaultData
} from "./utils";
import {getUser} from "../Authentication/redux";
import {SEPA} from "../../constants";
import {CONTRACTS} from "../../constants/paths";
import {toast} from "react-toastify";
import PizZipUtils from "pizzip/utils/index.js";
import {pdf} from '@react-pdf/renderer'
import PdfLetter from "./PdfLetter";
import moment from "moment";
import Swal from "sweetalert2";
import {pdfjs} from "react-pdf";
import LaddaButton, {EXPAND_LEFT} from "react-ladda";
import {ROLE_ADMIN, ROLE_SELLER, ROLE_SELLER_SENIOR, ROLE_TECHNICO_COMMERCIAL} from "../../constants/roles";
import DownloadPopup from "./DownloadPopup";
import * as contractService from "../../services/contract";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function loadFile(url, callback) {
	PizZipUtils.getBinaryContent(url, callback);
}

class Contract extends Component {
	constructor(props) {
		super(props);
		this.paramID = getParamIDFromProps(this.props);
		this.isLocalContract = isStartingWithUuidPrefix(this.paramID);
		this.isArchivedContract = this.props.contract ? this.props.contract.archived : null;
	}

	state = {
		open: false,
		loaded: false,
		disabledContract: false,
		disabled: true,
		isToggleOn: this.props.contract ? this.props.contract.alreadyInstalled : null,
		isToggleOnParc: this.props.contract ? this.props.contract.parc : null,
		className: this.props.contract ? this.props.contract.alreadyInstalled : null,
		classNameParc: this.props.contract ? this.props.contract.parc : null,
		pagePDF: 1,
	};
	signPopup = React.createRef();
	downloadPopup = createRef();
	sigPadContract = createRef();

	fetchContractData = async () => {
		await this.props.onFetchCurrentContract(this.paramID);
		this.setState({loaded: true});
	};

	componentDidUpdate = prevProps => {
		if (
			this.props.contract &&
			(!prevProps.contract ||
				prevProps.contract.id !== this.props.contract.id)
		) {
			const {archived} = this.props.contract;
			this.isArchivedContract = archived;
		}
	};

	componentDidMount = () => {
		this.managebutton("isToggleOnParc", "classNameParc");
		this.managebutton("isToggleOn", "className");
		this.fetchContractData();
	};

	managebutton = (state, className) => {
		this.state[state]
			? this.setState({[className]: "btn btn-block btn-success btn-lg"})
			: this.setState({[className]: "btn btn-block btn-danger btn-lg"});
	};

	onSaveData = async data => {
		const dataToSave = formatContractForSave(data);
		await this.props.onSubmit(dataToSave);
	};

	onSubmit = async data => {
		try {
			await this.onSaveData({
				...data,
				sector: data.sector ? data.sector : null,
				technician: data.technician ? data.technician : null,
			});
			await this.fetchContractData();
			await Swal.fire({
				width: 400,
				icon: 'success',
				title: "Contrat Sauvegardé",
				showConfirmButton: false,
				timer: 1500,
				timerProgressBar: true
			})
		} catch (e) {
			await Swal.fire({
				width: 400,
				icon: 'error',
				title: "Erreur",
				text: "Le contrat n'a pas été sauvegardé",
				showConfirmButton: false,
				timer: 1500,
				timerProgressBar: true
			})
			toast.error(e.errors[0].message)
		}
	};

	install = (state, value, className) => {
		this.setState({[state]: !this.state[state]}, () => {
			this.props.currentContract[value] = this.state[state];
			this.managebutton(state, className);
		});
	};

	onSign = () => {
		this.onSaveData(this.props.currentContract);
		this.signPopup.current.handleOpen(this.props.currentContract);
	};
	onDownload = () => {
		this.onSaveData(this.props.currentContract);
		this.downloadPopup.current.handleOpen(this.props.currentContract);
	};

	sendMailCgv = async () => {
		try {
			const data = await sendCGV(this.paramID);
			if (data.status === "ok") {
				toast.success("Email envoyé");
			} else {
				toast.error(data.message);
			}
		} catch (e) {
		}
	};

	sendContractDocuments = async () => {
		try {
			const data = await sendContractDocuments(this.paramID);
			if (data.status === "ok") {
				toast.success("Email envoyé");
			} else {
				toast.error(data.message);
			}
		} catch (e) {
		}
	};

	generateDocument = () => {
		const {
			contract: {customer}
		} = this.props;

		const downloadFileToPrint = async () => {
			const blob = await pdf(
				<PdfLetter
					firstname={customer.firstName.toUpperCase()}
					name={customer.lastName.toUpperCase()}
					address={customer.address.toUpperCase()}
					zipcode={customer.zipCode}
					city={customer.city.toUpperCase()}
				/>
			).toBlob()

			FileSaver.saveAs(blob, `test.pdf`)
		}
		downloadFileToPrint()
	}


	modif = async () => {
		await Swal.fire({
			title: 'Voulez-vous modifier le contrat ?',
			text: "Il faudra signer le contrat une nouvelle fois après toutes modifications",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Oui',
			cancelButtonText: 'Non',
		}).then(async (result) => {
			if (result.isConfirmed) {
				const contrat = this.props.contract
				contrat.document = null
				contrat.contractSignature = null
				contrat.status = 700
				await this.onSaveData(contrat)
				window.location.reload()
			}
		})
	}

	handleClose = () => {
		this.setState({open: false});
	}

	async cancelReplace() {
		const contract = this.props.contract
		contract.status = 900
		await contractService.update(contract)
		const dataToSend = getDefaultData()
		dataToSend.customer = contract.customer
		dataToSend.replaceDocument = contract.document
		dataToSend.contractInformation = contract.contractInformation
		dataToSend.sector = contract.sector ? contract.sector['@id'] : null
		dataToSend.teamTechnique = contract.teamTechnique ? contract.teamTechnique['@id'] : null
		this.props.onSaveContract(dataToSend);
		redirectTo(CONTRACTS, dataToSend.tmpID);
		window.location.reload();
	}

	render() {
		const {loaded, appointments, disabledContract} = this.state;
		const {
			paramID,
			onSign,
			onSubmit,
			load,
			signPopup,
			downloadPopup,
			onDownload,
			goToSignCgv,
			isArchivedContract,
			downloadCgv,
			sendMailCgv,
			sendContractDocuments,
		} = this;

		const {user, contract, currentContract, submitting} = this.props;
		if (!contract) {
			return <Loading/>;
		}
		const isSeller =
			this.props.user.roles &&
			(this.props.user.roles.indexOf(ROLE_SELLER) > -1 ||
				this.props.user.roles.indexOf(ROLE_ADMIN) > -1 ||
				this.props.user.roles.indexOf(ROLE_TECHNICO_COMMERCIAL) > -1 ||
				this.props.user.roles.indexOf(ROLE_SELLER_SENIOR) > -1);


		const changeDisabled = (value) => {
			this.setState({disabled: value})
		}
		let data = formatInstallationDate(contract);

		if (!this.isLocalContract) {
			data = {
				...data,
				teamTechnique: data.teamTechnique ? data.teamTechnique['@id'] : null,
				sector: data.sector ? data.sector['@id'] : null,
				technician: data.technician ? data.technician['@id'] : null,

//				seller: data.affectedSeller['@id'],
			}
		}

		const {sepaSignature, contractSignature, document, CGVSignature} = contract;
		const shouldShowSepa = !!sepaSignature && data.paymentType === SEPA;
		const isContractValid = checkIfContractValid(currentContract);
		const isSepaValid = checkIfSepaValid(currentContract);
		const userIsAdmin = this.props.user.roles.includes("ROLE_ADMIN")
		const isSynchronised = !!this.props.contract.synchronised
		const createdDate = isSynchronised ? moment(data.created).toDate() : moment.utc(data.created).toDate()
		const shouldShowSignButton =
			isSeller && isContractValid;

		return (
			<div className="animated fadeIn">
				{(!loaded || !currentContract) && <Loading/>}
				<SignPopup
					onSaveData={this.onSaveData}
					contract={contract}
					ref={signPopup}
					onClose={load}
					id={paramID}
					CGVSignature={!!CGVSignature}
					isSepaValid={isSepaValid}
					isContractValid={isContractValid}
					sepaSignature={sepaSignature}
					contractSignature={contractSignature}
				/>

				<DownloadPopup
					contract={contract}
					ref={downloadPopup}
					onClose={load}
					id={paramID}
					CGVSignature={!!CGVSignature}
					sepaSignature={!!sepaSignature}
					contractSignature={!!contractSignature}
				/>


				<Row>
					<Col lg={6}>
						<Card className="border-card">
							<CardHeader className="card-header-contract">
								<h3>Informations du contrat</h3>
							</CardHeader>


							{loaded && (
								<ContractForm
									local={this.isLocalContract}
									onSubmit={onSubmit}
									initialValues={{
										...data,
										created: createdDate
									}}
									user={user}
									isContractValid={isContractValid}
									isArchivedContract={isArchivedContract}
									userIsAdmin={userIsAdmin}
								/>
							)}
						</Card>
					</Col>
					<Col lg={6}>
						<Row>
							{isSynchronised &&
								<Col lg={12} className="datasigned case-contract-form">
									<Card className="border-card shadow-card">
										<CardHeader className="card-header-contract">
											<h3 className="contract-form-title">Documents</h3>
										</CardHeader>
										<CardBody>
											<LaddaButton
												className="btn btn-success btn-ladda btn-block btn-lg button-border"
												loading={submitting}
												onClick={onSign}
												data-style={EXPAND_LEFT}
												style={{marginTop: 0}}
												type="button"
											>
												<i className="fa fa-edit"/> Signer
											</LaddaButton>
											<LaddaButton
												className="btn btn-success btn-ladda btn-block btn-lg mt-2 button-border"
												loading={submitting}
												onClick={onDownload}
												data-style={EXPAND_LEFT}
												style={{marginTop: 0}}
												type="button"
											>
												<i className="fa fa-download"/> Télécharger
											</LaddaButton>
										</CardBody>
									</Card>
								</Col>
							}
							{isSynchronised &&
								<Col lg={12} className="datasigned case-contract-form">
									<Card className="border-card shadow-card">
										<CardHeader className="card-header-contract">
											<h3 className="contract-form-title">Emails</h3>
										</CardHeader>
										<CardBody>
											<div>
												{CGVSignature &&
													<button
														onClick={sendMailCgv}
														className="btn btn-block btn-success btn-lg btn-contract-form button-border"
													>
														Envoyer les CGV par mail
													</button>
												}
												<button
													onClick={sendContractDocuments}
													className="btn btn-block btn-primary btn-lg btn-contract-form button-border"
												>
													Envoyer les documents par mail
												</button>
											</div>
										</CardBody>
									</Card>
								</Col>
							}
							<Col lg={6} className="datasigned case-contract-form">
								<Card className="border-card shadow-card">
									<CardBody>
										<button
											onClick={this.generateDocument}
											className="btn btn-block btn-primary btn-lg btn-contract-form button-border"
										>
											Lettre préremplie
										</button>
									</CardBody>
								</Card>
							</Col>
							{userIsAdmin && contractSignature &&
								<Col lg={6} className="datasigned case-contract-form">
									<Card className="border-card shadow-card">
										<CardBody>
											<button
												onClick={() => this.modif()}
												className={`btn btn-block btn-primary btn-lg btn-contract-form button-border`}
											>
												Modifier le contrat
											</button>
										</CardBody>
									</Card>
								</Col>
							}
							{isSynchronised &&
								<Col lg={6} className="datasigned case-contract-form">
									<Card className="border-card shadow-card">
										<CardBody>
											<button
												onClick={() => this.cancelReplace()}
												className={`btn btn-block btn-outline-danger btn-lg btn-contract-form button-border`}
											>
												Annuler et remplacer
											</button>
										</CardBody>
									</Card>
								</Col>
							}
							<Col lg={6} className="datasigned case-contract-form">
								<Card className="border-card shadow-card">
									<CardHeader className="card-header-contract-50">
										<h3 className="contract-form-title">Matériels installés</h3>
									</CardHeader>
									<CardBody>
										<button
											onClick={() => this.install("isToggleOn", "alreadyInstalled", "className")}
											className={`${this.state.className} btn-contract-form button-border`}
										>
											{this.state.isToggleOn
												? "Oui"
												: "Non"}
										</button>
									</CardBody>
								</Card>
							</Col>
							<Col lg={6} className="datasigned case-contract-form">
								<Card className="border-card shadow-card">
									<CardHeader className="card-header-contract-50">
										<h3 className="contract-form-title">Parc</h3>
									</CardHeader>
									<CardBody>
										<button
											onClick={() => this.install("isToggleOnParc", "parc", "classNameParc")}
											className={`${this.state.classNameParc} btn-contract-form button-border`}
										>
											{this.state.isToggleOnParc
												? "Oui"
												: "Non"}
										</button>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		);
	}
}

Contract.defaultProps = {
	currentContract: null,
	contract: null
};

Contract.propTypes = {
	user: PropTypes.shape({}).isRequired,
	onSaveContract: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({id: PropTypes.string.isRequired}).isRequired
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
	contract: PropTypes.shape({
		installationDate: PropTypes.string,
		archived: PropTypes.bool
	}),
	currentContract: PropTypes.shape({installationDate: PropTypes.string})
};

const mapStateToProps = (state, ownProps) => {
	const paramID = getParamIDFromProps(ownProps);
	const getContract = isStartingWithUuidPrefix(paramID)
		? getOneContractToSync
		: getCurrentContract;

	return {
		user: getUser(state),
		contract: getContract(state, paramID),
		currentContract: getFormValues(formName)(state)
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	const isTmpID = isStartingWithUuidPrefix(getParamIDFromProps(ownProps));
	const onSubmit = isTmpID ? editContractToSync : updateContract;
	return bindActionCreators(
		{onSubmit, onFetchCurrentContract: fetchContract, onSaveContract: addContractToSync,},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Contract);
