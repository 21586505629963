import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as reduxFormReducer } from 'redux-form';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { ONLINE_RK, onlineStatusReducer } from '../components/OnlineStatus/redux';
import { AUTH_RK, AuthReducer } from '../modules/Authentication/redux';
import { CATALOG_RK, catalogReducer } from '../modules/Catalog/redux';
import { SignatureReducer, SIGNATURE_RK } from '../modules/Signature/redux';
import {CONTRACTS_RK, ContractsReducer} from "../modules/Contract/redux";

const contractPersistKey = {
    key: CONTRACTS_RK,
    storage,
    blacklist: [],
};

const reducers = {
    routing: routerReducer,
    form: reduxFormReducer,
    [ONLINE_RK]: onlineStatusReducer,
    [CATALOG_RK]: catalogReducer,
    [CONTRACTS_RK]: persistReducer(contractPersistKey, ContractsReducer),
    [AUTH_RK]: AuthReducer,
    [SIGNATURE_RK]: SignatureReducer,
};

export default combineReducers(reducers);
