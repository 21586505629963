import equipementsTypes from './types';
import { SET_EQUIPMENTS } from './constants';
import { createAction } from 'redux-friends';
import formatDataForRedux from '../../../../utils/formatDataForRedux';

import { init, search, remove, view, create, update } from '../../../../services/catalog/equipment';
import { SET_DATA, SET_PAGES, SET_LOADED, SET_EDITED, REMOVE_ITEM } from '../../redux/constants';

export const setEquipments = createAction(equipementsTypes[SET_EQUIPMENTS]);
export const setEquipmentsLoadedState = createAction(equipementsTypes[SET_LOADED]);
export const setEquipmentsList = createAction(equipementsTypes[SET_DATA]);
export const setEquipmentsPages = createAction(equipementsTypes[SET_PAGES]);
export const setEditedEquipment = createAction(equipementsTypes[SET_EDITED]);
export const removeEquipment = createAction(equipementsTypes[REMOVE_ITEM]);

export const fetchEquipments = () => async dispatch => {
    try {
        const data = await init();
        dispatch(setEquipments(formatDataForRedux(data,20)));
    } catch (error) {
        throw new Error(error);
    }
};

export const loadEquipments = (state = {}) => async dispatch => {
    try {
		const pageSize = state.filtered.find(x => x.id === 'pageSize')
		if (pageSize) state.pageSize = pageSize.value
        const data = await search(state);
        dispatch(setEquipments(formatDataForRedux(data, state.pageSize)));
    } catch (error) {
        console.error({ error });
    }
};

export const deleteEquipment = toDelete => async dispatch => {
    try {
        await remove(toDelete);
        dispatch(removeEquipment(toDelete));
    } catch (error) {
        console.error({ error });
        alert('Il existe des dépendances sur cet équipement, il ne peut donc être supprimé');
    }
};

export const createEquipment = item => async dispatch => {
    try {
        await create(item);
        dispatch(loadEquipments());
    } catch (error) {
        console.error({ error });
    }
};
export const updateEquipment = item => async dispatch => {
    try {
        await update(item);
        dispatch(loadEquipments());
    } catch (error) {
        console.error({ error });
    }
};
export const fetchEquipment = id => async dispatch => {
    try {
        const data = await view(id);
        data.family = data.family['@id'];
        dispatch(setEditedEquipment(data));
    } catch (error) {
        console.error({ error });
    }
};

export const resetEquipment = () => async dispatch => {
	try {
		const data = {};
		dispatch(setEditedEquipment(data));
	} catch (error) {
		console.error({ error });
	}
};
