import {
	DASHBOARD,
	ROOT,
	CUSTOMERS,
	ACCOUNT,
	OPPORTUNITIES,
	CONTRACTS,
	CONTRACTS_TO_SYNC,
	PAGES,
	POSTS,
	SYNC,
	MEMBERS,
	EQUIPMENTS,
	FAMILIES,
	PRICES,
	SERVICES,
	PREVIEW,
	SIGNATURE,
	ARCHIVED_CONTRACTS,
	COMMERCIALS_SECTORS,
	TECHNIQUES_SECTORS,
	COMMERCIAL_TEAMS,
	TECHNIQUE_TEAMS,
	SUIVIE,
	ENTECHNICIENS,
	PUBLIPOSTAGE,
	PORTEFEUILLE,
	MEMBERS_DISABLED,
	PRE_CONTRACT,
	RAPPEL_FIN_CONTRAT,

} from '../../../constants/paths';
import {composeRoute} from '../../../utils/getPath';
import {SEPA, CONTRACT} from '../../../constants';

/**
 * Manage breadcrump name
 */

const routes = {
	[ROOT]: 'Accueil',
	[DASHBOARD]: 'Dashboard',
	// [CUSTOMERS]: 'Clients',
	[MEMBERS]: 'Membres activés',
	[MEMBERS_DISABLED]: 'Membres désactivés',
	[SUIVIE] : 'Suivi des entretiens',
	[PUBLIPOSTAGE]: 'publipostage',
	[ENTECHNICIENS]: "Entretiens par techniciens",
	[PORTEFEUILLE]: "Portefeuille clients",
	[ACCOUNT]: 'Mon compte',
	[COMMERCIAL_TEAMS]: 'Equipes Commerciales',
	[TECHNIQUE_TEAMS]: 'Equipes Technique',
	[COMMERCIALS_SECTORS]: 'Secteurs Commerciaux',
	[TECHNIQUES_SECTORS]: 'Secteurs Techniques',
	[EQUIPMENTS]: 'Equipements',
	[FAMILIES]: "Familles d'équipement",
	[PRICES]: 'Tarifs',
	[SERVICES]: 'Prestations',
	[OPPORTUNITIES]: 'Gestion des opportunités',
	[CONTRACTS]: 'Edition d’un contrat',
	[CONTRACTS]: 'Gestion des contrats',
	[CONTRACTS_TO_SYNC]: 'Gestion des contrats à synchroniser',
	[ARCHIVED_CONTRACTS]: 'Gestion des contrats archivés',
	[PRE_CONTRACT]: 'Gestion des Pré-contrat',
	[PAGES]: 'Gestion des pages',
	[POSTS]: 'Gestion des articles',
	[SYNC]: 'Synchronisation des données',
	[RAPPEL_FIN_CONTRAT]: 'Rappel fin de contrat',
	[composeRoute(PREVIEW, SEPA)]: 'Prévisualisation du mandat',
	[composeRoute(PREVIEW, CONTRACT)]: 'Prévisualisation du contrat',
	[composeRoute(SIGNATURE, SEPA)]: 'Signature du mandat',
	[composeRoute(SIGNATURE, CONTRACT)]: 'Signature du contrat',
};
export default routes;
