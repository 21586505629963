const CONFIG = {
    ROLES: [
    	{ value: 'ROLE_ADMIN', label: 'Admin' },
		{ value: 'ROLE_SELLER', label: 'Vendeur' },
		{ value: 'ROLE_SELLER_SENIOR', label: 'Commercial sénior' },
		{ value: 'ROLE_EXPERT', label: 'Expert' },
		{ value: 'ROLE_MANAGER', label: 'Manager' },
		{ value: 'ROLE_TECHNICIEN_SAV', label: 'Technicien SAV' },
		{ value: 'ROLE_POSEUR', label: 'Poseur' },
		{ value: 'ROLE_PIQUEUR', label: 'Piqueur' },
		{ value: 'ROLE_TECHNICO_COMMERCIAL', label: 'Technico-commercial' },
	],
    PAYMENTS_TYPES: [
        { value: 'sepa', name: 'Prélèvement' },
        // { value: 'cb', name: 'Carte bleue' },
        // { value: 'cheque', name: 'Chèque' },
		// { value: 'payment_entretien', name: 'Paiement à l\'entretien' },
    ],
    PROMOTION: [{ value: false, name: 'Non' }, { value: true, name: 'Oui' }],
	REGLEMENT: [{ value: false, name: 'Non' }, { value: true, name: 'Oui' }],
	THERMOSTAT: [{ value: false, name: 'Non' }, { value: true, name: 'Oui' }]
};

module.exports = CONFIG;
