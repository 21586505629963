import {ROLE_ADMIN, ROLE_SELLER, ROLE_SELLER_SENIOR, ROLE_TECHNICIEN_SAV,ROLE_TECHNICO_COMMERCIAL} from "../../../constants/roles";
import {
	DASHBOARD,
	CUSTOMERS,
	FAMILIES,
	SERVICES,
	PRICES,
	MEMBERS,
	DISABLED_MEMBERS,
	SYNC,
	EQUIPMENTS,
	CONTRACTS,
	PRE_CONTRACT,
	CONTRACTS_TO_SYNC,
	ARCHIVED_CONTRACTS,
	SUIVI_ENTRETIEN,
	ENTRETIEN_PAR_TECHNICIEN,
	PUBLIPOSTAGE,
	COMMERCIALS_SECTORS,
	TECHNIQUES_SECTORS,
	COMMERCIAL_TEAMS,
	TECHNIQUE_TEAMS,
	PORTEFEUILLE, AttestationTva, RAPPEL_FIN_CONTRAT,
} from "../../../constants/paths";

export default [
	{
		name: "Dashboard",
		url: DASHBOARD,
		icon: "fa fa-pie-chart",
		roles: ROLE_ADMIN,
	},
	{
		name: "Contrats",
		icon: "fa fa-clipboard",
		children: [
			{
				name: "Pré-contrat",
				roles: [ROLE_TECHNICIEN_SAV,ROLE_ADMIN,ROLE_TECHNICO_COMMERCIAL],
				url: PRE_CONTRACT
			},
			{
				name: "Contrats à synchroniser",
				url: CONTRACTS_TO_SYNC
			},
			{
				name: "Contrats archivés",
				url: ARCHIVED_CONTRACTS
			},
			{
				name: "Contrats synchronisés",
				url: CONTRACTS
			},
			{
				name: "Suivi des entretiens",
				url: SUIVI_ENTRETIEN
			},
			{
				name: "Entretiens par technicien",
				url: ENTRETIEN_PAR_TECHNICIEN
			},
			{
				name: "Publipostage",
				url: PUBLIPOSTAGE
			},
			{
				name: "Attestation TVA",
				url: AttestationTva
			},
			{
				name: "Rappel fin de contrat",
				url: RAPPEL_FIN_CONTRAT,
				roles: ROLE_ADMIN,
			},
		]
	},
	{
		name: "Clients",
		icon: "fa fa-user",
		roles: [ROLE_ADMIN,ROLE_TECHNICIEN_SAV, ROLE_TECHNICO_COMMERCIAL],
		children: [
			{
				name: "Portefeuille Clients",
				roles: [ROLE_TECHNICIEN_SAV,ROLE_ADMIN,ROLE_TECHNICO_COMMERCIAL],
				url: PORTEFEUILLE
			},
			{
				name: "Listes des clients",
				roles: ROLE_ADMIN,
				url: CUSTOMERS,
			},
		]
	},
	{
		name: "Equipes",
		roles: ROLE_ADMIN,
		icon: "fa fa-users",
		children: [
			{
				name: "Equipes Commerciales",
				url: COMMERCIAL_TEAMS,
				roles: ROLE_ADMIN,
			},
			{
				name: "Equipes Techniques",
				url: TECHNIQUE_TEAMS,
				roles: ROLE_ADMIN,
			}
		]
	},
	{
		name: "Secteurs",
		roles: ROLE_ADMIN,
		icon: "fa fa-map-pin",
		children: [
			{
				name: "Secteurs Commerciaux",
				url: COMMERCIALS_SECTORS,
				roles: ROLE_ADMIN,
			},
			{
				name: "Secteurs Techniques",
				url: TECHNIQUES_SECTORS,
				roles: ROLE_ADMIN,
			}
		]
	},
	{
		name: "Catalogue",
		roles: ROLE_ADMIN,
		icon: "fa fa-shopping-basket",
		children: [
			{
				name: "Equipements",
				url: EQUIPMENTS,
				roles: ROLE_ADMIN
			},
			{
				name: "Familles d'équipements",
				url: FAMILIES,
				roles: ROLE_ADMIN
			},
			{
				name: "Prestation",
				url: SERVICES,
				roles: ROLE_ADMIN
			},
			{
				name: "Tarifs",
				url: PRICES,
				roles: ROLE_ADMIN
			}
		]
	},
	{
		name: "Paramètres",
		roles: ROLE_ADMIN,
		icon: "fa fa-cog",
		children: [
			{
				name: "Membres activés",
				url: MEMBERS,
				roles: ROLE_ADMIN,
			},
			{
				name: "Membres désactivés",
				url: DISABLED_MEMBERS,
				roles: ROLE_ADMIN,
			}
		]
	},
	{
		name: "Synchroniser",
		url: SYNC,
		icon: "fa fa-refresh"
	}
];
