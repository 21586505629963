import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ContractsList from '../';
import {connect} from 'react-redux';
import {getAllContractsToSyncList, removeContractFromToSync} from '../../redux';
import {bindActionCreators} from 'redux';
import {CONTRACTS_TO_SYNC} from '../../../../constants/paths';

export class ContractsToSync extends Component {
	state = {
		customFilters: [],
	};

	onSetState = arg => this.setState(arg);

	onRemove = itemID => this.props.onRemove(itemID);

	onCustomFiltersChange = (customFilters) => {
		this.setState({
			customFilters,
		})
	}

	filterData = () => {
		const checkFilter = (item, filter) => {

			switch (filter.id) {
				case "customer.lastName":
					return item.customer && item.customer.lastName ? item.customer.lastName.toUpperCase().startsWith(filter.value.toUpperCase()) : filter.value === ""

				case "customer.city":
					return item.customer && item.customer.city ? item.customer.city.toUpperCase().startsWith(filter.value.toUpperCase()) : filter.value === ""

				case "alreadyInstalled":
					return item.alreadyInstalled ? Boolean(filter.value) : !Boolean(filter.value)
			}
		}

		return this.props.data.filter(
			item => {
				let condition = true

				this.state.customFilters.forEach(filter => {
					const result = checkFilter(item, filter);

					if (!result) {
						condition = false
					}
				})

				return condition;
			}
		)
	}

	render() {
		const {onEdit, onSetState, onRemove} = this;
		const {data, /*totalContractToSynced*/} = this.props;


		const filteredData = this.filterData()

		return (
			<ContractsList
				data={filteredData}
				pages={Math.ceil(data.length / 20)}
//				totalContractToSynced={totalContractToSynced}
				onEdit={onEdit}
				onRemove={onRemove}
				setParentState={onSetState}
				contractType={CONTRACTS_TO_SYNC}
				onCustomFiltersChange={this.onCustomFiltersChange}
				manual
				loaded
			/>
		);
	}
}

ContractsToSync.propTypes = {
	history: PropTypes.shape({push: PropTypes.func.isRequired}).isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	onRemove: PropTypes.func.isRequired,
//	totalContractToSynced:  PropTypes.number.isRequired,
};

export const mapStateToProps = state => ({
	data: getAllContractsToSyncList(state),
//	totalContractToSynced: getTotalContractToSynced(state)
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			onRemove: removeContractFromToSync,
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ContractsToSync);
