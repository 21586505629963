import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {Nav, NavItem, NavLink as RsNavLink} from 'reactstrap';
import {connect} from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {badge, title, divider, handleClick, activeRoute, hideMobile} from './utils';
import nav from './_nav';
import SidebarHeader from '../SidebarHeader';
import SidebarMinimizer from '../SidebarMinimizer';
import {getUserRoles} from '../../Authentication/redux';

/* eslint-disable no-use-before-define */

class Sidebar extends Component {
	testRole = role => {
		if (Array.isArray(role)) {
			let passed = false
				role.map((r) => {
					 if (this.props.roles.find(role => role === r)){
						  passed = true
					 }
				})
			return passed
		}else{
			return this.props.roles.indexOf(role) > -1;
		}
	}

	testRoleChildren(children) {
		children.map( (child, key) => {
			if (child.roles){
				if ( this.testRole(child.roles) === false){
				children.splice(key,1)
				}
			}
		})
		return children
	}

	render() {
		const {props} = this;

		const isExternal = url => {
			const link = url ? url.substring(0, 4) : '';
			return link === 'http';
		};
		const navType = (item, idx) =>
			item.title
				? title(item, idx)
				: item.divider
					? divider(item, idx)
					: item.label
						? navLabel(item, idx)
						: item.children
							? navDropdown(item, idx)
							: navItem(item, idx);

		const navList = items => items.map((item, index) => navType(item, index));

		const navLink = (item, key, classes) => {
			const url = item.url || '';

			return (
				<NavItem key={key} className={classes.item}>
					{isExternal(url) ? (
						<RsNavLink href={url} className={classes.link} active>
							<i className={classes.icon}/>
							{item.name}
							{badge(item.badge)}
						</RsNavLink>
					) : (
						<NavLink
							to={url}
							className={classes.link}
							activeClassName="active"
							onClick={hideMobile}
						>
							<i className={classes.icon}/>
							{item.name}
							{badge(item.badge)}
						</NavLink>
					)}
				</NavItem>
			);
		}; // nav label with nav link
		const navLabel = (item, key) => {
			const classes = {
				item: classNames('hidden-cn', item.class),
				link: classNames('nav-label', item.class ? item.class : ''),
				icon: classNames(
					!item.icon ? 'fa fa-circle' : item.icon,
					item.label.variant ? `text-${item.label.variant}` : '',
					item.label.class ? item.label.class : ''
				),
			};
			return navLink(item, key, classes);
		};

		// nav item with nav link
		const navItem = (item, key) => {
			const classes = {
				item: classNames(item.class),
				link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
				icon: classNames(item.icon),
			};
			return navLink(item, key, classes);
		};

		// nav dropdown
		const navDropdown = (item, key) => {
			const put = (item.roles && this.testRole(item.roles)) || !item.roles;
			if (put) {
				return (
					<li key={key} className={activeRoute(item.url, props)}>
						<a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick}>
							<i className={item.icon}/>
							{item.name}
						</a>
						<ul className="nav-dropdown-items">{navList(this.testRoleChildren(item.children))}</ul>
					</li>
				);
			}
		};

		return (
			<div className="sidebar">
				<SidebarHeader/>

				<nav className="sidebar-nav">
					<Nav>{navList(nav)}</Nav>
				</nav>

				<SidebarMinimizer/>
			</div>
		);
	}


}

Sidebar.propTypes = {
	location: PropTypes.shape({pathname: PropTypes.string.isRequired}).isRequired,
	roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

const mapStateToProps = state => ({
	roles: getUserRoles(state),
});

export default connect(mapStateToProps)(Sidebar);
