export const UUID_PREFIX = 'WH_TMP_ID_';
export const GET = 'get';
export const PUT = 'put';
export const DELETE = 'delete';
export const PATCH = 'patch';
export const HYDRA_MEMBER = 'hydra:member';
export const HYDRA_TOTAL_ITEMS = 'hydra:totalItems';
export const JWT_TOKEN = 'jwt_token';
export const TMP_ID = 'tmpID';
export const SEPA = 'sepa';
export const REPLACE_DOCUMENT = 'replaceDocument';
export const CONTRACT = 'contract';
export const CONTRACT_SELLER = 'contract-seller';
export const TVA = 'tva';
export const CGV = 'cgv';
export const SIGNATURE_ALLOWED_TYPES = [SEPA, CONTRACT, CONTRACT_SELLER,TVA];
