import contractsToSyncReducer from './toSync.reducer';
import syncedContractsReducer from './synced.reducer';
import archivedContractsReducer from './archived.reducer';
import statusContractsReducer from './status.reducer';
import currentContractReducer from './currentContract.reducer';
import {
	CONTRACTS_TO_SYNC_RK,
	SYNCED_CONTRACTS_RK,
	STATUS_CONTRACTS_RK,
	CURRENT_CONTRACT_RK,
	ARCHIVED_CONTRACTS_RK,
} from './constants';
import { combineReducers } from 'redux';

export default combineReducers({
	[CONTRACTS_TO_SYNC_RK]: contractsToSyncReducer,
	[ARCHIVED_CONTRACTS_RK]: archivedContractsReducer,
	[SYNCED_CONTRACTS_RK]: syncedContractsReducer,
	[STATUS_CONTRACTS_RK]: statusContractsReducer,
	[CURRENT_CONTRACT_RK]: currentContractReducer,
});
