import React, {Component} from 'react';
import {Form} from 'reactstrap';
import {Field, reduxForm} from 'redux-form'
import {FieldTxt, FieldCheckbox, FieldSelect2} from '../../../../../components/Form/wh-field';
import {ModalBody, ModalFooter, Alert} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';
import CONFIG from '../../../../../constants/parameters';
import UserForm from "./ExportForm";

class ExportForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }
    render() {
        const {error, handleSubmit, submitting} = this.props;

        return(
            <Form onSubmit={handleSubmit} className="form-horizontal">
                <ModalBody>
                    <Field
                        label="De :"
                        name="signatureDate.after"
                        component={FieldTxt}
                        type="date"
                    />

                    <Field
                        label="A :"
                        name="signatureDate.before"
                        component={FieldTxt}
                        type="date"
                    />


                    {error &&
                    <Alert color="danger">
                        <div dangerouslySetInnerHTML={{__html: error}}/>
                    </Alert>
                    }


                </ModalBody>

                <ModalFooter>

                    <LaddaButton
                        className="btn btn-success btn-ladda"
                        loading={submitting}
                        data-style={EXPAND_LEFT}
                        type={'submit'}
                    >
                        <i className="fa fa-save"></i>
                        &nbsp;Exporter
                    </LaddaButton>

                </ModalFooter>

            </Form>
        )
    }
}

ExportForm = reduxForm({
    form: 'ExportForm'
})(ExportForm);


export default ExportForm
