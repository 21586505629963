import moment from 'moment';

export const getDatesFromTimeInterval = (date, startTime, endTime) => {
  let startDate = null
  let endDate = null

  if (startTime) {
    const [startHours, startMinutes] = startTime.split(':')
    
    startDate = moment(date)
    .set('hour', parseInt(startHours))
    .set('minutes', parseInt(startMinutes))
    .toDate()
  }

  if (endTime) {
    const [endHours, endMinutes] = endTime.split(':')
    endDate = moment(date)
      .set('hour', parseInt(endHours))
      .set('minutes', parseInt(endMinutes)).toDate()
  }
  

  return {
    startDate,
    endDate,
  }
}