import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import contract_head_1 from '../../../img/contract_head_1.png';
import contract_head_3 from '../../../img/contract_head_3.png';
import { getServicesList } from '../../Catalog/Service/redux';
import { formatLevelService } from '../utils';

import SpecificConditions from './partials/SpecificConditions';
import ClientInfos from './partials/ClientInfos';
import Materials from './partials/Materials';
import ContractInformations from './partials/ContractInformations';
import Comments from './partials/Comments';
import { CONTRACT_SIGNATURE, CONTRACT_SELLER_SIGNATURE } from '../../Contract/redux/constants';
import './index.scss';

export const ContractPreview = ({ contract: nonFormattedContract, services }) => {
    const contract = formatLevelService(nonFormattedContract, services);

    return (
        <div className="fixed">
            <div size="A4" layout="portrait" className="contract preview">
                <SpecificConditions
                    place={contract.place}
                    customerSignature={contract[CONTRACT_SIGNATURE]}
                    sellerSignature={contract[CONTRACT_SELLER_SIGNATURE]}
                />
                <div className="col">
                    <table className="table-head" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <td>
                                    <img src={contract_head_1} />
                                </td>
                                <td className="text-center bold">
                                    Contrat n°&nbsp;
                                    {contract.reference}
                                </td>
                                <td>
                                    <img src={contract_head_3} style={{ maxWidth: '100px' }} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ClientInfos contract={contract} />
                    <Materials list={contract.contractInformation} />
                    <ContractInformations contract={contract} />
                    <Comments note={contract.note} />
                </div>
            </div>
        </div>
    );
};

ContractPreview.propTypes = {
    contract: PropTypes.shape({}).isRequired,
    services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export const mapStateToProps = state => ({
    services: getServicesList(state),
});

export default connect(mapStateToProps)(ContractPreview);
