import { EQUIPMENTS_RK } from './constants';
import { CATALOG_RK, LOADED, DATA, PAGES, EDITED_ITEM } from '../../redux/constants';

export const getEquipments = state => state[CATALOG_RK][EQUIPMENTS_RK];
export const getEquipmentsPages = state => state[CATALOG_RK][EQUIPMENTS_RK][PAGES];
export const getEquipmentsList = state => state[CATALOG_RK][EQUIPMENTS_RK][DATA];
export const getEquipmentsLoadedState = state => state[CATALOG_RK][EQUIPMENTS_RK][LOADED];
export const getEquipmentsEditedItem = state => state[CATALOG_RK][EQUIPMENTS_RK][EDITED_ITEM];

export const getEquipementsByFamily = (state, equipmentFamily) =>
    getEquipmentsList(state).filter(el => el.family.id == equipmentFamily);
