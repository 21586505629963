import store from '../redux/store';
import { getCredentials } from '../modules/Authentication/redux';
import { redirectToLogin } from './redirectTo';
import { isAuthenticated, login } from './session';

const callIfAuthenticated = async callback => {
    try {
        if (await isAuthenticated()) {
            callback();
        } else {
            const { username, password } = getCredentials(store.getState());
            await login(username, password);
            callback();
        }
    } catch (error) {
        redirectToLogin();
    }
};

export default callIfAuthenticated;
