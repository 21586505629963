export const CATALOG_RK = 'catalog';

export const DATA = 'data';
export const LOADED = 'loaded';
export const PAGES = 'pages';
export const EDITED_ITEM = 'edited';

export const SET_DATA = 'SET_DATA';
export const SET_PAGES = 'SET_PAGES';
export const SET_LOADED = 'SET_LOADED';
export const SET_EDITED = 'SET_EDITED';
export const REMOVE_ITEM = 'REMOVE_ITEM';
