import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import getRef from '../../../../utils/getRef';

export const CellTemplate = ({ value, self }) => (
		<div>
			<Button
				color="info"
				onClick={() => getRef(self.createEditPopup, true).handleOpen(value)}>
				<i className="fa fa-edit" />
			</Button>
			<Button color="danger" onClick={() => self.remove(value)}>
				<i className="fa fa-trash" />
			</Button>
		</div>
	);

CellTemplate.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	self: PropTypes.shape({}).isRequired,
};

export const getActionCellTemplate = (row, self) => <CellTemplate value={row.value} self={self} />;
