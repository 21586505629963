import { SET_ONLINE_MODE } from './constants';
import OnlineTypes from './types';
import { createReducer } from 'redux-friends';

const defaultOnlineState = false;

const behaviours = {
    [OnlineTypes[SET_ONLINE_MODE]]: (state, { payload }) => payload,
};

export default createReducer(behaviours, defaultOnlineState);
