import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContractsList from '../';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	getArchivedContractsList,
	getArchivedPagesContracts,
	fetchArchivedContracts, removeContract,
} from '../../redux';
import { ARCHIVED_CONTRACTS } from '../../../../constants/paths';
import {getTotalArchivedContract} from "../../redux/selectors";

class ArchivedContracts extends Component {
	state = { loaded: false };

	onSetState = arg => {
		this.setState(arg)
	};

	render() {
		const { onSetState } = this;
		const { pages, data,onRemove, onLoad, totalArchivedContract } = this.props;
		const { loaded } = this.state;

		return (
			<ContractsList
				onLoad={onLoad}
				onRemove={onRemove}
				data={data}
				pages={pages}
				totalArchivedContract={totalArchivedContract}
				loaded={loaded}
				setParentState={onSetState}
				contractType={ARCHIVED_CONTRACTS}
				manual
				isSyncedContracts
				isArchivedContracts
			/>
		);
	}
}

ArchivedContracts.defaultProps = { data: [] };

ArchivedContracts.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
	pages: PropTypes.number.isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({})),
	onLoad: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	totalArchivedContract: PropTypes.number.isRequired,
};

export const mapStateToProps = state => ({
	data: getArchivedContractsList(state),
	pages: getArchivedPagesContracts(state),
	totalArchivedContract: getTotalArchivedContract(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			onLoad: fetchArchivedContracts,
			onRemove: removeContract,
		}, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ArchivedContracts);
