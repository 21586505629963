import LoginTypes from './types';
import { SET_LOGIN } from './constants';
import { createReducer } from 'redux-friends';

const defaultState = null;
const behaviors = {
    [LoginTypes[SET_LOGIN]]: (state, { payload }) => payload,
};

const LoginReducer = createReducer(behaviors, defaultState);

export default LoginReducer;
