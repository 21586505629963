import { USER_RK, AUTH_RK, LOGIN_RK } from './constants';
import {ROLE_SELLER, ROLE_ADMIN, ROLE_SELLER_SENIOR, ROLE_TECHNICO_COMMERCIAL} from '../../../constants/roles';

export const getUser = state => state[AUTH_RK][USER_RK] || {};
export const getUserRoles = state => getUser(state).roles || [];
export const getIsSeller = state =>
	(getUserRoles(state).includes(ROLE_TECHNICO_COMMERCIAL)
		|| getUserRoles(state).includes(ROLE_SELLER)
		|| getUserRoles(state).includes(ROLE_SELLER_SENIOR))
	&& !getUserRoles(state).includes(ROLE_ADMIN);

export const getCredentials = state => state[AUTH_RK][LOGIN_RK];
