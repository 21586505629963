import React, { Component } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import Form from './Form';
import * as levelService from '../../../../services/catalog/service';
import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import Loading from '../../../../components/Loading';

import PropTypes from 'prop-types';

class CreateEditPopup extends Component {
    state = {
        open: false,
        data: {},
        loaded: true,
        edit: false,
    };

    handleOpen = (id = null) => {
        this.setState({
            open: true,
        });

        if (id) {
            this.setState({
                loaded: false,
                edit: true,
            });

            levelService.view(id).then(data => {
                this.setState({
                    data,
                    loaded: true,
                });
            });
        }
    };

    handleClose = () => this.setState({ open: false, data: {} });

    onSubmit = async data => {
        const edit = !data.id ? levelService.create : levelService.update;
        try {
            const result = edit(data);
            this.props.newData(result);
            toast.success('Enregistrement réussi');
            this.handleClose();
        } catch (error) {
            throw new SubmissionError(error);
        }
    };

    render() {
        const { className } = this.props;
        const { loaded, edit, data } = this.state;

        return (
            <Modal isOpen={this.state.open} className={className} fade={false}>
                <ModalHeader toggle={this.handleClose}>{edit ? 'Editer' : 'Nouveau'}</ModalHeader>
                {!loaded && <Loading />}
                {loaded && <Form onSubmit={this.onSubmit} initialValues={data} />}
            </Modal>
        );
    }
}
CreateEditPopup.defaultProps = {
    className: '',
};

CreateEditPopup.propTypes = {
    className: PropTypes.string,
    newData: PropTypes.func.isRequired,
};

export default CreateEditPopup;
