import React, {Component,createRef} from "react";
import {Button, Card, CardHeader} from "reactstrap";
import "../../../styles";
import 'moment/locale/fr';
import {redirectTo} from "../../../utils/redirectTo";
import {CONTRACTS} from "../../../constants/paths";
import * as contractService from "../../../services/contract";
import Loading from "../../../components/Loading";
import {getUserRoles} from "../../Authentication/redux";
import connect from "react-redux/lib/connect/connect";
import PropTypes from "prop-types";
import {ROLE_ADMIN} from "../../../constants/roles";
import {addContractToSync} from "../redux";
import {bindActionCreators} from "redux";
import Swal from "sweetalert2";
import {remove} from "../../../services/contract";
import WHTable from "../../../components/WHTable";
import PopupContract from "../List/ToSync/popup";

class PreContrat extends Component {
	createPopup = createRef();
	state = {
		appointmentId: null,
		open: false,
		data: [],
		pages: null,
		loaded: true,
		table: null,
		archivePending: false,
	};

	onRemove = (id) => {
		Swal.fire({
			title: "Supprimer",
			text: 'Êtes-vous sûr de vouloir supprimer ce contrat ?',
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#3085d6',
			denyButtonColor: '#d33',
			confirmButtonText: 'Supprimer',
			denyButtonText: 'Annuler',
		}).then(async (result) => {
			if (result.isConfirmed) {
				this.setState({
					loaded: false
				});
				try {
					await remove(id)
					await this.reload();
					Swal.fire({
						title: 'Le contrat a bien été supprimé',
						icon: 'success',
						timer: 1500,
						timerProgressBar: true,
						showConfirmButton: false,
					})
				}catch (e) {
					Swal.fire({
						title: "Le contrat n'a pas été supprimé",
						icon: 'error',
						timer: 1500,
						timerProgressBar: true,
						showConfirmButton: false,
					})
				}
				this.setState({
					loaded: true
				});
			} else if (result.isDenied) {
				Swal.fire({
					title: "Le contrat n'a pas été supprimé",
					icon: 'error',
					timer: 1500,
					timerProgressBar: true,
					showConfirmButton: false,
				})
			}
		})
	};

	columns = () => {
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				width: 130,
				Cell: row => {
					return (
						<div>
							<Button
								color="info"
								onClick={() => redirectTo(CONTRACTS, row.value)}
							>
								<i className="fa fa-edit"/>
							</Button>
							{this.props.roles.includes("ROLE_ADMIN") &&
								<Button
									color="danger"
									onClick={() => {
										this.onRemove(row.value);
									}}
								>
									<i
										className="fa fa-trash"
										aria-hidden="true"
									/>
								</Button>
							}
						</div>
					)
				}
			},
			{
				Header: "Nom",
				accessor: "customer.lastName",
			},
			{
				Header: "Prénom",
				accessor: "customer.firstName",
			},
			{
				Header: "Adresse",
				accessor: "customer.address",
			},
			{
				Header: "Code postal",
				accessor: "customer.zipCode",
			},
			{
				Header: "Ville",
				accessor: "customer.city",
			},
		]
	};

	reload = () => {
		this.load(this.state.table)
	}

	load = (state) => {
		this.setState({
			loaded: false,
			table: state
		});
		return contractService.searchNest({
			pageSize: state.pageSize,
			page: state.page,
			filtered: [
				{id: 'preContrat', value: 1},
			]
		}).then(data => {
			this.setState(
				{
					data: []
				},
				() => {
					this.setState({
						data: data.pagination.data,
						pages: data.pagination.meta.totalPages,
						loaded: true,
					});
				}
			);
		});
	};

	Gocreate = () => {
		this.createPopup.current.handleOpen()
	};
	create = (dataToSend) => {
		dataToSend.preContrat = true
		this.props.onSaveContract(dataToSend);
		redirectTo(CONTRACTS, dataToSend.tmpID);
	};


	render() {
		const {
			manual,
			roles
		} = this.props;

		const {columns, load} = this;

		const isAdmin =
			roles &&
			(roles.indexOf(ROLE_ADMIN) > -1);

		return (

			<div>
				<PopupContract create={this.create} ref={this.createPopup}/>
				{this.state.archivePending && <Loading/>}
				<div className="animated fadeIn">
					<Card className="border-card">
						<CardHeader className="card-header-contract">
							<h2>Pré contrat</h2>
							{isAdmin && (
								<div className="card-actions">
									<Button
										className="button-create button-text"
										onClick={() => this.Gocreate()}
									>
										<i className="fa fa-clipboard fa-lg icone-button-text"> </i> Créer
									</Button>
								</div>)}
						</CardHeader>
						{!this.state.archivePending && <WHTable
							data={this.state.data}
							pages={this.state.pages}
							columns={columns()}
							loading={!this.state.loaded}
							manual={manual}
							onFetchData={load}
						/>}
					</Card>
				</div>

			</div>
		);
	}
}

PreContrat.propTypes = {
	roles: PropTypes.arrayOf(PropTypes.any).isRequired,
	onSaveContract: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	roles: getUserRoles(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			onSaveContract: addContractToSync,
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PreContrat);


