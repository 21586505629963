import { STATUS_FILTER_KEY } from '../constants';

export const getHref = contract => {
	const stringifiedContract = encodeURIComponent(JSON.stringify(contract, null, 4));
	const result = `mailto:dev@whatson-web.com?subject=Omeo Entretien Erreur Contrat&body=${stringifiedContract}`;
	return result;
};

const getFilteredValue = (state) => (
	state.filtered.filter((el) => el.id === 'status')
);

export const saveStatusFilterValue = state => {
	const filterValue = getFilteredValue(state);
	localStorage.setItem(STATUS_FILTER_KEY, JSON.stringify(filterValue));
};

export const getStatusFilterValue = () => JSON.parse(localStorage.getItem(STATUS_FILTER_KEY)) || [];
