import React, {Component, createRef} from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import PropTypes from 'prop-types';
import * as ContractService from "../../../services/contract";
import Loading from "../../../components/Loading";
import {Document, Page} from "react-pdf";
import {Signature} from "../../Signature";
import {CGV} from "../../../constants";
import FormSignPopup from "../FormSignPopup/FormSignPopup";
import TvaForm from "../Form/Views/TvaForm";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {updateCGVSignature} from "../../../services/contract";


class SignPopup extends Component {
	state = {
		open: false,
		disabled: false,
		openCGV: false,
		openTVA: false,
		data: {},
		contractOpen: false,
		sepaOpen: false,
		yousign: {
			url: null,
			error: false,
			loading: false
		},
		signature: {
			contract: false,
			cgv: false,
			sepa: false,
			tva: false
		},
		pagePDF: 1
	};
	sigPadCGV = createRef();
	beginSign = async (data) => {
		this.setState({signature: data}, () => this.signatureContinue());
	}

	signatureContinue = async () => {
		const data = this.state.signature
		if (data.contract) {
			try {
				await this.contractSign()
				this.setState({
					signature: {
						...this.state.signature,
						contract: false,
					}
				})
			} catch (e) {
			}

		} else if (data.sepa) {
			try {
				await this.sepaSign()
				this.setState({
					signature: {
						...this.state.signature,
						sepa: false,
					}
				})
			} catch (e) {
			}
		} else if (data.cgv) {
			try {
				await this.handleOpenServices("openCGV")
				this.setState({
					signature: {
						...this.state.signature,
						cgv: false,
					}
				})
			} catch (e) {
			}
		} else if (data.tva) {
			try {
				await this.handleOpenServices("openTVA")
				this.setState({
					signature: {
						...this.state.signature,
						tva: false,
					}
				})
			} catch (e) {
			}
		} else {
			window.location.reload();
		}
	}
	handleCloseServices = (event) => {
		this.setState({[event]: false});
		if (event === "contractOpen" || event === "sepaOpen") {
			toast.success('Enregistrement réussi');
			this.signatureContinue()
		}
	};
	handleOpenServices = (event) => {
		this.setState({[event]: true});
	};

	handleOpen = data => this.setState({open: true, data});

	handleClose = () => {
		this.setState({open: false, data: {}});
		this.props.onClose && this.props.onClose(this.state.data);
	};

	contractSign = async () => {
		this.setState({
			contractOpen: true,
			yousign: {
				loading: true
			}
		});
		try {
			const url = await ContractService.yousign(this.props.id)
			if (!url[1]) {
				const newText = url[0].split('\n').map(str => <p>{str}</p>);
				this.setState({
					yousign: {
						error: true,
						url: newText,
						loading: false
					}
				})
				return false
			} else {
				this.setState({
					yousign: {
						error: false,
						url: url[0],
						loading: false
					}
				})
				return true
			}
		} catch (e) {
			await Swal.fire(
				'Echec !',
				"Un problème est survenue dans la signature",
				'error',
			).then((result) => {
				this.handleClose()
			})
		}
	}


	sepaSign = async () => {
		this.setState({
			sepaOpen: true,
			yousign: {
				loading: true
			}
		});
		try {
			const url = await ContractService.yousignSepa(this.props.id)
			if (!url[1]) {
				const newText = url[0].split('\n').map(str => <p>{str}</p>);
				this.setState({
					yousign: {
						error: true,
						url: newText,
						loading: false
					}
				})
			} else {
				this.setState({
					yousign: {
						error: false,
						url: url[0],
						loading: false
					}
				})
			}
		} catch (e) {
			await Swal.fire(
				'Echec !',
				"Un problème est survenue dans la signature",
				'error',
			).then((result) => {
				this.handleClose()
			})
		}
	}

	goToPrevPage = () => {
		if (this.state.pagePDF > 1) {
			this.setState(state => ({pagePDF: state.pagePDF - 1}));
		}
	}

	goToNextPage = () => {
		if (this.state.pagePDF < 18) {
			this.setState(state => ({pagePDF: state.pagePDF + 1}));
		}
	}

	signCGV = async () => {
		try {
			const signature = this.sigPadCGV.current.getTrimmedCanvas().toDataURL('image/png')
			const contract = await ContractService.view(this.props.id)
			contract.CGVSignature = signature
			await ContractService.updateCGVSignature(contract)
			toast.success('Enregistrement réussi');
			this.handleCloseServices("openCGV")
			this.signatureContinue()
		} catch (e) {
			toast.error('Une erreur est survenue');
			this.handleCloseServices("openCGV")
		}
	}

	render() {
		const {
			className,
			isContractValid,
			isSepaValid,
			contractSignature,
			sepaSignature,
			CGVSignature,
			handleSubmit,
			contract
		} = this.props;
		const {contractSign, sepaSign, signCGV, beginSign, signatureContinue} = this;
		const changeDisabled = (value) => {
			this.setState({disabled: value})
		}
		const TVAFill = !!contract.attestationTVA
		const initialSign = {
			"contract": !contractSignature,
			"cgv": !CGVSignature,
			"sepa": !sepaSignature,
			"tva": !TVAFill
		}
		const initialTVA = {
			"cac1": "1",
			"cac2": "1",
			"cac3": "1",
			"cac4": true,
			"cac5": true,
			"cac12": true,
			"cac13": true,
			"cac14": true,
		}
		const {
			open,
			contractOpen,
			sepaOpen,
			data: {paymentType},
			yousign: {loading, url, error},
			openCGV
		} = this.state;
		return (
			<>
				<Modal isOpen={openCGV} fade={false} size="lg" style={{maxWidth: '100%'}}>
					<ModalHeader toggle={() => this.handleCloseServices("openCGV")}>CGV</ModalHeader>
					<Document file={"/cgv.pdf"}>
						<Page pageNumber={this.state.pagePDF}>
						</Page>
					</Document>
					<div className={"alignBtn"}>
						<button className={"btn btn-danger btn-lg mr-2"} onClick={this.goToPrevPage}>Précédent</button>
						<button className={"btn btn-success btn-lg ml-2"} onClick={this.goToNextPage}>Suivant</button>
					</div>
					<Signature changeDisabled={changeDisabled} type={CGV} sigPadContract={this.sigPadCGV}/>
					<button className={"btn btn-success btn-lg ml-2"} disabled={this.state.disabled}
							onClick={this.signCGV}>Signer
					</button>
				</Modal>

				<Modal isOpen={this.state.openTVA} fade={false} style={{maxWidth: '100%'}}>
					<ModalHeader toggle={() => this.handleCloseServices("openTVA")}>attestation TVA</ModalHeader>
					<TvaForm signatureContinue={() => signatureContinue()}
							 handleCloseServices={() => this.handleCloseServices("openTVA")} alone={false}
							 id={this.props.id}
							 initialValues={initialTVA} handleClose={() => this.handleCloseServices("openTVA")}/>
				</Modal>


				<Modal size="sm" isOpen={open} className={className} fade={false}>
					<ModalHeader toggle={this.handleClose}>Documents</ModalHeader>

					<ModalBody>
						<FormSignPopup
							beginSign={beginSign}
							initialValues={initialSign}
							isContractValid={isContractValid}
							contractSignature={contractSignature}
							CGVSignature={CGVSignature}
							sepaSignature={sepaSignature}
							paymentType={paymentType}
							isSepaValid={isSepaValid}
							TVAValid={TVAFill}/>

					</ModalBody>
				</Modal>


				<Modal style={{maxWidth: '100%'}} isOpen={contractOpen} className="h-100"
					   contentClassName={"h-100 w-100"} fade={true}>
					<ModalHeader toggle={() => this.handleCloseServices("contractOpen")}/>
					<ModalBody>
						{loading && <Loading/>}
						{!loading && error &&
							<p>{url}</p>}
						{!loading && !error &&
							<iframe src={url} sandbox={"allow-scripts allow-forms allow-same-origin"}
									className="w-100 h-100"/>}
					</ModalBody>
				</Modal>


				<Modal style={{maxWidth: '100%'}} isOpen={sepaOpen} className="h-100" contentClassName={"h-100 w-100"}
					   fade={true}>
					<ModalHeader toggle={() => this.handleCloseServices("sepaOpen")}/>
					<ModalBody>
						{loading && <Loading/>}
						{!loading && error &&
							<p>{url}</p>}
						{!loading && !error &&
							<iframe src={url} sandbox={"allow-scripts allow-forms allow-same-origin"}
									className="w-100 h-100"/>}
					</ModalBody>
				</Modal>
			</>
		);
	}
}

SignPopup.defaultProps = {
	className: '',
	onClose: undefined,
	isContractValid: false,
	isSepaValid: false,
};
SignPopup.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	id: PropTypes.string.isRequired,
	isContractValid: PropTypes.bool,
	isSepaValid: PropTypes.bool,
};

export default SignPopup


