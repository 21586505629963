import { getActionCellTemplate } from '../CellTemplate/Action';
import {Button} from "reactstrap";
import React from "react";
const getColumns = self => [
    {
        Header: '',
        accessor: 'id',
        filterable: false,
        Cell: row => getActionCellTemplate(row, self),
        maxWidth: 90,
    },
    {
        Header: 'Nom',
        accessor: 'name',
    },
    {
        Header: 'Famille',
        accessor: 'family.name',
    },
	{
		Header: 'activé',
		accessor: 'enabled',
		Cell: row => (
			<div>
				<p>{row.value === true ? "Activé" : "Désactivé"}</p>
			</div>
		),
	},
];

export default getColumns;
