import { checkIfContractValid } from '../modules/Contract/utils';
import { SEPA_SIGNATURE, CONTRACT_SIGNATURE } from '../modules/Contract/redux/constants';

const paymentTypeValid = (data) => {
	if (data.paymentType === "sepa"){
		return true
	}
	else return data.paymentType === "payment_entretien";
}


const ifContractIsToBeSynced = contract =>
    !!paymentTypeValid(contract) && checkIfContractValid(contract);

export default ifContractIsToBeSynced;
