import React, {Component} from 'react';
import {Container, Row, Col, Button, Input, InputGroup} from 'reactstrap';
import {withRouter} from "react-router-dom";

class Page404 extends Component {
	handleClick = () => {
		this.props.history.push("/contracts");
	}

	render() {
		return (

			<div className="app flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="6">
							<div className="clearfix">
								<h1 className="float-left display-3 mr-4">404</h1>
								<h4 className="pt-4 display-5">
									Oops ! On dirait que tu t
									{"'"}
									es trompé !
								</h4>
								<p className="text float-left">
									Il semblerait que la page que tu cherches n'existe pas, ou tu n'as pas les droits pour y accéder.
								</p>
							</div>
							<button onClick={this.handleClick} type="button" className="btn btn-primary">RETOUR
							</button>
						</Col>
					</Row>
				</Container>
			</div>

		)
	}
}

export default withRouter(Page404);


