const QueryBuilder = (state) => {
	if (!state) return {};
	let { pageSize, page, sorted, filtered } = state;

	const params = {};

	pageSize ? params.itemsPerPage = pageSize : params.itemsPerPage = 20;
	if (pageSize) params.itemsPerPage = pageSize;

	if (page) params.page = page + 1 ;

	/**
	 * Order
	 */
	params.order = {};
	if (sorted) {
		sorted.map(({ id, desc }) => {
			params.order[id] = desc ? 'desc' : 'asc';
		});
	}

	/**
	 * Filter
	 */
	if (filtered) {

		filtered.map(({ id, value }) => {
			params[id === 'statusName' ? 'status' : id] = value;
		});
	}

	return params;
};

export default QueryBuilder;
