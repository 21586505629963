import React, {Component, createRef} from 'react';
import {Card, CardBody, CardHeader, Col, Form, Row} from 'reactstrap';
import {Field, reduxForm} from 'redux-form'
import {FieldTxt, FieldCheckbox, FieldSelect2, FieldRadio} from '../../../../components/Form/wh-field';
import {ModalBody, ModalFooter, Alert} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';
import {Signature} from "../../../Signature";
import {TVA} from "../../../../constants";
import {attestationTVA} from "../../../../services/contract";
import Loading from "../../../../components/Loading";
import Swal from "sweetalert2";
import {redirectTo, redirectToContract, redirectToDashboard} from "../../../../utils/redirectTo";
import {toast} from "react-toastify";

class TvaForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			disabled: true,
			loadingTVA: false
		};
	}

	sigPadContract = createRef();

	render() {
		const {error, handleSubmit, submitting} = this.props;

		const saveTva = async (e) => {
			this.setState({loadingTVA: true})
			const signature = this.sigPadContract.current.getTrimmedCanvas().toDataURL('image/png')

			let data = {
				...e,
				alone: this.props.alone,
				signature: signature
			}

			if (!this.props.alone) {
				data = {...data, id: this.props.id}
			}
			try {
				await attestationTVA(data)
				this.setState({loadingTVA: false})
				if (this.props.alone) {
					await Swal.fire(
						'Réussi !',
						'Votre attestation a bien été sauvegardée !',
						'success',
					).then((result) => {
						if (result.isConfirmed) {
							redirectToDashboard()
						}
					})
				}else{
					toast.success('Enregistrement réussi');
					this.props.handleCloseServices()
					this.props.signatureContinue()
				}
			} catch (e) {
				await Swal.fire(
					'Echec !',
					"Votre attestation n'a pas été sauvegardée !",
					'error',
				).then((result) => {
					if (result.isConfirmed) {
						if (!this.props.alone) {
							this.props.handleCloseServices()
						} else {
							redirectToDashboard()
						}
					}
				})
			}
		}

		const changeDisabled = (value) => {
			this.setState({disabled: value})
		}


		return (
			<Form onSubmit={handleSubmit(saveTva)} className="form-vertical custom-form">
				<ModalBody>
					{this.state.loadingTVA && <Loading/>}
					{this.props.alone &&
						<Card>
							<CardHeader>IDENTITÉ DU CLIENT OU DE SON REPRÉSENTANT</CardHeader>
							<CardBody>
								<p>Je soussigné(e) :</p>
								<Row>
									<Col md={6}>
										<Field
											label={"Nom : "}
											name="a1"
											component={FieldTxt}
											type="text"
										/>
									</Col>
									<Col md={6}>
										<Field
											label={"Prénom : "}
											name="a2"
											component={FieldTxt}
											type="text"
										/>
									</Col>
								</Row>
								<Row>
									<Col md={4}>
										<Field
											label={"Adresse : "}
											name="a3"
											component={FieldTxt}
											type="text"
										/>
									</Col>
									<Col md={4}>
										<Field
											label={"Code postal : "}
											name="a5"
											component={FieldTxt}
											type="text"
										/>
									</Col>
									<Col md={4}>
										<Field
											label={"Commune : "}
											name="a4"
											component={FieldTxt}
											type="text"
										/>
									</Col>
								</Row>
							</CardBody>
						</Card>
					}
					<Card>
						<CardHeader>NATURE DES LOCAUX</CardHeader>
						<CardBody>
							<p>J’atteste que les travaux à réaliser portent sur un immeuble achevé depuis plus de deux
								ans à la
								date de commencement des travaux et affecté à l’habitation à l’issue de ces travaux
								:</p>

							<div>
								<Field
									label="maison ou immeuble individuel :"
									id={"cac1One"}
									name="cac1"
									value="1"
									type="radio"
									component={FieldRadio}
								/>
								<Field
									label="immeuble collectif :"
									name="cac1"
									id={"cac1Two"}
									value="2"
									type="radio"
									component={FieldRadio}
								/>
								<Field
									label="appartement individuel:"
									name="cac1"
									id={"cac1Three"}
									value="3"
									type="radio"
									component={FieldRadio}
								/>


							</div>

							<Row>
								<Col md={4}>
									<Field
										label="autre (précisez la nature du local à usage d’habitation):"
										name="cac1"
										id={"cac1Four"}
										value="4"
										type="radio"
										component={FieldRadio}
									/>

								</Col>
								<Col xl={6} sm={6} md={6}>
									<Field
										noLabel
										name="a5a"
										component={FieldTxt}
										type="text"
									/>
								</Col>
							</Row>
							<p>Les travaux sont réalisés dans :</p>
							<Field
								label="un local affecté exclusivement ou principalement à l’habitation :"
								name="cac2"
								id={"cac2One"}
								type="radio"
								component={FieldRadio}
								value="1"
							/>
							<Field
								type="radio"
								label="des pièces affectées exclusivement à l’habitation situées dans un local affecté pour moins de 50 % à cet usage :"
								name="cac2"
								id={"cac2Two"}
								component={FieldRadio}
								value="2"
							/>
							<Row>
								<Col md={7}>
									<Field
										label="des parties communes de locaux affectés exclusivement ou principalement à l’habitation dans une proportion de :"
										name="cac2"
										id={"cac2Three"}
										type="radio"
										component={FieldRadio}
										value="3"
									/>
								</Col>
								<Col xl={3} sm={3} md={3}>
									<Field
										noLabel
										name="a6"
										component={FieldTxt}
										type="text"
									/>
								</Col>
								<Col xl={2}><p> millièmes de l’immeuble :</p></Col>
							</Row>
							<Field
								label="un local antérieurement affecté à un usage autre que d’habitation et transformé à cet usage :"
								name="cac2"
								id={"cac2Four"}
								type="radio"
								component={FieldRadio}
								value="4"
							/>
							<Field
								label="Adresse * :"
								name="a7"
								component={FieldTxt}
								type="text"
							/>
							<Field
								label=" Commune:"
								name="a8"
								component={FieldTxt}
								type="text"
							/>
							<Field
								label="Code postal :"
								name="a9"
								component={FieldTxt}
								type="text"
							/>
							<p>dont je suis :</p>
							<Field
								label="propriétaire :"
								name="cac3"
								id={"cac3One"}
								type="radio"
								component={FieldRadio}
								value="1"
							/>
							<Field
								label="Locataire :"
								name="cac3"
								id={"cac3Two"}
								type="radio"
								component={FieldRadio}
								value="2"
							/>
							<Field
								label="autre (précisez votre qualité) :"
								name="cac3"
								id={"cac3Three"}
								type="radio"
								component={FieldRadio}
								value="3"
							/>
							<Field
								name="a10"
								component={FieldTxt}
								type="text"
							/>
						</CardBody>
					</Card>


					<Card>
						<CardHeader>NATURE DES TRAVAUX</CardHeader>
						<CardBody>
							<p>J’atteste que sur la période de deux ans précédant ou suivant la réalisation des travaux
								décrits
								dans la présente attestation, les travaux :</p>


							<Field
								label="n’affectent ni les fondations, ni les éléments, hors fondations, déterminant la résistance et la rigidité de l’ouvrage, ni la consistance des façades (hors ravalement)"
								name="cac4"
								id={"cac4"}
								type="checkbox"
								component={FieldCheckbox}
							/>

							<Row>
								<Col xl={6}>
									<Field
										label="n’affectent pas plus de cinq des six éléments de second œuvre suivants "
										name="cac5"
										id={"cac5"}
										component={FieldCheckbox}
										type="checkbox"
									/>
								</Col>
								<Col xl={6}>
									<p>Cochez les cases correspondant aux éléments affectés :</p>
									<Field
										label="planchers qui ne déterminent pas la résistance ou la rigidité de l’ouvrage "
										name="cac6"
										id={"cac6"}
										type="checkbox"
										component={FieldCheckbox}
									/>
									<Field
										label="huisseries extérieures"
										name="cac7"
										id={"cac7"}
										type="checkbox"
										component={FieldCheckbox}
									/>
									<Field
										label="cloisons intérieures "
										name="cac8"
										id={"cac8"}
										type="checkbox"
										component={FieldCheckbox}
									/>
									<Field
										label="installations sanitaires et de plomberie"
										name="cac9"
										id={"cac9"}
										type="checkbox"
										component={FieldCheckbox}
									/>
									<Field
										label="installations électriques"
										name="cac10"
										id={"cac10"}
										type="checkbox"
										component={FieldCheckbox}
									/>
									<Field
										label="système de chauffage (pour les immeubles situés en métropole)"
										name="cac11"
										id={"cac11"}
										type="checkbox"
										component={FieldCheckbox}
									/>
								</Col>

							</Row>
							<p>NB: tous autres travaux sont sans incidence sur le bénéfice du taux réduit</p>
							<Field
								label="n’entraînent pas une augmentation de la surface de plancher de la construction existante supérieure à 10 %."
								name="cac12"
								id={"cac12"}
								type="checkbox"
								component={FieldCheckbox}
							/>
							<Field
								label="ne consistent pas en une surélévation ou une addition de construction."
								name="cac13"
								id={"cac13"}
								type="checkbox"
								component={FieldCheckbox}
							/>
							<Field
								label="J’atteste que les travaux visent à améliorer la qualité énergétique du logement et portent sur la fourniture,
						 la pose, l’installation ou l’entretien des matériaux, appareils et équipements dont la liste figure dans la notice
						 (1 de l’article 200 quater du code général des impôts – CGI)
						 et respectent les caractéristiques techniques et les critères de performances minimales fixés par un arrêté du ministre du budget
						 (article 18 bis de l’annexe IV au CGI)."
								name="cac14"
								id={"cac14"}
								type="checkbox"
								component={FieldCheckbox}
							/>
							<Field
								label=" J’atteste que les travaux ont la nature de travaux induits indissociablement liés à des travaux d’amélioration de la qualité énergétique soumis au taux de TVA de 5,5 %."
								name="cac15"
								id={"cac15"}
								type="checkbox"
								component={FieldCheckbox}
							/>
						</CardBody>
					</Card>

					<Card>
						<CardHeader>CONSERVATION DE L’ATTESTATION ET DES PIÈCES JUSTIFICATIVES</CardHeader>
						<CardBody>
							<p>
								Je conserve une copie de cette attestation ainsi que de toutes les factures ou notes
								émises
								par les entreprises prestataires jusqu’au 31 décembre de la cinquième année suivant la
								réalisation des travaux et m’engage à en produire une copie à l’administration
								fiscale sur sa
								demande.
							</p>
						</CardBody>
					</Card>

					<Card>
						<Signature changeDisabled={changeDisabled} type={TVA} sigPadContract={this.sigPadContract}/>
					</Card>

					<p>* Si différente de l’adresse indiquée sur la page client</p>
					{error &&
						<Alert color="danger">
							<div dangerouslySetInnerHTML={{__html: error}}/>
						</Alert>
					}

				</ModalBody>

				<ModalFooter>

					<button
						className="btn btn-success btn-ladda"
						data-style={EXPAND_LEFT}
						disabled={this.state.disabled}
					>
						<i className="fa fa-save"/>
						&nbsp;Enregistrer
					</button>

				</ModalFooter>

			</Form>

		);

	}
}

TvaForm = reduxForm({
	form: 'TvaForm'
})(TvaForm);

export default TvaForm
