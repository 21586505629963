import React from "react";
import {
	Col,
	FormGroup,
	FormText,
	Label,
	InputGroup,
	FormFeedback,
	Input
} from "reactstrap";

import Select from "react-select";
import "react-select/dist/react-select.css";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {registerLocale} from "react-datepicker";
import fr from "date-fns/locale/fr";
import TimePicker from 'react-time-picker';
import ReactDatePicker from "react-datepicker";
import moment from "moment";

registerLocale("fr", fr);

export const SimpleInput = ({
								placeholder,
								name,
								input,
								label,
								type,
								help,
								meta: {touched, error}
							}) => (
	<Input
		placeholder={placeholder}
		{...input}
		type={type}
		{...(touched && error ? {valid: false} : {})}
	/>
);

export const FieldTxtIcon = ({
								 disabled,
								 placeholder,
								 bsSize,
								 id,
								 prepend,
								 append,
								 name,
								 input,
								 label,
								 type,
								 help,
								 meta: {touched, error},
								 className
							 }) => (
	<FormGroup row>
		<Col md="3">{label && <Label>{label}</Label>}</Col>
		<Col xs="12" md="9">
			<InputGroup>
				{prepend && (
					<div className="input-group-prepend">
						<span className="input-group-text">
							{prepend.icon && <i className={prepend.icon}/>}
							{!prepend.icon && prepend}
						</span>
					</div>
				)}
					<Input
						{...input}
						id={id}
						bsSize={bsSize}
						placeholder={placeholder}
						disabled={disabled}
						type={type}
						{...(touched && error ? {valid: false} : {})}
						className={`${className}`}
						style={{borderBottomLeftRadius: 4, borderTopLeftRadius: 4}}
					/>

				{append && (
					<div className="input-group-append">
						<span
							className="input-group-text"
							style={{borderBottomRightRadius: 4, borderTopRightRadius: 4}}
						>
							{append.icon && <i className={append.icon}/>}
							{!append.icon && append}
						</span>
					</div>
				)}
				{touched && error && (
					<FormFeedback
						className="help-block"
						style={{display: "block"}}
					>
						{error}
					</FormFeedback>
				)}
				{help && <FormText className="help-block">{help}</FormText>}
			</InputGroup>
		</Col>
	</FormGroup>
);
export const FieldRadio = ({
							   name,
							   input,
							   label,
							   bsSize,
							   placeholder,
							   id,
							   disabled,
							   meta: {touched, error}
						   }) => (
	<FormGroup check inline>

		<Input
			style={{cursor: "pointer"}}
			{...input}
			id={id}
			bsSize={bsSize}
			disabled={disabled}
			type="radio"
			{...(touched && error ? {valid: false} : {})}
		/>
		{label && <Label  style={{cursor: "pointer"}} for={id} className="mr-1">{label}</Label>}
	</FormGroup>
);

export const FieldTxt = ({
							 className,
							 name,
							 input,
							 label,
							 type,
							 help,
							 bsSize,
							 placeholder,
							 id,
							 noLabel,
							 disabled,
							 meta: {touched, error}
						 }) => (
	<FormGroup row>
		{!noLabel && <Col md="3">{label && <Label>{label}</Label>}</Col>}
		<Col xs="12" md="9">

				<Input
					className={`field-filters ${className}`}
					{...input}
					id={id}
					bsSize={bsSize}
					placeholder={placeholder}
					disabled={disabled}
					type={type}
					{...(touched && error ? {valid: false} : {})}
				/>


			{touched && error && (
				<FormFeedback className="help-block">{error}</FormFeedback>
			)}
			{help && <FormText className="help-block">{help}</FormText>}
		</Col>
	</FormGroup>
);

export const FieldCheckbox = ({
								  name,
								  className,
								  input,
								  label,
								  type,
								  disabled,
								  id,
								  meta: {touched, error}
							  }) => (
	<FormGroup row>
		<Col md="3"/>

		<Col xs="12" md="9">
			<input disabled={disabled} {...input} id={id}  style={{cursor: "pointer"}} className="mr-1" type="checkbox"/>

			<Label className={className} style={{cursor: "pointer"}} for={id}>{label}</Label>

			{touched && error && <span className="error-txt">{error}</span>}
		</Col>
	</FormGroup>
);

export const FieldSelect = props => {
	let {
		nullValue,
		valueField,
		disabled,
		labelField,
		required,
		help,
		options,
		name,
		input,
		label,
		type,
		defaultValue,
		meta: {touched, error},
		className,
	} = props;

	if (!valueField) valueField = "value";
	if (!labelField) labelField = "name";

	return (
		<FormGroup row>
			<Col md="3">{label && <Label>{label}</Label>}</Col>

			<Col xs="12" md="9">
				<Input
					name={name}
					type="select"
					disabled={disabled}
					{...input}
					{...(touched && error ? {valid: false} : {})}
					className={`field-filters ${className}`}
				>
					{nullValue && <option value={""}>{nullValue}</option>}
					{options.map((option, index) => (
						<option value={option[valueField]} key={index}>
							{option[labelField]}
						</option>
					))}
				</Input>

				{touched && error && (
					<FormFeedback className="help-block">{error}</FormFeedback>
				)}

				{help && <FormText className="help-block">{help}</FormText>}
			</Col>
		</FormGroup>
	);
};

export const FieldSelect2 = ({
								 help,
								 options,
								 name,
								 input,
								 label,
								 type,
								 meta: {touched, error}
							 }) => (
	<FormGroup row>
		<Col md="3">{label && <Label>{label}</Label>}</Col>

		<Col xs="12" md="9">
			<Select
				options={options}
				multi
				{...input}
				onChange={value => input.onChange(value.map(p => p.value))}
				onBlur={() => {
					input.onBlur([...input.value]);
				}}
			/>

			{touched && error && (
				<FormFeedback className="help-block">{error}</FormFeedback>
			)}

			{help && <FormText className="help-block">{help}</FormText>}
		</Col>
	</FormGroup>
);

export const FieldSelectAsync = ({
									 help,
									 options,
									 multi,
									 loadOptions,
									 change,
									 name,
									 input,
									 label,
									 type,
									 meta: {touched, error}
								 }) => (
	<FormGroup row>
		<Col md="3">{label && <Label>{label}</Label>}</Col>

		<Col xs="12" md="9">
			<Select.Async
				name={name}
				placeholder="Chercher"
				noResultsText="Pas de résultat"
				searchPromptText="Commencez votre saisie"
				options={options}
				multi={multi}
				{...input}
				loadOptions={loadOptions}
				onChange={value => input.onChange(value)}
				onBlur={() => input.onBlur(input.value)}
				{...(touched && error ? {valid: false} : {})}
			/>

			{touched && error && (
				<FormFeedback
					className="help-block"
					style={{display: "block"}}
				>
					{error}
				</FormFeedback>
			)}

			{help && <FormText className="help-block">{help}</FormText>}
		</Col>
	</FormGroup>
);

export const ReactDateField = ({onChange, value, className, isClearable, dateFormat, minDate, maxDate, showMonthYearPicker}) => {
	return (
		/*<DatePicker
			onChange={onChange}
			selected={value}
			locale={fr}
			dateFormat="dd/MM/yyyy"
			className={className}
			isClearable={isClearable}
			showYearDropdown={true}
			yearDropdownItemNumber={7}
			calendarStartDay={1}
		/>*/
		<ReactDatePicker
			className={className}
			value={value}
			onChange={onChange}
			dateFormat={dateFormat}
			showMonthYearPicker={showMonthYearPicker}
			minDate={minDate}
			maxDate={maxDate}
			isClearable={isClearable}
		/>
	);
};

export const DateField = ({onChange, value, className, isClearable}) => {
	return (
		<DatePicker
			onChange={onChange}
			selected={value}
			locale={fr}
			dateFormat="dd/MM/yyyy"
			className={className}
			isClearable={isClearable}
			showYearDropdown={true}
			yearDropdownItemNumber={7}
			calendarStartDay={1}
		/>
	);
};

export const DatePickerField = ({input, label, meta: {touched, error}, help, isClearable, disabled, className}) => {


	return (
		<FormGroup row className="date-picker-field">
			<Col md="3">{label && <Label>{label}</Label>}</Col>
			<Col xs="12" md="9">

				<DatePicker
					isClearable={isClearable}
					showYearDropdown={true}
					locale={fr}
					onChange={input.onChange}
					selected={input.value}
					dateFormat="dd/MM/yyyy"
					className={`form-control field-filters ${className}`}
					disabled={disabled}

				/>

				{touched && error && (
					<FormFeedback
						className="help-block"
						style={{display: "block"}}
					>
						{error}
					</FormFeedback>
				)}

				{help && <FormText className="help-block">{help}</FormText>}
			</Col>
		</FormGroup>
	)
}

export const TimePickerField = ({input, label, meta: {touched, error}, help}) => {

	const onChange = (value) => {
		if (typeof value === 'string' || value === null) {
			input.onChange(value)
		}
	}

	return (
		<FormGroup row className="date-picker-field">
			<Col md="3">{label && <Label>{label}</Label>}</Col>
			<Col xs="12" md="9">

				<TimePicker
					onChange={onChange}
					value={input.value}
					disableClock
					className="form-control"
					locale="fr-fr"
				/>

				{touched && error && (
					<FormFeedback
						className="help-block"
						style={{display: "block"}}
					>
						{error}
					</FormFeedback>
				)}

				{help && <FormText className="help-block">{help}</FormText>}
			</Col>
		</FormGroup>
	)
}
