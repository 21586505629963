import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, CardFooter, Form, Alert } from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

import { FieldTxt } from '../../components/Form/wh-field';

const FormPassword = ({ error, handleSubmit, submitting }) => (
    <Card className="border-card">
        <Form onSubmit={handleSubmit} className="form-horizontal">
            <CardHeader className="card-header-contract">
				<h2>Mon mot de passe</h2>
			</CardHeader>

            <CardBody>
                <Field
                    label="Saisir votre nouveau mot de passe :"
                    name="plainPassword"
                    component={FieldTxt}
                    type="password"
                />

                {error && <Alert color="danger">{error}</Alert>}

                <button type="submit" style={{ position: 'absolute', left: '-100000px' }} />
            </CardBody>

            <CardFooter>
                <LaddaButton
                    className="btn btn-success btn-ladda"
                    loading={submitting}
                    data-style={EXPAND_LEFT}
                    type="submit"
                >
                    <i className="fa fa-save" />
                    &nbsp;Enregistrer
                </LaddaButton>
            </CardFooter>
        </Form>
    </Card>
);

FormPassword.defaultProps = {
    error: null,
};
FormPassword.propTypes = {
    error: PropTypes.shape({}),
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export default reduxForm({ form: 'FormPassword' })(FormPassword);
