import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CatalogTemplate from '..';
import Form from './PopupForm';
import {
    getFamilyList,
    getFamilyPages,
    deleteFamilyItem,
    loadFamily,
    getFamilyEditedItem,
    fetchFamilyItem,
    updateFamilyItem,
    createFamilyItem,
} from './redux';

import getColumns from './utils';
import {resetFamilyItem} from "./redux/actions";

const Families = ({ onLoad, onRemove, data, pages, onCreate, onUpdate, onFetch, className, onReset }) => (
    <CatalogTemplate
        title="Gestion des famille d'équipements"
        removeMessage="Êtes-vous sûr de vouloir supprimer cette famille d'équipement ?"
		removeMessageObject="La famille d'équipement"
        data={data}
        pages={pages}
        onLoad={onLoad}
        onRemove={onRemove}
        getPopupData={getFamilyEditedItem}
        getColumns={getColumns}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onFetch={onFetch}
		onReset={onReset}
        className={className}
        FormComponent={Form}
    />
);

Families.defaultProps = {
    className: '',
};
Families.propTypes = {
    onLoad: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    pages: PropTypes.number.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onFetch: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
};

export const mapStateToProps = state => ({
    data: getFamilyList(state),
    pages: getFamilyPages(state),
});

export const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            onLoad: loadFamily,
            onRemove: deleteFamilyItem,
            onCreate: createFamilyItem,
            onUpdate: updateFamilyItem,
            onFetch: fetchFamilyItem,
			onReset : resetFamilyItem
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Families);
