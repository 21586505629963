import React from 'react';
import PropTypes from 'prop-types';

const ClientInfos = ({contract}) => (
    <div className="card">
        <div className="card-header orange">INFORMATIONS CLIENT</div>
        <div className="card-body orange">
            <table className="table">
                <tbody>
                <tr>
                    <th width="31px">Nom&nbsp;:</th>
                    <td>{contract.customer.lastName}</td>
                    <th width="55px">Prénom&nbsp;:</th>
                    <td>{contract.customer.firstName}</td>
                </tr>
                </tbody>
            </table>
            <table className="table">
                <tbody>
                <tr>
                    <th width="28px">Rue&nbsp;:</th>
                    <td>{contract.customer.address}</td>
                    <th width="75px">Code postal&nbsp;:</th>
                    <td>{contract.customer.zipCode}</td>
                    <th width="35px;">Ville&nbsp;:</th>
                    <td>{contract.customer.city}</td>
                </tr>
                </tbody>
            </table>
            <table className="table">
                <tbody>
                <tr>
                    <th width="62px">Téléphone&nbsp;:</th>
                    <td>{contract.customer.mobile}</td>
                    <th width="110px">N° Pièce d&#39;identité&nbsp;:</th>
                    <td>{contract.identity}</td>
                </tr>
                </tbody>
            </table>
            <table className="table">
                <tbody>
                <tr>
                    {/*<th width="102px">Date d&#39;installation&nbsp;:</th>*/}
                    {/*<td width="100px">{contract.installationDate}</td>*/}
                    <th width="70px">Installateur&nbsp;:</th>
                    <td>{contract.fitter}</td>
                    <th width="35px">Mail&nbsp;:</th>
                    <td>{contract.customer.email}</td>
                </tr>
                </tbody>
            </table>
            <table className="table">
                <tbody>
                <tr>
                    <th width="62px">Status :</th>
                    <td>{contract.customer.status}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
);

ClientInfos.propTypes = {
    contract: PropTypes.shape({}).isRequired,
};

export default ClientInfos;
