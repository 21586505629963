import React, {Component} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import PropTypes, {object} from 'prop-types';
import {connect} from 'react-redux';

import FormMaterial from './MaterialForm';
import {getById} from '../../../../services/catalog/equipment';
import {getEquipmentsList} from '../../../Catalog/Equipment/redux/selectors';
import isEmpty from "redux-actions/lib/utils/isEmpty";

class PopupMaterial extends Component {
	state = {
		open: false,
		data: {},
		dataOriginal: {},
		edit: false,
	};

	handleOpen = (data = {}, index = null) => {
		this.setState({dataOriginal: data})
		if (data.type) data.type = data.type.id;
		this.setState({index, open: true, data});
	};

	handleClose = () => {
		if (JSON.stringify(this.state.dataOriginal) === '{}'){
			this.setState({open: false});
		}else{
			this.save(this.state.dataOriginal)
		}

	}
	save = (data) => {
		if (data.type !== "") {
			const type = getById(data.type, this.props.equipments);
			const formattedData = {...data, type};

			this.props.newData(formattedData, this.state.index);
			this.setState({open: false, data: {}});
		}
	}
	onSubmit = data => {
		this.save(data)
	}

	render() {
		const {handleClose, onSubmit} = this;
		const {open, data, edit} = this.state;
		const {className, isArchived, isArchivedContract, userIsAdmin} = this.props;

		const fiedDisabled = isArchivedContract && !userIsAdmin
		let title;
		if (isArchived) {
			title = 'Consulter';
		} else if (edit) {
			title = 'Editer';
		} else {
			title = 'Nouveau';
		}

		return (
			<Modal size="xs" isOpen={open} className={className} fade={false}>
				<ModalHeader toggle={handleClose}>{title}</ModalHeader>
				<FormMaterial
					onSubmit={onSubmit}
					initialValues={data}
					isArchived={isArchived}
					fiedDisabled={fiedDisabled}
				/>
			</Modal>
		);
	}
}

PopupMaterial.defaultProps = {
	className: '',
};

PopupMaterial.propTypes = {
	className: PropTypes.string,
	newData: PropTypes.func.isRequired,
	equipments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	isArchived: PropTypes.bool.isRequired,
};

export const mapStateToProps = state => ({
	equipments: getEquipmentsList(state),
});

export default connect(
	mapStateToProps,
	null,
	null,
	{withRef: true}
)(PopupMaterial);
