import { SET_ARCHIVED_CONTRACTS, ContractsTypes } from './constants';
import { createReducer, spreadPayloadToState, DATA, PAGE_SIZE, PAGES } from 'redux-friends';

const defaultState = {
	[DATA]: [],
	[PAGES]: 1,
	[PAGE_SIZE]: 20,
};

const behaviours = {
	[ContractsTypes[SET_ARCHIVED_CONTRACTS]]: spreadPayloadToState,
};

export default createReducer(behaviours, defaultState);
