import React, { Component } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import Form from './Form';
import * as customerService from '../../../services/customer';
import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';

class CreateEditPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            data: {},
            loaded: true,
            edit: false,
            customer: false,
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.newContract = this.newContract.bind(this);
    }

    handleOpen(id) {
        this.setState({
            open: true,
        });

        if (id) {
            this.setState({
                loaded: false,
                edit: true,
            });

            customerService.view(id).then(data => {
                this.setState({
                    data,
                    loaded: true,
                });
            });
        }
    }

    handleClose() {
        this.setState({
            open: false,
            data: {},
        });
    }

    newContract(customer) {
        alert('tes');
        this.setState({
            customer,
        });
    }

    onSubmit(data) {
        const edit = !data.id ? customerService.create : customerService.update;

        return edit(data)
            .then(data => {
                this.props.newData(data);

                toast.success('Enregistrement réussi');

                this.handleClose();
            })
            .catch(error => {
                throw new SubmissionError(error);
            });
    }

    render() {
        const { className } = this.props;
        const { loaded } = this.state;

        return (
            <Modal isOpen={this.state.open} className={className} fade={false}>
                <ModalHeader toggle={() => this.handleClose()}>
                    {this.state.edit ? 'Editer' : 'Nouveau'}
                </ModalHeader>

                {!loaded && <Loading />}

                {loaded && <Form onSubmit={this.onSubmit} initialValues={this.state.data} />}
            </Modal>
        );
    }
}

export default CreateEditPopup;
