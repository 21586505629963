import React, { Component } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { SubmissionError } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '../../../components/Loading';
import {setEditedFamilyItem} from "../Family/redux/actions";

class Popup extends Component {
    state = {
        open: false,
        loaded: true,
        edit: false,
    };

    handleOpen = async (id = null) => {
        this.setState({ open: true });
        if (id) {
            this.setState({ loaded: false, edit: true });
            await this.props.onFetch(id);
            this.setState({ loaded: true });
        }else{
			await this.props.onReset()
		}
    };

    handleClose = () => this.setState({ open: false,  edit: false });

    onSubmit = async data => {
        const edit = !data.id ? this.props.onCreate : this.props.onUpdate;
        try {
            await edit(data);
            this.props.newData();
            toast.success('Enregistrement réussi');
            this.handleClose();
        } catch (error) {
            throw new SubmissionError(error);
        }
    };

    render() {
        const { className, data, FormComponent } = this.props;
        const { loaded, open, edit } = this.state;
        const { onSubmit, handleClose } = this;
        return (
            <Modal isOpen={open} className={className} fade={false}>
                <ModalHeader toggle={handleClose}>{edit ? 'Editer' : 'Nouveau'}</ModalHeader>
                {!loaded && <Loading />}
                {loaded && <FormComponent onSubmit={onSubmit} initialValues={data} />}
            </Modal>
        );
    }
}

Popup.defaultProps = {
    data: {},
    className: '',
};
Popup.propTypes = {
    newData: PropTypes.func.isRequired,
    className: PropTypes.string,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onFetch: PropTypes.func.isRequired,
	FormComponent: PropTypes.func.isRequired,
    data: PropTypes.shape({}),
    /* eslint-disable-next-line */
    getPopupData: PropTypes.func.isRequired,
};
export const mapStateToProps = (state, { getPopupData }) => ({
    data: getPopupData(state),
});

export default connect(
    mapStateToProps,
    null,
    null,
    { withRef: true }
)(Popup);
