import {
	LOADED,
	SET_DATA,
	SET_PAGES,
	DATA,
	PAGES,
	SET_LOADED,
	EDITED_ITEM,
	SET_EDITED,
	REMOVE_ITEM,
} from './constants';
import { createReducer } from 'redux-friends';

const getDefaultState = () => ({
	[DATA]: [],
	[EDITED_ITEM]: {},
	[LOADED]: false,
	[PAGES]: 1,
});

const getBehaviors = (types, globalKeyAction) => ({
	[types[globalKeyAction]]: (state, { payload }) => ({
		...payload,
	}),
	[types[SET_DATA]]: (state, { payload }) => ({
		...state,
		[DATA]: payload,
	}),
	[types[SET_PAGES]]: (state, { payload }) => ({
		...state,
		[PAGES]: payload,
	}),
	[types[SET_EDITED]]: (state, { payload }) => ({
		...state,
		[EDITED_ITEM]: payload,
	}),
	[types[SET_LOADED]]: (state, { payload }) => ({
		...state,
		[LOADED]: payload,
	}),
	[types[REMOVE_ITEM]]: (state, { payload: deleteID }) => ({
		...state,
		[DATA]: state[DATA].filter(x => x.id !== deleteID),
	}),
});

const catalogCreateReducer = (types, globalKeyAction) =>
	createReducer(getBehaviors(types, globalKeyAction), getDefaultState());

export default catalogCreateReducer;
