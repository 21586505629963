import React, {useEffect, useRef, useState} from 'react';
import {Row, Col} from 'reactstrap';
import {connect} from 'react-redux';

import moment from "moment";
import * as contractService from "../../services/contract";

import Graph from './Graph'
import Select from "react-select";
import * as teamsService from "../../services/team";
import * as sectorsService from "../../services/sectors";
import * as usersService from "../../services/member";

import {getUser, getUserRoles} from "../Authentication/redux";
import * as Members from "../../services/member";
import {ROLE_SELLER} from "../../constants/roles";

function Dashboard({roles, user}) {
	const isSeller = roles.includes("ROLE_SELLER")
	const isTechnico_commercial = roles.includes("ROLE_TECHNICO_COMMERCIAL")
	const isTechnicien = roles.includes("ROLE_TECHNICIEN_SAV")
	const isAdmin = roles.includes("ROLE_ADMIN")
	const isMounted = useRef(false)

	const [startDate, setStartDate] = useState(moment(new Date()).subtract(6, 'd').toDate())
	const [endDate, setEndDate] = useState(new Date())
	const [startDatePrevious, setStartDatePrevious] = useState(moment(startDate).subtract(7, 'd').toDate())
	const [endDatePrevious, setEndDatePrevious] = useState(moment(startDate).subtract(1, 'd').toDate())
	const [statistiques, setStatistiques] = useState(null)
	const [statistiquesPrevious, setStatistiquesPrevious] = useState(null)
	const [pending, setPending] = useState(false)

	const [technicalTeams, setTechnicalTeams] = useState(null)
	const [teams, setTeams] = useState(null)
	const [sectors, setSectors] = useState(null)
	const [sellers, setSellers] = useState(null)
	const [techniciens, setTechniciens] = useState(null)
	const [technicienTeam, setTechnicienTeam] = useState(null)
	const [responsableTeam, setResponsableTeam] = useState(undefined)
	const [responsableTeamUser, setResponsableTeamUser] = useState(undefined)
	const [sectorTeam, setSectorTeam] = useState(undefined)
	const isResponsableTeam = responsableTeamUser !== false
	const [filters, setFilters] = useState({
		sector: null,
		team: null,
		seller: null,
	})

	const findTeamTechnicien = () => {
		const result = technicalTeams.filter(team => team.users[0])
		setTechnicienTeam(
			result.find((team) => {
				return team.users[0].id === user.id;
			})
		)
	}


	//********** renvoie l'objet equipe qui a pour responsable team le User qui est connecté **********//
	const findTeamResponsable = () => {
		setResponsableTeam(
			teams.find((team) => {
				return team.userMaster.id === user.id;
			})
		)

		setResponsableTeamUser(
			teams.some((team) => team.userMaster.id === user.id)
		)
	}

	useEffect(() => {
		if (technicalTeams) {
			findTeamTechnicien()
		}
	}, [technicalTeams])

	useEffect(() => {
		if (teams) {
			findTeamResponsable()
		}
	}, [teams])

	useEffect(() => {
		if (sellers) {
			findTeamSector()
		}
	}, [sellers])


	useEffect(() => {
		loadDashboard()
	}, [startDate, endDate, filters])

	useEffect(() => {
		loadDashboardPrevious()
	}, [startDatePrevious, endDatePrevious, filters])

	//********** Insere les values dans teams quand on est responsableTeam pour afficher les sellers pour le filtres **********//
	useEffect(() => {
		if (teams && isResponsableTeam && !isAdmin) {
			setFilters({
				sector: null,
				team: {
					value: responsableTeam.id,
					label: responsableTeam.name,
				},
				seller: null,
			})
		}
	}, [responsableTeam])

	//********** Insere les values dans sellers quand on est seller pour les filtres **********//
	useEffect(() => {
		if ((responsableTeamUser !== undefined) && (isSeller || isTechnico_commercial) && !isAdmin && !isResponsableTeam) {
			setFilters({
				sector: null,
				team: null,
				seller: {value: user.id, label: user.name}
			})
		}
	}, [sellers, responsableTeamUser])

	useEffect(() => {
		if ((responsableTeamUser !== undefined) && (isTechnicien || isTechnico_commercial) && !isAdmin && !isResponsableTeam) {
			setFilters({
				sector: null,
				team: null,
				seller: {value: user.id, label: user.name}
			})
		}
	}, [sellers, responsableTeamUser])

	useEffect(() => {
		loadTeams()
		loadSectors()
		loadSellers()
		loadTechniciens()
		loadTechnicalTeams()
//		loadTrueTechniciens()
//		loadTrueSellers()
	}, [])

	//********** Filtre pour filtrer tout le dashboard **********//
	const filtre = (start) => {
		let startDate1 = startDate
		let endDate1 = endDate
		if (!start) {
			startDate1 = startDatePrevious
			endDate1 = endDatePrevious
		}
		const tab = []
		if (startDate1) {
			tab.push({id: 'startDate', value: moment(startDate1).format('YYYY-MM-DD')})
		}
		if (endDate1) {
			tab.push({id: 'endDate', value: moment(endDate1).add(1, 'd').format('YYYY-MM-DD')}) // add 1 day endDate non inclus : (01/01/2022 = 01/01/2022 00h01)
		}
		if (filters.seller) {
			tab.push({id: 'seller', value: (filters.seller.value)})
		}
		if (filters.sector) {
			tab.push({id: 'sector', value: (filters.sector.value)})
		}
		if (filters.team) {
			tab.push({id: 'team', value: (filters.team.value)})
		}
		return tab
	}


	const loadDashboard = async () => {
		setPending(true)
		const data = await contractService.dashboard({
			filtered: filtre(true)
		})
		setPending(false)
		setStatistiques(data)
	}

	const loadDashboardPrevious = async () => {
		setPending(true)
		const data = await contractService.dashboard({
			filtered: filtre(false)
		})
		setPending(false)
		setStatistiquesPrevious(data)
	}


	//********** Compare id user et vendeurs de toutes les vendeurs **********//
	const findTeamSector = () => {
		setSectorTeam(
			sellers.find((seller) => {
				return seller.id === user.id;
			})
		)
	}

	//********** Modifie les previous dates **********//
	useEffect(() => {
		if (isMounted.current) {
			const diff = moment(endDate).add(1, 'day').diff(startDate)
			const newStartDatePrevious = moment(startDate).subtract(diff, 'milliseconds').toDate()
			const newEndDatePrevious = moment(startDate).subtract(1, 'day').toDate()

			setStartDatePrevious(newStartDatePrevious)
			setEndDatePrevious(newEndDatePrevious)
		} else {
			isMounted.current = true;
		}
	}, [startDate, endDate])

	//********** lance le chargement initial des données de sectors **********//
	const loadSectors = async () => {
		const data = await sectorsService.search(
			{filtered: [{id: 'sectorType', value: 'commercial'},]}
		)
		setSectors(data['hydra:member'])
	}

	//********** lance le chargement initial des données de teams **********//
	const loadTeams = async () => {
		const data = await teamsService.search(
			{filtered: [{id: 'sector.sectorType', value: 'commercial'},]}
		)
		setTeams(data['hydra:member'])
	}

	const loadTechnicalTeams = async () => {
		const data = await teamsService.search(
			{filtered: [{id: 'sector.sectorType', value: 'technical'},]}
		)
		setTechnicalTeams(data['hydra:member'])
	}

	//********** lance le chargement initial des données de sellers **********//
	const loadSellers = async () => {
		const data = await usersService.search(
			{filtered: [{id: 'team.sector.sectorType', value: 'commercial'}, {id: 'pagination', value: false}]}
		)
		setSellers(data['hydra:member'])
	}

	const loadTechniciens = async () => {
		const data = await usersService.search(
			{filtered: [{id: 'team.sector.sectorType', value: 'technical'}, {id: 'pagination', value: false}]}
		)
		setTechniciens(data['hydra:member'])
	}

	//********** Permet d'afficher toutes les équipes  **********//
	const showTeams = () => {
		return teams ? teams.map(team => ({
			value: team.id,
			label: team.name,
		})) : []
	}

	//********** Permet de trouver et d'afficher les equipes par rapport aux secteurs **********//
	const showTeamsSectors = (value) => {
		if (value) {
			const sectorFind = sectors.find(sector => sector.id === value.value)
			return sectorFind ? sectorFind.teams.map(team => ({
				value: team.id,
				label: team.name,
			})) : []
		}
	}

	//********** Permet d'afficher tous les vendeurs **********//
	const showSellers = () => {
		const usersMasterTeams = teams && sellers ? teams.map(user => (user.userMaster)).concat(sellers) : []
		const usersMasterTeamsTechs = teams && techniciens ? usersMasterTeams.concat(techniciens) : []
		return usersMasterTeamsTechs ? usersMasterTeamsTechs.map(seller => ({
			value: seller.id,
			label: seller.name,
		})) : []
	}

	//********** Permet de trouver et d'afficher vendeurs et responsable par rapport aux equipes **********//
	const showSellersTeams = (value) => {
		if (value) {
			const teamFind = teams.find(team => team.id === value.value)
			const teamFindUserMaster = teamFind.users.concat(teamFind.userMaster).reverse()
			return teamFindUserMaster ? teamFindUserMaster.map(seller => ({
					value: seller.id,
					label: seller.name,
				}))
				: []
		}

	}

	const clearValueSector = () => {
		setFilters({sector: null, team: null, seller: null})
	}

	const clearValueTeam = () => {
		setFilters({sector: filters.sector, team: null, seller: null})
	}

	const clearValueSeller = () => {
		setFilters({sector: filters.sector, team: filters.team, seller: null})
	}

	useEffect(() => {
		if (filters.team !== null || filters.seller !== null) {
			setFilters({
				sector: filters.sector,
				team: null,
				seller: null
			})
		}
	}, [filters.sector])

	useEffect(() => {
		if (filters.seller !== null) {
			setFilters({
				sector: filters.sector,
				team: filters.team,
				seller: null
			})
		}
	}, [filters.team])

	return (
		<>
			<div className="animated fadeIn body-color">
				<div className="animated fadeIn graph-header">
					<Row>
						<Col xl={8} sm={8} xs={12}>
							<h1 className="titleDashboard">Dashboard</h1>
						</Col>

					</Row>

					<div className="main-graph-filters">
						{
							//********** select field sectors **********//
						}
						{((isResponsableTeam || isSeller || isTechnico_commercial) && !isAdmin)
							? <div className="group-filter-less">
								<div>Secteur Commercial</div>
								<div className="filters-less box-shadow">
									{responsableTeam
										? responsableTeam.sector.name
										: sectorTeam
											? sectorTeam.team.sector.name
											: []
									}
								</div>
							</div>
							: ((isTechnicien || isTechnico_commercial) && !isAdmin)
								? <div className="group-filter-less">
									<div>Secteur Techniques</div>
									<div className="filters-less box-shadow">
										{technicienTeam
											? technicienTeam.sector.name
											: []
										}
									</div>
								</div>

								: <div className="graph-filter">
									<label>Secteur Commercial</label>
									<Select
										name="Secteur Commercial"
										className="graph-select-field"
										isOptionDisabled={(option) => option.disabled}
										clearable={false}
										disabled={pending}
										value={filters.sector}
										options={sectors ? sectors.map((sector) => {
											return {value: sector.id, label: sector.name}
										}) : []}
										onChange={sector => {
											setFilters({
												...filters,
												sector
											})
										}}
									>
									</Select>
									<button disabled={pending} className="clear-value-sector button-clear-value"
											onClick={clearValueSector}>X
									</button>
								</div>
						}

						{
							//********** select field teams **********//
						}
						{((isResponsableTeam || isSeller || isTechnico_commercial) && !isAdmin)
							? <div className="group-filter-less">
								<div>Équipe Commercial</div>
								<div className="filters-less box-shadow">
									{responsableTeam
										? responsableTeam.name
										: sectorTeam
											? sectorTeam.team.name
											: []
									}
								</div>
							</div>
							: ((isTechnicien || isTechnico_commercial) && !isAdmin)
								? <div className="group-filter-less">
									<div>Équipe Techniques</div>
									<div className="filters-less box-shadow">
										{technicienTeam
											? technicienTeam.name
											: []
										}
									</div>
								</div>
								: <div className="graph-filter">
									<label>Équipe Commercial</label>
									<Select
										name="Équipe Commercial"
										className="graph-select-field"
										isOptionDisabled={(option) => option.disabled}
										disabled={pending}
										value={filters.team}
										options={filters.sector ? showTeamsSectors(filters.sector) : showTeams()}
										onChange={team => {
											setFilters({
												...filters,
												team
											})
										}}
									>
									</Select>
									<button disabled={pending} className="clear-value-team button-clear-value"
											onClick={clearValueTeam}>X
									</button>
								</div>

						}

						{
							//********** select field sellers **********//
						}

						{(isResponsableTeam || isAdmin)
							? <div className="graph-filter">
								<label>Commercial</label>
								<Select
									name="Commercial"
									className="graph-select-field"
									isOptionDisabled={(option) => option.disabled}
									disabled={pending}
									value={filters.seller}
									options={filters.team ? showSellersTeams(filters.team) : showSellers()}
									onChange={seller => {
										setFilters({
											...filters,
											seller
										})
									}}
								>
								</Select>
								<button disabled={pending} className="clear-value-seller button-clear-value"
										onClick={clearValueSeller}>X
								</button>

							</div>

							: <div className="group-filter-less">
								<div>{isTechnicien ? "Technicien"
									: isSeller ? "Commercial"
									: isTechnico_commercial ? "Technicien/Commercial"
									: []
								}</div>
								<div className="filters-less box-shadow">{user.name}</div>
							</div>
						}

					</div>
				</div>

				<div>
					{
						//********** Actual Dashboard **********//
					}
					<Graph
						startDate={startDate}
						endDate={endDate}
						periodeName={"Période Séléctionnée"}
						setStartDate={setStartDate}
						setEndDate={setEndDate}
						statistiques={statistiques}
					/>

					{
						//********** Previous Dashboard **********//
					}
					<Graph
						startDate={startDatePrevious}
						endDate={endDatePrevious}
						periodeName={"Période Précédente"}
						setStartDate={setStartDatePrevious}
						setEndDate={setEndDatePrevious}
						statistiques={statistiquesPrevious}
					/>

				</div>
			</div>
			{/*)}*/}
		</>
	);
}

export const mapStateToProps = state => ({
	roles: getUserRoles(state),
	user: getUser(state)
});
export default connect(mapStateToProps)(Dashboard);

