import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import WHTable from '../../../components/WHTable';
import CreateEditPopup from './Popup/CreateEditPopup';
import * as levelService from '../../../services/catalog/service';
import { getIsOnline } from '../../../components/OnlineStatus/redux';
import Swal from "sweetalert2";

class Services extends Component {
	state = {
		data: [],
		pages: null,
		loaded: true,
	};

	createEditPopup = createRef();

	columns = () => [
		{
			Header: '',
			accessor: 'id',
			filterable: false,
			Cell: row => (
				<div>
					<Button
						color="info"
						onClick={() => this.createEditPopup.current.handleOpen(row.value)}>
						<i className="fa fa-edit" />
					</Button>
					<Button color="danger" onClick={() => this.remove(row.value)}>
						<i className="fa fa-trash" />
					</Button>
				</div>
			),
			maxWidth: 90,
		},
		{
			Header: 'Nom',
			accessor: 'name',
		},
	];

	load = state => {
		if (this.props.isOnline) {
			this.setState({
				loaded: false,
			});

			return levelService.search(state).then(data => {
				this.setState(
					{
						data: [],
					},
					() => {
						this.setState({
							data: data['hydra:member'],
							pages: Math.ceil(data['hydra:totalItems'] / state.pageSize),
							loaded: true,
						});
					}
				);
			});
		}
	};

	remove = toDelete => {

		Swal.fire({
			title: "Supprimer",
			text: "Êtes-vous sûr de vouloir supprimer ce niveau de prestations ?",
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#3085d6',
			denyButtonColor: '#d33',
			confirmButtonText: 'Supprimer',
			denyButtonText: 'Annuler',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					"Le niveau de prestations a bien été supprimé",
					'',
					'success'
				)

				this.setState({
					loaded: false,
				});

				levelService
					.remove(toDelete)
					.then(() => {
						const lst = this.state.data;

						const index = lst.findIndex(el => el.id === toDelete);

						if (index > -1) {
							lst.splice(index, 1);

							this.setState({
								data: lst,
								loaded: true,
							});
						}
					})

			} else if (result.isDenied) {
				Swal.fire(
					"Le niveau de prestations n'a pas été supprimé",
					'',
					'error'
				)
			}
		})

		/*if (window.confirm('Êtes-vous sûr de vouloir supprimer ce niveau de prestations ?')) {
			this.setState({
				loaded: false,
			});

			levelService
				.remove(toDelete)
				.then(() => {
					const lst = this.state.data;

					const index = lst.findIndex(el => el.id === toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({
							data: lst,
							loaded: true,
						});
					}
				})
				.catch(() => {
					this.setState({ loaded: true });
					alert(
						'Il existe des dépendances sur ce niveau de prestation, il ne peut donc être supprimé'
					);
				});
		}*/
	};

	newData = data => {
		const lst = this.state.data;

		const index = lst.findIndex(el => el.id === data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({
			data: lst,
		});
	};

	render() {
		const { data, pages } = this.state;

		return (
			<div className="animated fadeIn">
				<CreateEditPopup ref={this.createEditPopup} newData={this.newData} />

				<Card className="border-card">
					<CardHeader className="card-header-contract">
						<h2>Gestion des niveaux de prestations</h2>
						<div className="card-actions">
							<button
								onClick={() => this.createEditPopup.current.handleOpen()}
								className="button-create button-text">
								<i className="fa fa-plus fa-lg icone-button-text" />
								&nbsp;Nouveau
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	}
}

Services.defaultProps = {
	isOnline: false,
};

Services.propTypes = {
	isOnline: PropTypes.bool,
};

export const mapStateToProps = state => ({
	isOnline: getIsOnline(state),
});

export default connect(mapStateToProps)(Services);
