import React from 'react';
import PropTypes from 'prop-types';
import { getSignatureStyle } from '../../utils';

const Footer = ({ place, signature }) => {
    const newDate = 'kigre';
    return (
        <table cellSpacing="0" cellPadding="0">
            <tbody>
                <tr>
                    <td width="300px" className="bg-color">
                        Type de paiement*Paiementrécurrent / répétitif
                    </td>
                    <td colSpan="2" width="400px" className="text-center">
                        [X]Paiement récurrent/Répétitif
                    </td>
                    <td className="text-center">[ ] Paiement ponctuel</td>
                </tr>
                <tr>
                    <td className="bg-color" />
                    <td width="100px" className="bg-color">
                        Date et lieu
                    </td>
                    <td>Le {newDate}</td>
                    <td>À {place}</td>
                </tr>
                <tr>
                    <td colSpan="4" className="bg-color">
                        Signature(s)
                        <div className="signature-block" style={getSignatureStyle(signature)} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="4" className="info">
                        Note : Vos droits concernant le présent mandat sont expliqués dans un
                        document que vous pouvez obtenir auprès de votre banque.
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

Footer.defaultProps = {
    signature: null,
    place: '',
};
Footer.propTypes = {
    place: PropTypes.string,
    signature: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Footer;
