import React from 'react';
import PropTypes from 'prop-types';

const Materials = ({ list }) => (
    <div className="card">
        <div className="card-header orange">
            APPAREIL
            {list.length > 1 && 'S'} A ENTRETENIR
        </div>
        <div className="card-body orange">
            <div>
                {list.map(information => (
                    <table className="table" key={information}>
                        <tbody>
                            <tr>
                                <th>Matériel&nbsp;:</th>
                                <td>{information.type.family.name.toUpperCase()}</td>
                                <th>Type&nbsp;:</th>
                                <td> {information.type.name}</td>
                            </tr>
                            <tr>
                                <th>N° de série&nbsp;:</th>
                                <td width="250px">{information.serialnumber}</td>
                                <th>Année de pose&nbsp;:</th>
                                <td>{information.year}</td>
                            </tr>
                            <tr>
                                <th>Marque&nbsp;:</th>
                                <td>{information.brand}</td>
                                <th>Puissance&nbsp;:</th>
                                <td>{information.power}</td>
                            </tr>
                        </tbody>
                    </table>
                ))}
            </div>
        </div>
    </div>
);

Materials.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Materials;
