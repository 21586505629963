import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createHistory from 'history/createHashHistory';
import { routerMiddleware } from 'react-router-redux';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './root.reducer';
import { CONTRACTS_RK } from '../modules/Contract/redux';
import {ONLINE_RK} from "../components/OnlineStatus/redux";

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: [ONLINE_RK, 'Form.js', CONTRACTS_RK, 'form'],
};

export const history = createHistory();
const middlewares = [routerMiddleware(history), thunk];

const store = createStore(
    persistReducer(persistConfig, rootReducer),
    composeWithDevTools(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);

export default store;
