import React, {Component} from 'react';
import {Container, Row, Col, CardGroup, Card, CardBody} from 'reactstrap';
import {SubmissionError} from 'redux-form';
import {login} from '../../utils/session';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {redirectToDashboard, redirectToContract} from '../../utils/redirectTo';
import LoginForm from './Form/';
import {getIsOnline} from '../../components/OnlineStatus/redux';
import Loading from '../../components/Loading';
import {getUser, getUserRoles, setLogin} from './redux';
import {destroy} from "../../utils/storage";
import * as usersService from "../../services/member";
import {persistor} from "../../redux/store";


class Login extends Component {


	constructor(props) {

		super(props);

		this.state = {
			username: null,
			roles: null,
		}
	}

	loadSellers = async () => {
		const data = await usersService.search(
			{filtered: [{id: 'username', value: this.state.username}, {id: 'pagination', value: false}]}
		)
		this.setState({roles: data['hydra:member'][0].roles})
	}

	submit = async ({password, username}) => {
		try {
			const result = await login(username, password);
			this.props.onSetLogin({username, password});
			this.setState({username})
			this.loadSellers()

		} catch (error) {
			throw new SubmissionError({_error: error});

		}
	};

	componentDidMount() {
		persistor.flush();
		destroy('persist:root');

	}


	componentDidUpdate(prevProps, prevState, snapshot) {

		if (this.state.roles !== null) {
		 	if (this.state.roles.includes('ROLE_ADMIN')) {
				redirectToDashboard()
			} else if (this.state.roles.includes('ROLE_SELLER') || this.state.roles.includes('ROLE_TECHNICIEN_SAV') || this.state.roles.includes('ROLE_TECHNICO_COMMERCIAL')) {
				redirectToContract()
			} else {
				redirectToDashboard()
			}
		}
		destroy('persist:root');
	}

	render() {
		if (this.props.isOnline === null) {
			return <Loading/>;
		}
		destroy('persist:root');
		return (
			<div className="app flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="8">
							<CardGroup className="mb-4">
								<Card className="p-4">
									<CardBody>
										<h1>Login</h1>

										<p className="text-muted">Merci de vous identifier</p>

										<LoginForm onSubmit={this.submit}/>
									</CardBody>
								</Card>

								<Card className="py-5 d-md-down-none" style={{width: `${44}%`}}>
									<CardBody className="text-center">
										<img
											src="img/logo.png"
											style={{maxWidth: '100%'}}
											alt=""
										/>
									</CardBody>
								</Card>
							</CardGroup>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

Login.propTypes = {
	isOnline: PropTypes.bool.isRequired,
	onSetLogin: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
	isOnline: getIsOnline(state),
	roles: getUserRoles(state),
	user: getUser(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			onSetLogin: setLogin,
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login);
