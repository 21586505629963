const FileSaver = require("file-saver")
const moment = require("moment")

export const generateCsv = async (result, columns, fileName) => {
  const columnsToPrint = columns.filter(col => !col.notInCsv)

  // Let's build the headers
  const headerRow = columnsToPrint
    .map(column => `${column.Header}`)
    .join(';')

  const rows = result.map(data => columnsToPrint
      .map(column => {
        const { accessor, renderInCsv, isDate } = column

        const value = accessor ? data[accessor] : undefined

        const cellContent = renderInCsv ? 
          renderInCsv(value, data)
        :
          isDate ?
            moment(value).format('YYYY-MM-DD')
          : value || typeof (value) == "number" ?
              value
            : 
              ''

        return `${cellContent === "" ? " " : cellContent}`
      })
      .join(';')
  )

  const csvContent = [headerRow, ...rows].join(`\n`)
  const contentType = "data:text/csv;charset=utf-8";
  const csvBlob = new Blob(["\uFEFF" + csvContent], { type: contentType })

  return FileSaver.saveAs(csvBlob, `${fileName}.csv`)
}

export const testAAAAAAAA = () => {}