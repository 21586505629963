import {
	CONTRACTS_TO_SYNC_RK,
	CONTRACTS_RK,
	SYNCED_CONTRACTS_RK,
	STATUS_CONTRACTS_RK,
	CURRENT_CONTRACT_RK,
	STATUS_FILTER_RK,
	ARCHIVED_CONTRACTS_RK,
} from './constants';
import { PAGES, DATA } from '../../Catalog/redux/constants';
import { PAGE_SIZE } from 'redux-friends';

export const getAllContractsToSyncList = state => state[CONTRACTS_RK][CONTRACTS_TO_SYNC_RK] || [];

export const getOneContractToSync = (state, tmpID) =>
	getAllContractsToSyncList(state).find(x => x.tmpID === tmpID);

export const getAllContractsList = state => state[CONTRACTS_RK][SYNCED_CONTRACTS_RK][DATA];
export const getCurrentContract = state => state[CONTRACTS_RK][CURRENT_CONTRACT_RK];
export const getStatusFilter = state => state[CONTRACTS_RK][STATUS_FILTER_RK];

export const getOneContract = (state, id) =>
	state[CONTRACTS_RK][SYNCED_CONTRACTS_RK][DATA].find(x => x.id === Number(id));

export const getContractsStatus = state => state[CONTRACTS_RK][STATUS_CONTRACTS_RK];

export const getPagesContracts = state => state[CONTRACTS_RK][SYNCED_CONTRACTS_RK][PAGES];
export const getPageSizeContracts = state => state[CONTRACTS_RK][SYNCED_CONTRACTS_RK][PAGE_SIZE];
export const getContractsList = state => state[CONTRACTS_RK][SYNCED_CONTRACTS_RK][DATA];
export const getTotalSyncedContract = state => state [CONTRACTS_RK][SYNCED_CONTRACTS_RK];
export const getTotalArchivedContract = state => state [CONTRACTS_RK][ARCHIVED_CONTRACTS_RK];
//export const getTotalContractToSynced = state => state [CONTRACTS_RK][CONTRACTS_TO_SYNC_RK];
export const getArchivedPagesContracts = state => state[CONTRACTS_RK][ARCHIVED_CONTRACTS_RK][PAGES];
export const getPageSizeArchivedContracts = state =>
	state[CONTRACTS_RK][SYNCED_CONTRACTS_RK][PAGE_SIZE];
export const getArchivedContractsList = state => state[CONTRACTS_RK][ARCHIVED_CONTRACTS_RK][DATA];
