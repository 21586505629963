import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { FieldSelect, FieldTxt } from '../../../components/Form/wh-field';
import { ModalBody, ModalFooter, Form } from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import * as validator from '../../../components/Form/wh-validator';

class CustomerForm extends Component {
    constructor(props) {
        super(props);

        this.state = { open: false };
    }

    render() {
        const { error, handleSubmit, submitting } = this.props;

        return (
            <div>
                <Form onSubmit={handleSubmit} className="form-horizontal">
                    <ModalBody>
                        <Field
                            label="Civilité :"
                            name="civility"
                            validate={[validator.required]}
                            component={FieldSelect}
                            options={[
                                { value: '', name: 'Choisir une civilité' },
                                { value: 'Mme', name: 'Mme' },
                                { value: 'M.', name: 'M.' },
                            ]}
                        />

                        <Field
                            label="Prénom :"
                            validate={[validator.required]}
                            name="firstName"
                            component={FieldTxt}
                        />

                        <Field
                            label="Nom :"
                            validate={[validator.required]}
                            name="lastName"
                            component={FieldTxt}
                            type="text"
                        />

                        {/*<Field*/}
                        {/*    label="Raison sociale :"*/}
                        {/*    name="socialReason"*/}
                        {/*    component={FieldTxt}*/}
                        {/*    type="text"*/}
                        {/*/>*/}

                        <Field
                            label="Adresse :"
                            validate={[validator.required]}
                            name="address"
                            component={FieldTxt}
                        />

                        <Field
                            label="Code postal :"
                            validate={[validator.required]}
                            name="zipCode"
                            component={FieldTxt}
                        />

                        <Field
                            label="Ville :"
                            validate={[validator.required]}
                            name="city"
                            component={FieldTxt}
                        />

                        <Field
                            label="Pays :"
                            validate={[validator.required]}
                            name="country"
                            component={FieldTxt}
                        />

                        <Field label="Email :" name="email" component={FieldTxt} />

                        <Field label="Mobile :" name="mobile" component={FieldTxt} />

                        <Field label="Téléphone :" name="phone" component={FieldTxt} />
                    </ModalBody>

                    <ModalFooter>
                        <LaddaButton
                            className="btn btn-success btn-ladda"
                            loading={submitting}
                            data-style={EXPAND_LEFT}
                            type="submit">
                            <i className="fa fa-save"> </i>
                            &nbsp;Enregistrer
                        </LaddaButton>
                    </ModalFooter>
                </Form>
            </div>
        );
    }
}

CustomerForm = reduxForm({
    form: 'CustomerForm',
})(CustomerForm);

export default CustomerForm;
