import React, {Component, createRef, useEffect} from 'react';
import {Card, CardHeader, CardBody, Row, Col, Button} from 'reactstrap';
import PopupMaterial from './PopupMaterial';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import getRef from '../../../../utils/getRef';
import Swal from "sweetalert2";

class Materials extends Component {
	state = {
		materials: this.props.materials || [],
	};
	popupMaterial = createRef();


	newData = (data, index) => {
		let {materials} = this.state;
		if (index !== null) {
			materials[index] = data;
		} else {
			if (!materials) materials = [];
			materials.push(data);
		}

		this.props.amountCalculator();
		this.setState({materials});
		this.props.change('contractInformation', materials);
	};

	remove = index => {

		Swal.fire({
			title: "Supprimer",
			text: "Êtes-vous sûr de vouloir supprimer cet équipement ?",
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#3085d6',
			denyButtonColor: '#d33',
			confirmButtonText: 'Supprimer',
			denyButtonText: 'Annuler',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					"L'équipement a bien été supprimé",
					'',
					'success'
				)
				const {materials} = this.state;
				materials.splice(index, 1);
				this.setState({materials});
				this.props.amountCalculator();
				this.props.change('contractInformation', materials);

			} else if (result.isDenied) {
				Swal.fire(
					"L'équipement n'a pas été supprimé",
					'',
					'error'
				)
			}
		})

		/*if (window.confirm('Êtes-vous sûr de vouloir supprimer cet équipement ?')) {
			const {materials} = this.state;
			materials.splice(index, 1);
			this.setState({materials});
			this.props.amountCalculator();
			this.props.change('contractInformation', materials);
		}*/
	};

	render() {
		const {materials} = this.state;
		const {isArchivedContract, isArchived, userIsAdmin} = this.props;
		const isMultipleMaterials = materials.length >= 2
		const isPompeAA = materials.find(elem => elem.type.id === 7)
		const disabled = !!this.props.initialValues.contractSignature
		return (

			<div>
				<PopupMaterial
					ref={this.popupMaterial}
					newData={this.newData}
					isArchived={isArchived}
					isArchivedContract={isArchivedContract}
					userIsAdmin={userIsAdmin}
				/>
				<a>
				<CardHeader className={"title-card-header"}>
					<h5 className="title-card-form"> <i style={{marginRight: 8}} className="fa fa-cog"/> Materiels</h5>
				</CardHeader>

				{materials.map((mat, index) => (
					<Card style={{borderTop: 0}} key={index} >
						<CardHeader className='card-header-contract-material'>
							{mat.type && mat.type.family && mat.type.family.name}
							{!isArchived && disabled && (
								<div className="card-actions">
									<a
										href="javascript:void(0);"
										className="btn btn-success btn-block button-create button-text disabled">
										<i className="fa fa-edit fa-lg icone-button-text"/>
										<span className="d-none d-sm-inline">Modifier</span>
									</a>
									{isMultipleMaterials && (
										<a
											href="javascript:void(0);"
											className="btn btn-success btn-block button-remove button-text disabled">
											<i className="fa fa-trash fa-lg icone-button-text"/>
											<span className="d-none d-sm-inline">Supprimer</span>
										</a>
									)}
								</div>
							)}
							{!isArchived && !disabled && (
								<div className="card-actions">
									<a
										onClick={() =>
											getRef(this.popupMaterial, true).handleOpen(mat, index)
										}
										href="javascript:void(0);"
										className="btn btn-success btn-block button-create button-text">
										<i className="fa fa-edit fa-lg icone-button-text"/>
										<span className="d-none d-sm-inline">Modifier</span>
									</a>
									{isMultipleMaterials && (
										<a
											onClick={() => this.remove(index)}
											href="javascript:void(0);"
											className="btn btn-success btn-block button-remove button-text">
											<i className="fa fa-trash fa-lg icone-button-text"/>
											<span className="d-none d-sm-inline">Supprimer</span>
										</a>
									)}
								</div>
							)}
						</CardHeader>

						<CardBody>
							<div>
								<Row>
									<Col md={5}>
										<strong>Type : </strong>
									</Col>
									<Col md={7}>{mat.type && mat.type.name}</Col>
								</Row>
								{isPompeAA && (
									<div>
										{mat.nbrUnite &&
											<Row>
												<Col md={5}>
													<strong>Nombre d'unité ext : </strong>
												</Col>
												<Col md={7}>{mat.nbrUnite}</Col>
											</Row>
										}
										{mat.nbrUniteInt &&
											<Row>
												<Col md={5}>
													<strong>Nombre d'unité int : </strong>
												</Col>
												<Col md={7}>{mat.nbrUniteInt}</Col>
											</Row>}
									</div>
								)}
								<Row>
									<Col md={5}>
										<strong>Marque :</strong>
									</Col>
									<Col md={7}>{mat.brand}</Col>
								</Row>
								<Row>
									<Col md={5}>
										<strong>Puissance :</strong>
									</Col>
									<Col md={7}>{mat.power}</Col>
								</Row>
								<Row>
									<Col md={5}>
										<strong>Année :</strong>
									</Col>
									<Col md={7}>{mat.year}</Col>
								</Row>
								<Row>
									<Col md={5}>
										<strong>Installation : </strong>
									</Col>
									<Col md={7}>
										<Moment format="DD/MM/YYYY">{mat.date}</Moment>
									</Col>
								</Row>
							</div>
						</CardBody>
					</Card>
				))}
				</a>
				{!isArchived && !disabled && (
					<a
						style={{marginTop: 7}}
						onClick={() => getRef(this.popupMaterial, true).handleOpen()}
						href="javascript:void(0);"
						className="btn btn-success btn-block button-border">
						<i className="fa fa-plus"/>{' '}
						<span className="d-none d-sm-inline">Ajouter</span>
					</a>
				)}
				{!isArchived && disabled && (
					<a
						style={{marginTop: 7}}
						href="javascript:void(0);"
						className="btn btn-success btn-block disabled button-border">
						<i className="fa fa-plus"/>{' '}
						<span className="d-none d-sm-inline">Ajouter</span>
					</a>
				)}

				<Button className="button-border" size="lg" block color="primary" onClick={this.props.next}>
					Suivant
				</Button>
			</div>
		);
	}
}

Materials.defaultProps = {
	materials: [],
};
Materials.propTypes = {
	next: PropTypes.func.isRequired,
	amountCalculator: PropTypes.func.isRequired,
	change: PropTypes.func.isRequired,
	materials: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
	isArchived: PropTypes.bool.isRequired,
};

export default Materials;
