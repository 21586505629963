import {createReducer} from 'redux-friends';
import moment from 'moment';

import {
	ADD_CONTRACT_TO_SYNC,
	CONTRACT_SIGNATURE,
	ContractsTypes,
	EDIT_CONTRACT_TO_SYNC,
	REMOVE_CONTRACT_FROM_SYNC,
	SET_SIGNATURE,
} from './constants';
import {TMP_ID} from '../constants';

const defaultState = [];

const behaviours = {
	[ContractsTypes[ADD_CONTRACT_TO_SYNC]]: (state, { payload }) => [...state, payload],
	[ContractsTypes[REMOVE_CONTRACT_FROM_SYNC]]: (state, { payload }) =>
		state.filter(x => x[TMP_ID] !== payload),
	[ContractsTypes[EDIT_CONTRACT_TO_SYNC]]: (state, { payload }) =>
		state.map(x => (x[TMP_ID] !== payload[TMP_ID] ? x : payload)),
	[ContractsTypes[SET_SIGNATURE]]: (state, { payload: { id, value, key } }) =>
		state.map(x =>
			x[TMP_ID] !== id
				? x
				: {
						...x,
						[key]: value,
						...(key === CONTRACT_SIGNATURE
							? { signatureDate: moment().format('YYYY-MM-DD HH:mm:ss') }
							: {}),
				  }
		),
};

export default createReducer(behaviours, defaultState);
