import {SEPA, CONTRACT, CONTRACT_SELLER, TVA} from '../../constants';

import {
	SEPA_SIGNATURE,
	CONTRACT_SIGNATURE,
	CONTRACT_SELLER_SIGNATURE,
} from '../Contract/redux/constants';

const mapper = {
    [SEPA]: SEPA_SIGNATURE,
    [CONTRACT]: CONTRACT_SIGNATURE,
    [CONTRACT_SELLER]: CONTRACT_SELLER_SIGNATURE,
};

const titleMapper = {
    [SEPA]: 'mandat',
    [CONTRACT]: 'contrat ( client )',
    [CONTRACT_SELLER]: 'contrat ( vendeur )',
	[TVA]: 'attestation TVA',
};

export const getKeyFromType = type => mapper[type];
export const getTitleFromType = type => `Signature de l'${titleMapper[type]}`;

export default null;
