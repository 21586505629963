import React, { Component, createRef } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';

export default class PopupPicture extends Component {
    state = {
        open: false,
    };
    webcam = createRef();

    handleOpen = () => this.setState({ open: true });

    handleClose = () => this.setState({ open: false });

    capture = () => {
        this.props.change(this.props.fieldName, this.webcam.current.getScreenshot());
        this.handleClose();

        return false;
    };

    render() {
        const { className, title } = this.props;
        const { open } = this.state;

        return (
            <Modal size="md" isOpen={open} className={className} fade={false}>
                <ModalHeader toggle={this.handleClose}>{title}</ModalHeader>

                <Webcam
                    audio={false}
                    height={350}
                    ref={this.webcam}
                    screenshotFormat="image/jpeg"
                    width={500}
                    videoConstraints={{
                        facingMode: 'environment',
                    }}
                />

                <a href="javascript:void(0)" onClick={this.capture} className="btn btn-primay">
                    Prendre la photo
                </a>
            </Modal>
        );
    }
}

PopupPicture.defaultProps = {
    className: '',
};

PopupPicture.propTypes = {
    change: PropTypes.func.isRequired,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
};
