import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {FieldTxt, FieldSelect, FieldCheckbox} from '../../../../components/Form/wh-field';
import {ModalBody, ModalFooter, Alert, Form} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';
import * as familyService from '../../../../services/catalog/family';

class UserForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			attributeOptionsLoaded: false,
			attributeOptions: [],
		};
	}

	componentDidMount() {
		this.loadFamily();
	}

	loadFamily() {
		return familyService.search().then(data => {
			const attributeOptions = [];

			attributeOptions.push({value: '', name: ''});
			data['hydra:member'].map(el => {
				attributeOptions.push({value: el['@id'], name: `${el.name}`});
			});

			this.setState({
				attributeOptionsLoaded: true,
				attributeOptions,
			});
		});
	}

	render() {
		const {error, handleSubmit, submitting} = this.props;

		return (
			<div>
				{this.state.attributeOptionsLoaded && (
					<Form onSubmit={handleSubmit} className="form-horizontal">
						<ModalBody>
							<Field label="Nom :" name="name" component={FieldTxt} type="text"/>

							<Field
								label="Famille :"
								name="family"
								component={FieldSelect}
								options={this.state.attributeOptions}
							/>

							<Field
								label="Activé"
								name="enabled"
								type="checkbox"
								component={FieldCheckbox}
							/>

							{error && (
								<Alert color="danger">
									<div dangerouslySetInnerHTML={{__html: error}}/>
								</Alert>
							)}
						</ModalBody>

						<ModalFooter>
							<LaddaButton
								className="btn btn-success btn-ladda"
								loading={submitting}
								data-style={EXPAND_LEFT}
								type="submit"
							>
								<i className="fa fa-save"/>
								&nbsp;Enregistrer
							</LaddaButton>
						</ModalFooter>
					</Form>
				)}
			</div>
		);
	}
}

const formName = 'UserForm';

export default reduxForm({form: formName})(UserForm);
