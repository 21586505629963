import React, {Component, useEffect, useState} from 'react';
import {Form} from 'reactstrap';
import {Field, reduxForm} from 'redux-form'
import {FieldTxt, FieldCheckbox, FieldSelect2, FieldSelect} from '../../components/Form/wh-field';
import {ModalBody, ModalFooter, Alert} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';
import * as AppointmentService from "../../services/appointment"

class EventAppointmentForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			appointments: []
		};
	}

	 appointmentSelect = async () => {
		let appointment = await AppointmentService.search({filtered: [{id: "contract", value: this.props.contractId}]})
		appointment = appointment['hydra:member']
		const appointments = appointment ? appointment.map(app => ({
			value: app['@id'],
			name: app.maintenanceIndex
		})) : []
		this.setState({appointments: appointments})
	}

	componentDidMount(){
		this.appointmentSelect();
	}
	render() {

		const {error, handleSubmit, submitting, initialValues} = this.props;



		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<div className="form-horizontal">
					<ModalBody>
						{this.state.appointments &&
							<div>
								<Field
									label="entretien concerné :"
									name="appointment"
									nullValue={"aucun" }
									component={FieldSelect}
									options={this.state.appointments}
								/>
								<Field
									label="Description :"
									name="description"
									component={FieldTxt}
									type="text"
								/>
							</div>
						}
					</ModalBody>

					<ModalFooter>
						<LaddaButton
							className="btn btn-success btn-ladda"
							data-style={EXPAND_LEFT}

						>
							<i className="fa fa-save"></i>
							&nbsp;Enregistrer
						</LaddaButton>

					</ModalFooter>
				</div>
			</Form>
		);
	}
}

EventAppointmentForm = reduxForm({
	form: 'EventAppointmentForm'
})(EventAppointmentForm);


export default EventAppointmentForm
