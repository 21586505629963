import { createTypes } from 'redux-friends';

export const SET_CONTRACTS = 'SET_CONTRACTS';
export const SET_SIGNATURE = 'SET_SIGNATURE';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const SET_CURRENT_CONTRACT = 'SET_CURRENT_CONTRACT';
export const SET_CONTRACTS_STATUS = 'SET_CONTRACTS_STATUS';
export const ADD_CONTRACT_TO_SYNC = 'ADD_CONTRACT_TO_SYNC';
export const EDIT_CONTRACT_TO_SYNC = 'EDIT_CONTRACT_TO_SYNC';
export const SET_ARCHIVED_CONTRACTS = 'SET_ARCHIVED_CONTRACTS';
export const REMOVE_CONTRACT_FROM_SYNC = 'REMOVE_CONTRACT_FROM_SYNC';
export const SET_SYNCED_CONTRACTS_PAGES = 'SET_SYNCED_CONTRACTS_PAGES';
export const SET_ARCHIVED_CONTRACTS_PAGES = 'SET_ARCHIVED_CONTRACTS_PAGES';
export const SET_SYNCED_CONTRACTS_AND_PAGES = 'SET_SYNCED_CONTRACTS_AND_PAGES';
export const SET_ARCHIVED_CONTRACTS_AND_PAGES = 'SET_ARCHIVED_CONTRACTS_AND_PAGES';

export const typesArray = [
	ADD_CONTRACT_TO_SYNC,
	SET_CONTRACTS,
	SET_ARCHIVED_CONTRACTS,
	REMOVE_CONTRACT_FROM_SYNC,
	SET_CONTRACTS_STATUS,
	EDIT_CONTRACT_TO_SYNC,
	SET_SIGNATURE,
	SET_CURRENT_CONTRACT,
	SET_STATUS_FILTER,
	SET_SYNCED_CONTRACTS_PAGES,
	SET_ARCHIVED_CONTRACTS_PAGES,
	SET_SYNCED_CONTRACTS_AND_PAGES,
	SET_ARCHIVED_CONTRACTS_AND_PAGES,
];

export const CONTRACTS_RK = 'contracts';
export const SYNCED_CONTRACTS_RK = 'synced';
export const STATUS_CONTRACTS_RK = 'status';
export const CONTRACTS_TO_SYNC_RK = 'toSync';
export const SEPA_SIGNATURE = 'sepaSignature';
export const STATUS_FILTER_RK = 'statusFilter';
export const ARCHIVED_CONTRACTS_RK = 'archived';
export const CURRENT_CONTRACT_RK = 'currentContract';
export const CONTRACT_SIGNATURE = 'contractSignature';
export const CONTRACT_SELLER_SIGNATURE = 'contractSellerSignature';

export const ContractsTypes = createTypes(CONTRACTS_RK, typesArray);
