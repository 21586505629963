import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContractsList from '../';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	getContractsList,
	removeContract,
	fetchOnGoingContracts,
	getPagesContracts,
	getPageSizeContracts,
} from '../../redux';
import { CONTRACTS } from '../../../../constants/paths';
import {getTotalSyncedContract} from "../../redux/selectors";

class CompletedContracts extends Component {
	state = { loaded: false };

	onSetState = arg => this.setState(arg);

	render() {
		const { onSetState } = this;
		const { data, onLoad, onRemove, pages, totalSyncedContract } = this.props;
		const { loaded } = this.state;
		return (
			<ContractsList
				onLoad={onLoad}
				onRemove={onRemove}
				data={data}
				pages={pages}
				totalSyncedContract={totalSyncedContract}
				loaded={loaded}
				setParentState={onSetState}
				manual
				isSyncedContracts
				contractType={CONTRACTS}
			/>
		);
	}
}

CompletedContracts.defaultProps = { data: [] };

CompletedContracts.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({})),
	onLoad: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	pages: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalSyncedContract: PropTypes.number.isRequired,
};

export const mapStateToProps = state => ({
	data: getContractsList(state),
	pages: getPagesContracts(state),
	totalSyncedContract: getTotalSyncedContract(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			onLoad: fetchOnGoingContracts,
			onRemove: removeContract,
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CompletedContracts);
