import { SET_CONTRACTS_STATUS, ContractsTypes } from './constants';

const ContractStatusReducer = (state = [], { payload, type }) => {
	switch (type) {
		case ContractsTypes[SET_CONTRACTS_STATUS]:
			return [...payload];

		default:
			return state;
	}
};

export default ContractStatusReducer;
