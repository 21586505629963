import UserTypes from './types';

import { SET_USER } from './constants';
import { createReducer } from 'redux-friends';

const defaultState = {
    id: null,
    name: null,
    email: null,
    roles: null,
};

const behaviors = {
    [UserTypes[SET_USER]]: (state, { payload }) => ({
        ...state,
        id: payload.id,
		idApi: payload['@id'],
        name: payload.name,
        lastname: payload.lastname,
        firstname: payload.firstname,
        username: payload.username,
        email: payload.email,
        mobile: payload.mobile,
        roles: payload.roles,
    }),
};

export default createReducer(behaviors, defaultState);
