import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import {Button} from 'reactstrap';
import {getSignatureTitle} from './redux';
import { getTitleFromType} from './utils';
import './index.scss';
import {CGV, TVA} from "../../constants";

export class Signature extends Component {
	type = this.props.type;

	clear = () => {
		this.props.sigPadContract.current.clear();
		this.props.changeDisabled(true)
	};

	setEdited = () => this.props.changeDisabled(false);

	render() {
		let title
		if (this.type === TVA){
			 title = getTitleFromType(this.type);
		}if (this.type === CGV){
			 title = "Signature des CGV"
		}

		return (
			<div className="signature">
				<div className="title">{title}</div>
				<SignatureCanvas
					canvasProps={{className: 'sigPad'}}
					ref={this.props.sigPadContract}
					onBegin={this.setEdited}
				/>
				<div className="buttons">
					<Button
						color="info"
						size="lg"
						onClick={this.clear}>
						<i className="fa fa-eraser"> </i> Effacer
					</Button>
				</div>
			</div>
		);
	}
}

Signature.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};

export const mapStateToProps = state => ({title: getSignatureTitle(state)});

export default connect(
	mapStateToProps,
)(Signature);
