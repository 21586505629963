import { combineReducers } from 'redux';

import { USER_RK, LOGIN_RK } from './constants';
import UserReducer from './user.reducer';
import LoginReducer from './login.reducer';

const reducers = {
    [USER_RK]: UserReducer,
    [LOGIN_RK]: LoginReducer,
};

export default combineReducers(reducers);
