import React, {Component} from 'react';
import {Button, Col, Modal, ModalHeader} from 'reactstrap';


import * as memberService from '../../../services/member';
import Contracts from './Contracts'


class ContractsPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			data: [],
			member: null,
			loaded: true
		};

	}

	handleOpen = (id = null) => {
		this.setState({
			open: true
		});

		if (id) {
			this.setState({
				loaded: false
			});

			memberService.view(id).then((data) => {
				this.setState({
					member: data,
					loaded: true
				});
			})


		}


	}


	handleClose = () => {
		this.setState({
			open: false,
			data: []
		});
	}

	render() {
		const {className} = this.props;
		const {loaded, data, member} = this.state;

		return (

			<Modal isOpen={this.state.open} className={className} fade={false} size="lg">
				<ModalHeader toggle={() => this.handleClose()}>
					<h2>Contracts</h2>
				</ModalHeader>

				{loaded && (

					<Contracts member={member}/>
				)
				}
			</Modal>

		);

	}
}

export default ContractsPopup;
