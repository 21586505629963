import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getIsOnline, setOnlineMode } from './redux';
import Network from 'react-network';

const OnlineStatus = ({ onSetOnlineMode, isOnline }) => (
    <Network
        onChange={({ online }) => {
            if (online !== isOnline) {
                onSetOnlineMode(online);
            }
        }}
        render={() => null}
    />
);

OnlineStatus.propTypes = {
    isOnline: PropTypes.bool.isRequired,
    onSetOnlineMode: PropTypes.func.isRequired,
};

export const mapStatateToProps = state => ({ isOnline: getIsOnline(state) });

export const mapDispatchToProps = dispatch =>
    bindActionCreators({ onSetOnlineMode: setOnlineMode }, dispatch);

export default connect(
    mapStatateToProps,
    mapDispatchToProps
)(OnlineStatus);
