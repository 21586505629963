import React, {Component} from "react";
import {Button, Card, CardHeader} from "reactstrap";
import WHTable from "@whatsonweb/table";
import "../../../styles";
import 'moment/locale/fr';
import {redirectTo} from "../../../utils/redirectTo";
import {CONTRACTS} from "../../../constants/paths";
import moment from "moment";
import Select from "react-select";
import * as contractService from "../../../services/contract";
import * as teamService from "../../../services/team";
import * as sectorService from "../../../services/sectors";
import {AUTH_RK, getUser, getUserRoles} from "../../Authentication/redux";
import {getFamilyList, getFamilyPages} from "../../Catalog/Family/redux";
import {connect} from "react-redux";
import {ROLE_ADMIN, ROLE_TECHNICIEN_SAV} from "../../../constants/roles";
import {portefeuilleClient} from "../../../services/contract";

class Portefeuille extends Component {
	constructor(props) {
		super(props);
	}

	state = {
		open: false,
		data: [],
		pages: null,
		loaded: true,
		table: null,
		dataTeams: [],
		dataSector: [],
		technicianSAV: this.props.role.indexOf(ROLE_ADMIN) === -1
	};

	componentDidMount() {
		this.loadSector()
		this.loadEquipe()
	}

	columns = () => {
		return [
			{
				Header: "Nom",
				accessor: "customer.lastName",

			},
			{
				Header: "Prénom",
				accessor: "customer.firstName",

			},
			{
				Header: "Téléphone",
				accessor: "customer.mobile",

			},
			{
				Header: "Adresse",
				accessor: "customer.address",
			},
			// {
			// 	Header: "Secteur",
			// 	accessor: "sector.name",
			//
			// },
			// {
			// 	Header: "Sous Secteur",
			// 	accessor: "teamTechnique.name",
			// },
			// {
			// 	Header: "Produits",
			// 	accessor: "contractInformation",
			// 	Cell: row => (
			// 		<div className="text-center">
			// 			{row.value.map((option) => (
			// 				<p key={option.type.name}>{option.type.name}</p>
			// 			))}
			// 		</div>
			// 	),
			// },
			{
				Header: "Contrat",
				accessor: "id",
				Cell: row => (
					<div className="text-center">
						<Button
							className="bg-dark  text-white rounded"
							onClick={() =>
								redirectTo(CONTRACTS, row.value)
							}
						>Voir Contrat
						</Button>
					</div>
				),
			}
		]
	};
	loadEquipe = () => {
		return teamService.search({filtered: [{id: "sector.sectorType", value: "technical"}, {id: 'pagination', value: false} ]}).then(data => {
			this.setState(
				{
					dataTeams: []
				},
				() => {
					this.setState({
						dataTeams: data["hydra:member"],
					});
				}
			);
		});
	}
	loadSector = () => {
		return sectorService.search({filtered: [{id: "sectorType", value: "technical"}]}).then(data => {
			this.setState(
				{
					dataSector: []
				},
				() => {
					this.setState({
						dataSector: data["hydra:member"],
					});
				}
			);
		});
	}
	getTechniciensOptions = () => {

		const reduceTeamsToUser = (result, team) => {
			const users = team.users.map((user) => {
				return {value: user['@id'], label: user.name}
			})

			return [...result, ...users]
		}

		return this.state.dataTeams.reduce(reduceTeamsToUser, [])

	}

	load = (state) => {
		const customFilters = state.customFilters ? state.customFilters : this.getCustomFiltersWithState(this.state)
		this.setState({
			loaded: false,
			table: state
		});
		return contractService.search({
			...state,
			filtered: [{id: "sector[exist]", value: true}, ...customFilters]
		}).then(data => {
			this.setState(
				{
					data: []
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize
						),
						loaded: true,
					});
				}
			);
		});
	};
	onFilterChange = (stateKey, value) => {
		const newState = {
			...this.state,
			[stateKey]: value,
		}
		this.setState(newState)

		this.filterReload(newState)
	}

	filterReload = (newState) => {
		const {tableState} = newState

		const customFilters = this.getCustomFiltersWithState(newState)
		const newTableState = {
			...tableState,
			customFilters,
			page: 0,
		}
		this.load(newTableState)
	}

	getCustomFiltersWithState(newState) {
		const state = newState ? newState : this.state
		const customFilters = []

		const {sector, teams, technician} = state

		if (sector) {
			customFilters.push({id: 'sector', value: sector.value})
		}

		if (teams) {
			customFilters.push({id: 'teamTechnique', value: teams.value})
		}
		if (technician) {
			customFilters.push({id: 'technician', value: technician.value})
		}

		return customFilters
	}


	render() {
		const {
			manual,
			user,
			role
		} = this.props;
		const {
			data,
			pages,
			loaded,
			dataTeams,
			dataSector,
			sector,
			technician,
			teams,
			technicianSAV
		} = this.state;
		const {columns, load} = this;

		return (
			<div>
				<div className="animated fadeIn">
					<Card className="border-card">
						<CardHeader className="card-header-contract">
							<h2>Mon Portefeuille Clients</h2>
						</CardHeader>
						{!technicianSAV &&
						<div className="topFiltre">
							<div className="filter-wallet ">
								<label>Secteurs Techniques</label>
								<Select
									value={sector}
									options={
										dataSector ? dataSector.map((sector) => {
											return {value: sector.id, label: sector.name}
										}) : []
									}
									onChange={value => {
										this.onFilterChange("sector", value)
									}}>
								</Select>
							</div>

							<div className="filter-wallet">
								<label>Équipes Techniques</label>
								<Select
									value={teams}
									options={dataTeams ? dataTeams.map((team) => {
										return {value: team.id, label: team.name}
									}) : []}
									onChange={value => {
										this.onFilterChange("teams", value)
									}}
								>
								</Select>
							</div>
							<div className="filter-wallet">
								<label>Techniciens</label>
								<Select
									value={technician}
									options={this.getTechniciensOptions()}
									onChange={value => {
										this.onFilterChange("technician", value)
									}}
								>
								</Select>
							</div>
						</div>
						}
						<WHTable
							data={data}
							pages={pages}
							columns={columns()}
							loading={!loaded}
							manual={manual}
							onFetchData={load}
						/>
					</Card>
				</div>

			</div>
		);
	}
}


export const mapStateToProps = state => ({
	role: getUserRoles(state),
	user: getUser(state),
});

export default connect(mapStateToProps)(Portefeuille);
