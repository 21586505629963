import React from 'react';
import PropTypes from 'prop-types';

const Comments = ({ note }) => (
    <div className="card">
        <div className="card-body orange">
            <table className="table table-comment">
                <tbody>
                    <tr>
                        <th>Commentaires&nbsp;:</th>
                    </tr>
                    <tr>
                        <td>{note}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
);

Comments.defaultProps = {
    note: null,
};

Comments.propTypes = {
    note: PropTypes.string,
};

export default Comments;
