import { toast } from 'react-toastify';

export const testSync = async (method, successMessage, errorMessage, methodParam) => {
	try {
		await (methodParam ? method(methodParam) : method());
		toast.success(successMessage);
	} catch (error) {
		console.warn(error);
		toast.error(errorMessage);
	}
};

export const loopOnTestSync = toBeSynced =>
	toBeSynced.map(({ method, successMessage, errorMessage, methodParam }) =>
		testSync(method, successMessage, errorMessage, methodParam)
	);
