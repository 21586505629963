import React from 'react';
import PropTypes from 'prop-types';

const ContractInformations = props => {
    const {
        contract: {
            levelService: { name },
            paymentType,
            monthly,
        },
    } = props;
    return (
        <div className="card">
            <div className="card-header orange">INFORMATIONS CONTRAT</div>
            <div className="card-body orange">
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Type de contract&nbsp;:</th>
                            <td>{name.toUpperCase()}</td>
                            <th>Condition de règlement&nbsp;:</th>
                            <td>{paymentType}</td>
                            <th>Montant mensuel&nbsp;:</th>
                            <td>{monthly}</td>
                            <th>Montant contrat&nbsp;:</th>
                            <td>{monthly * 36}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

ContractInformations.propTypes = {
    contract: PropTypes.shape({}).isRequired,
};

export default ContractInformations;
