import React, {Component} from 'react';
import {Badge, Button, Card, CardHeader, Modal, ModalHeader, Input} from 'reactstrap';

import Moment from "react-moment";
import EuroFormat from "../../../components/EuroFormat/EuroFormat";
import WHTable from "../../../components/WHTable";
import * as contractService from '../../../services/contract';
import * as contractExportService from '../../../services/contract/export';
import {history} from "../../../redux/store";


class Contracts extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pages: null,
			loaded: false,
			filters: []
		};


	}

	componentDidMount() {

	}

	getTotalValidated = () => {
		const {data} = this.state;
		const confort = 450;
		var total = 0;

		data['hydra:member'] && data['hydra:member'].map((el) => (
			el.status == 300 && el.levelService.name == 'essentiel' ? total += 300 : total = total,
				el.status == 300 && el.levelService.name == 'confort' ? total += 450 : total = total
		))

		return total;

	}

	getTotalCanceled = () => {
		const {data} = this.state;
		var total = 0;

		data['hydra:member'] && data['hydra:member'].map((el) => (
			el.status == 10 && el.levelService.name == 'essentiel' ? total += 300 : total = total,
				el.status == 10 && el.levelService.name == 'confort' ? total += 450 : total = total

		))

		return total;


	}


	export = () => {
		const {member} = this.props;
		const {filters} = this.state;
		if (member) {
			contractExportService.exportCSV(filters).then((data) => {
			})
		}
	}


	load = (filters) => {

		const {member} = this.props;
		const newFilter = {id: 'isAffected', value: member.id};

		const index = filters.filtered.findIndex(el => el.id === 'isAffected');


		if (index > -1) {
			filters.filtered.splice(index, 1, newFilter)

		} else {
			filters.filtered.push(newFilter)

		}


		this.setState({filters: filters.filtered})


		if (member) {
			contractService.search(filters).then((data) => {
				this.setState(
					{
						data: [],
					},
					() => {
						this.setState({
							data,
							pages: Math.ceil(data['hydra:totalItems'] / filters.pageSize),
							loaded: true
						});
					}
				);
			});
		}

	}


	columns = () => {
		return [
			{
				Header: '',
				accessor: 'id',
				filterable: false,
				Cell: row => (
					<div>
						<Button color="info" onClick={() => this.onEdit(row.value)}>
							<i className="fa fa-edit"/>
						</Button>
					</div>
				),
			},

			{
				Header: 'De',
				accessor: 'signatureDate[after]',
				Filter: ({filter, onChange}) => {
					return (
						<Input
							onChange={({target: {value}}) => onChange(value)}
							type="date"
							value={filter ? filter.value : ''}
						/>
					)
				}
			},

			{
				Header: 'A',
				accessor: 'signatureDate[before]',
				Filter: ({filter, onChange}) => {
					return (
						<Input
							onChange={({target: {value}}) => onChange(value)}
							value={filter ? filter.value : ''}
							type="date"
						/>
					)
				}
			},


			{
				Header: 'Statut',
				accessor: 'statusName',
				Cell: props => <Badge color={props.original.statusColor}>{props.original.statusName}</Badge>,
				Filter: ({filter, onChange}) =>
					<select
						onChange={event => onChange(event.target.value)}
						style={{width: "100%"}}
						value={filter ? filter.value : ""}
					>
						<option value="">Tous</option>
						<option value="10">Annulé</option>
						<option value="300">Validé</option>
					</select>

			},

			{
				Header: 'Date',
				accessor: 'signatureDate',
				Cell: row => <Moment format="DD/MM/YYYY HH:mm">{row.value}</Moment>,
			},


			{
				Header: 'Client',
				accessor: 'customer.lastName',
				Cell: row => (
					<div>{`${row.original.customer.firstName || ''} ${row.original.customer
						.lastName || ''}`}</div>
				),
			},
			{
				Header: 'Ville',
				accessor: 'customer.city',
			},
			{
				Header: 'Mobile',
				accessor: 'customer.mobile',
				filterable: false,
			},
			{
				Header: 'Montant CA',
				accessor: 'levelService.name',
				filterable: false,
				Cell: row => (
					row.original.status == 300 && ((row.value == 'essentiel') ? (
						<EuroFormat value="300"/>) : (row.value == 'confort' ? <EuroFormat value="450"/> : ''))
				),
				Footer: row => (
					<div>
						<div>Total CA <EuroFormat value={this.getTotalValidated()}/></div>
						<div>Total CA perdu -<EuroFormat value={this.getTotalCanceled()}/></div>
					</div>

				)
			},
		]
	}

	onEdit = (id) => {
		history.push(`/contracts/${id}`)

	}


	render() {
		const {loaded, data, pages, member} = this.state;

		const lst = [];
		data['hydra:member'] && data['hydra:member'].map((el) => (

			el.status == 300 || el.status == 10 ? lst.push(el) : ''
		))

		return (
			<div className="animated fadeIn">

				<Card>
					<CardHeader>
						<div>
							<Button color="success" onClick={this.export} className="button-export button-text">
								<i className="fa fa-refresh fa-lg icone-button-text"> </i> Export
							</Button>

						</div>
					</CardHeader>
					<WHTable
						data={lst}
						pages={pages}
						columns={this.columns()}
						filterable
						onFetchData={this.load}
						loading={!loaded}
					/>


				</Card>
			</div>

		);
	}

}

export default Contracts;
