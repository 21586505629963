import React, {Component,createRef} from "react";
import {Button, Card, CardHeader} from "reactstrap";
import "../../../styles";
import 'moment/locale/fr';
import * as contractService from "../../../services/contract";
import Loading from "../../../components/Loading";
import {getUserRoles} from "../../Authentication/redux";
import connect from "react-redux/lib/connect/connect";
import PropTypes from "prop-types";
import {ROLE_ADMIN} from "../../../constants/roles";
import {addContractToSync} from "../redux";
import {bindActionCreators} from "redux";
import WHTable from "../../../components/WHTable";
import moment from "moment";
import {exportReconduction} from "../../../services/contract";
import Swal from "sweetalert2";
import {DateField, ReactDateField} from "../../../components/Form/wh-field";

class RappelFinContrat extends Component {
	state = {
		appointmentId: null,
		open: false,
		data: [],
		pages: null,
		loaded: true,
		table: null,
		archivePending: false,
		month: moment().format('yyyy-MM'),
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.month !== this.state.month) {
			this.reload()
		}

	}

	columns = () => {
		return [
			{
				Header: "Nom",
				accessor: "customer.lastName",
			},
			{
				Header: "Prénom",
				accessor: "customer.firstName",
			},
			{
				Header: "Adresse",
				accessor: "customer.address",
			},
			{
				Header: "Code postal",
				accessor: "customer.zipCode",
			},
			{
				Header: "Ville",
				accessor: "customer.city",
			},
		]
	};

	reload = () => {
		this.load(this.state.table)
	}

	load = (state) => {
		this.setState({
			loaded: false,
			table: state
		});
		return contractService.getReconduction({
				page: state.page + 1,
				limit: state.pageSize,
				"filter.month": moment(this.state.month).month() + 1,
				"filter.year": moment(this.state.month).year() ,
				"filter.status": 300,
				"filter.reconduction": 100,
		}).then(data => {
			this.setState(
				{
					data: []
				},
				() => {
					this.setState({
						data: data.data,
						pages: data.meta.totalPages,
						loaded: true,
					});
				}
			);
		});
	};

	onExport = async () => {

		this.setState({
			loaded: false
		});
		try {
			const contractIds = this.state.data.map(contract => contract.id)
			const pdf = await exportReconduction(contractIds)
			const linkSource = `data:application/pdf;base64,${pdf.pdf}`;
			const link = document.createElement('a');

			link.href = linkSource;
			link.download = pdf.name;
			link.click();
			Swal.fire({
				title: `Les contrats ont bien été exporté`,
				icon: 'success',
				timer: 1500,
				timerProgressBar: true,
				showConfirmButton: false,
			})
			this.reload()

		} catch (e) {
			Swal.fire({
				title: `Les contrats n'ont pas été exporté`,
				icon: 'error',
				timer: 1500,
				timerProgressBar: true,
				showConfirmButton: false,
			})
		}

		this.setState({
			loaded: true
		});

	}

	render() {
		const {
			manual,
			roles
		} = this.props;
		const {columns, load} = this;

		return (

			<div>
				{this.state.archivePending && <Loading/>}

				<div className="animated fadeIn">
					<Card className="border-card">
						<CardHeader className="card-header-contract">
							<h2>Rappel Fin de Contrat</h2>
							<div className="card-actions">
								<Button
									className="button-export button-text"
									onClick={()=>this.onExport()}
									disabled={!this.state.loaded}
								>
									<i className="fa fa-file fa-lg icone-button-text"> </i> Export
								</Button>
							</div>
							<div className="compteur-entretien-tech">
								<span style={{marginRight: 10}}>Date de Signature : </span>
								{/*<Select
									className="bordure-select-entretien-tech"
									clearable={false}
									value={this.state.month}
									options={dateOptions}
									onChange={value => {
										this.setState({month: value.value})
									}}>
								</Select>*/}
								<ReactDateField
									value={moment(this.state.month).format('MM')}
									onChange={(date) => {
										this.setState({month: date})
									}}
									dateFormat="MM"
									showMonthYearPicker={true}
								/>
							</div>
						</CardHeader>
						{!this.state.archivePending && <WHTable
							data={this.state.data}
							pages={this.state.pages}
							columns={columns()}
							loading={!this.state.loaded}
							manual={manual}
							onFetchData={load}
						/>}
					</Card>
				</div>

			</div>
		);
	}
}

RappelFinContrat.propTypes = {
	roles: PropTypes.arrayOf(PropTypes.any).isRequired,
	onSaveContract: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	roles: getUserRoles(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			onSaveContract: addContractToSync,
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RappelFinContrat);


