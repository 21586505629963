import { getActionCellTemplate } from '../CellTemplate/Action';

import EuroCellTemplate from '../CellTemplate/Euro';

const getColumns = self => [
    {
        Header: '',
        id: 'id',
        filterable: false,
        accessor: d => `equipment=${d.equipment.id};levelService=${d.levelService.id}`,
        Cell: row => getActionCellTemplate(row, self),
        maxWidth: 90,
    },

    {
        Header: 'Famille',
        accessor: 'family.name',
    },
    {
        Header: 'Equipement',
        accessor: 'equipment.name',
    },
    {
        Header: 'Prestation',
        accessor: 'levelService.name',
    },
    {
        Header: 'Mensuel',
        accessor: 'monthly',
        Cell: EuroCellTemplate,
    },
    {
        Header: 'Remisé',
        accessor: 'remise',
        Cell: EuroCellTemplate,
    },
];

export default getColumns;
