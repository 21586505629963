import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { ModalBody, ModalFooter, Alert, Form } from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

import {FieldCheckbox, FieldTxt} from '../../../../components/Form/wh-field';

const UserForm = ({ error, handleSubmit, submitting }) => (
    <Form onSubmit={handleSubmit} className="form-horizontal">
        <ModalBody>
            <Field label="Nom :" name="name" component={FieldTxt} type="text" />
			<Field
				label="Activé"
				checked
				name="enabled"
				type="checkbox"
				component={FieldCheckbox}
			/>
            {error && (
                <Alert color="danger">
                    <div dangerouslySetInnerHTML={{ __html: error }} />
                </Alert>
            )}
        </ModalBody>

        <ModalFooter>
            <LaddaButton
                className="btn btn-success btn-ladda"
                loading={submitting}
                data-style={EXPAND_LEFT}
                type="submit"
            >
                <i className="fa fa-save" />
                &nbsp;Enregistrer
            </LaddaButton>
        </ModalFooter>
    </Form>
);

UserForm.propTypes = {
    error: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};
const formName = 'equipmentPopupForm';

export default reduxForm({ form: formName })(UserForm);
