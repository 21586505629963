import React, { Component, createRef } from 'react';
import { Card, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getIsOnline } from '../../components/OnlineStatus/redux';
import Popup from './Popup/';
import getRef from '../../utils/getRef';
import WHTable from '../../components/WHTable';
import Swal from "sweetalert2";

class CatalogTemplate extends Component {
	state = { loaded: true };
	createEditPopup = createRef();

	getColumns = () => this.props.getColumns(this);

	load = state => {
		this.setState({ loaded: false });
		if (this.props.isOnline) {
			this.props.onLoad(state);
		}
		this.setState({ loaded: true });
	};

	remove = toDelete => {

		Swal.fire({
			title: "Supprimer",
			text: this.props.removeMessage,
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#3085d6',
			denyButtonColor: '#d33',
			confirmButtonText: 'Supprimer',
			denyButtonText: 'Annuler',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					`${this.props.removeMessageObject} a bien été supprimé`,
					'',
					'success'
				)
				this.setState({ loaded: false });
				this.props.onRemove(toDelete);
				this.setState({ loaded: true });
			} else if (result.isDenied) {
				Swal.fire(
					`${this.props.removeMessageObject} n'a pas été supprimé`,
					'',
					'error'
				)
			}
		})

		/*if (window.confirm(this.props.removeMessage)) {
			this.setState({ loaded: false });
			this.props.onRemove(toDelete);
			this.setState({ loaded: true });
		}*/
	};

	newData = () => this.load();

	getPopup = () => getRef(this.createEditPopup, true);

	render() {
		const {
			data,
			pages,
			title,
			getPopupData,
			className,
			onCreate,
			onUpdate,
			onFetch,
			FormComponent,
			onReset
		} = this.props;
		const { loaded } = this.state;
		const { createEditPopup, newData, getColumns } = this;
		const popup = this.getPopup();

		return (
			<div className="animated fadeIn">
				<Popup
					ref={createEditPopup}
					newData={newData}
					getPopupData={getPopupData}
					className={className}
					onCreate={onCreate}
					onUpdate={onUpdate}
					onFetch={onFetch}
					onReset={onReset}
					FormComponent={FormComponent}
				/>

				<Card className="border-card">
					<CardHeader className="card-header-contract">
						<h2>{title}</h2>
						<div className="card-actions">
							<button onClick={() => popup.handleOpen()} className="button-create button-text">
								<i className="fa fa-plus fa-lg icone-button-text" />
								&nbsp;Nouveau
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={getColumns()}
						filterable
						onFetchData={this.load}
						loading={!loaded}
					/>
				</Card>
			</div>
		);
	}
}

CatalogTemplate.propTypes = {
	isOnline: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	removeMessageObject: PropTypes.string.isRequired,
	removeMessage: PropTypes.string.isRequired,
	className: PropTypes.string.isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	pages: PropTypes.number.isRequired,
	onLoad: PropTypes.func.isRequired,
	getColumns: PropTypes.func.isRequired,
	getPopupData: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	FormComponent: PropTypes.func.isRequired,
	onCreate: PropTypes.func.isRequired,
	onUpdate: PropTypes.func.isRequired,
	onFetch: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({ isOnline: getIsOnline(state) });

export default connect(mapStateToProps)(CatalogTemplate);
