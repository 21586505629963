import React, {useEffect, useState} from "react";
import {Field} from "redux-form";
import {Alert, Card, CardBody, CardHeader} from "reactstrap";
import {FieldSelect, FieldTxt} from "../../../../components/Form/wh-field";
import * as sectorService from "../../../../services/sectors";
import * as userService from "../../../../services/member";

const SectorForm = ({
						error,
						isArchived,
						sectorTechnique,
						equipe,
						technician,
						initialValues,
						local,
						isArchivedContract,
						userIsAdmin
					}) => {
	const [sectors, setSectors] = useState();
	const [techniciens, setTechniciens] = useState();
	const [choosenTeam, setChoosenTeam] = useState();
	const [sectorsLoaded, setSectorsLoaded] = useState(false);
	const [techniciensLoaded, setTechniciensLoaded] = useState(false);
	const fieldDisabled = isArchivedContract && !userIsAdmin

	const loadSectors = async () => {
		return sectorService
			.search({
				filtered: [
					{id: "enabled", value: true},
					{id: "sectorType", value: "technical"}
				]
			})
			.then(data => {
				setSectors(data["hydra:member"]);
			});
	};
	const loadTechniciens = async () => {
		return userService
			.search({
				filtered: [
					{id: "enabled", value: true},
					{id: "pagination", value: false},
					{id: "roles", value: "TECHNICIEN_SAV"}
				]
			}).then(data => {
				setTechniciens(data["hydra:member"]);
			})
	};

	useEffect(() => {
		loadSectors().then(r => setSectorsLoaded(true))
		loadTechniciens().then(r => setTechniciensLoaded(true))
	}, []);

	useEffect(() => {
		if (sectors) {
			const sectorFind = sectors.find(sector => sector['@id'] === sectorTechnique)
			setChoosenTeam(sectorFind)
		}
	}, [sectorTechnique]);

	const showTeams = (value) => {
		const sectorFind = sectors.find(sector => sector['@id'] === value)
		return sectorFind ? sectorFind.teams.map(team => ({
			value: team['@id'],
			name: team.name
		})) : []
	}

	return (
		<>
			{sectorsLoaded && techniciensLoaded && (
				<Card>
					<CardHeader>
						<h5 className="title-card-form"> <i style={{marginRight: 8}} className="fa fa-map-pin"/> Secteurs</h5>
					</CardHeader>
					<CardBody>
						<Field
							label="Sélectionnez le secteur technique : "
							name="sector"
							component={FieldSelect}
							required={false}
							nullValue="Choisir un secteur"
							options={
								sectors.map(sector => ({
									value: sector["@id"],
									name: sector.name
								}))
							}
							disabled={fieldDisabled}
						/>
						{sectorTechnique &&
							<Field
								label="Sélectionnez le sous-secteur technique (équipe) : "
								name="teamTechnique"
								required={false}
								nullValue="Choisir une équipe"
								component={FieldSelect}
								options={showTeams(sectorTechnique)}
								disabled={fieldDisabled}
							/>
						}{equipe &&
						<Field
							label="Sélectionnez le technicien : "
							name="technician"
							required={false}
							nullValue="Choisir un technicien"
							component={FieldSelect}
							options={techniciens.map(tech => ({
								value: tech["@id"],
								name: tech.name
							}))}
							disabled={fieldDisabled}
						/>
					}{!local &&
						<div>
							<Field
								label="Secteur commercial :"
								name="seller.team.sector.name"
								component={FieldTxt}
								type="text"
								disabled={true}
							/>

							<Field
								label=" Sous secteur commercial"
								name="seller.team.name"
								component={FieldTxt}
								disabled={true}
							/>

							<Field
								label=" commercial"
								name="seller.name"
								component={FieldTxt}
								disabled={true}
							/>
						</div>
					}
						{error && (
							<Alert color="danger">
								<div dangerouslySetInnerHTML={{__html: error}}/>
							</Alert>
						)}
					</CardBody>
				</Card>
			)}
		</>
	)
}

export default SectorForm
