import React, {Component} from 'react';
import {Button, Modal, ModalHeader} from 'reactstrap';
import ExportForm from './ExportForm';
import * as memberService from '../../../../../services/member';
import {SubmissionError} from 'redux-form';
import {toast} from 'react-toastify';
import Loading from '../../../../../components/Loading';
import * as contractExportService from "../../../../../services/contract/export";


class ExportPopup extends Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			data: {},
			loaded: true,
			loading: false
		};

	}

	export = () => {
		contractExportService.exportAllCSV().then((data) => {
		})
	}

	handleOpen = () => {
		this.setState({
			open: true
		});

		this.setState({
			loaded: false
		});


	}

	handleClose = () => {
		this.setState({
			open: false,
		});
	}

	onSubmit = (data) => {
		data.archived = 1;
		this.setState({
			loading: true
		})
		contractExportService.exportAllCSV(data).then((data) => {
			this.setState({
				loading: false
			})
		})
	}

	render() {
		const {className} = this.props;
		const {loaded, loading} = this.state;
		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				{loading && <Loading/>}
				<ModalHeader toggle={() => this.handleClose()}>Export</ModalHeader>
				<ExportForm onSubmit={this.onSubmit} initialValues={this.state.data}/>

			</Modal>
		)
	}
}

export default ExportPopup;
