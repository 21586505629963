import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import CatalogTemplate from '..';
import Form from './PopupForm';

import {
    loadEquipments,
    getEquipmentsEditedItem,
    getEquipmentsList,
    getEquipmentsPages,
    deleteEquipment,
    fetchEquipment,
    updateEquipment,
    createEquipment,
} from './redux';

import getColumns from './utils';
import {resetEquipment} from "./redux/actions";

const Equipments = ({ onLoad, onRemove, data, pages, onCreate, onUpdate, onFetch, className, onReset }) => (
    <CatalogTemplate
        removeMessage="Êtes-vous sûr de vouloir supprimer cet équipement ?"
		removeMessageObject="L'équipement"
        title="Gestion des équipements"
        onLoad={onLoad}
        onRemove={onRemove}
        data={data}
        pages={pages}
        getPopupData={getEquipmentsEditedItem}
        getColumns={getColumns}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onFetch={onFetch}
		onReset={onReset}
        className={className}
        FormComponent={Form}
    />
);

Equipments.defaultProps = { className: '' };

Equipments.propTypes = {
    onLoad: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    pages: PropTypes.number.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onFetch: PropTypes.func.isRequired,
    className: PropTypes.string,
	onReset: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
    data: getEquipmentsList(state),
    pages: getEquipmentsPages(state),
});

export const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            onLoad: loadEquipments,
            onRemove: deleteEquipment,
            onCreate: createEquipment,
            onUpdate: updateEquipment,
            onFetch: fetchEquipment,
			onReset : resetEquipment
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Equipments);
