import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getSignatureStyle } from '../../utils';

const SpecificConditions = ({ place, sellerSignature, customerSignature }) => {
    const newDate = moment().format('DD-MM-YYYY');

    return (
        <div className="col">
            <h1 className="title">CONDITIONS PARTICULIÈRES</h1>

            <div className="bold black">
                OMÉO vous propose 3 types de contrats d’entretien pour satisfaire d’avantages vos
                attentes . Ainsi, vous pouvez opter pour:
            </div>

            <fieldset>
                <legend className="bold black">
                    <h3>• LE CONTRAT « ESSENTIEL »</h3>
                </legend>
                <div className="subtitle">
                    Ce contrat vous assure l’entretien de votre appareil de chauffage domestique une fois dans l’année et ce, pendant les 36 mois de l’abonnement.
                    En cas de panne, le déplacement et la main d’œuvre seront facturés en fonction de la zone.
                </div>

                <div className="roww">
                    <div className="col">
                        <div className="block">
                            <div className="block-title">PAC Air / Air</div>
                            <div className="block-body">
                                <ul>
                                    <li>Nettoyage du groupe extérieu</li>
                                    <li>
                                        Mise en place d’un antibactérien sur le groupe extérieur
                                    </li>
                                    <li>Nettoyage des unités intérieures</li>
                                    <li>Nettoyage des filtr es des unités intérieures</li>
                                    <li>Vérification des paramètres</li>
                                    <li>
                                        Remise en état des paramètres et programmations du matériel
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="block">
                            <div className="block-title">CHAUDIÈRE HYBRIDE</div>
                            <div className="block-body">
                                <ul>
                                    <li>Nettoyage du groupe extérieur</li>
                                    <li>
                                        Mise en place d’un antibactérien sur le groupe extérieur
                                    </li>
                                    <li>Nettoyage du kit hydraulique</li>
                                    <li>Nettoyage du pot à boue</li>
                                    <li>Nettoyage des filtres à tamis</li>
                                    <li>Mise à niveau du réseau hydraulique</li>
                                    <li>Nettoyage de l’échangeur si nécessaire</li>
                                    <li>Vérification des paramètres</li>
                                    <li>
                                        Remise en état des paramètres et programmations du matériel
                                    </li>
                                    <li>Nettoyage du conduit</li>
                                </ul>
                            </div>
                        </div>

                        <div className="block">
                            <div className="block-title">CHAUDIERE / POELE / INSERT A GRANULÉS</div>
                            <div className="block-body">
                                <ul>
                                    <li>Ramonage du conduit</li>
                                    <li>Vérification et nettoyage de l’extracteur</li>
                                    <li>Vérification et nettoyage du ventilateur</li>
                                    <li>Vérification et nettoyage de la vis sans fin</li>
                                    <li>Vérification et nettoyage de la bougie d’allumag</li>
                                    <li>Vérification et nettoyage du circuit imprimé</li>
                                    <li>Vérification et nettoyage de l’échangeur</li>
                                    <li>Vérification et nettoyage du corps de chauffe</li>
                                    <li>Vérification des paramètres de fonctionnement</li>
                                    <li>Remise à zéro du compteur</li>
                                    <li>Essai à froid des organes de fonctionnement</li>
                                    <li>Remise en marche de l’appareil</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="block">
                            <div className="block-title">PAC Air / Eau</div>
                            <div className="block-body">
                                <ul>
                                    <li>Nettoyage du groupe extérieu</li>
                                    <li>
                                        Mise en place d’un antibactérien sur le groupe extérieur
                                    </li>
                                    <li>Nettoyage du kit hydraulique</li>
                                    <li>Nettoyage du pot à boue</li>
                                    <li>Nettoyage des filtres à tamis</li>
                                    <li>Mise à niveau du réseau hydraulique</li>
                                    <li>Nettoyage de l’échangeur si nécessaire</li>
                                    <li>Vérification des paramètres</li>
                                    <li>
                                        Remise en état des paramètres et programmations du matériel
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="block">
                            <div className="block-title">CHAUDIÈRE PULSATOIRE / CONDENSATION</div>
                            <div className="block-body">
                                <ul>
                                    <li>Nettoyage du pot à boue</li>
                                    <li>Nettoyage des filtres à tamis</li>
                                    <li>Mise à niveau du réseau hydraulique</li>
                                    <li>Nettoyage de l’échangeur si nécessaire</li>
                                    <li>Vérification des paramètres</li>
                                    <li>
                                        Remise en état des paramètres et programmations du matériel
                                    </li>
                                    <li>Nettoyage du conduit</li>
                                </ul>
                            </div>
                        </div>

                        <div className="block">
                            <div className="block-title">BALLON THERMODYNAMIQUE</div>
                            <div className="block-body">
                                <ul>
                                    <li>Vérification et nettoyage du conduit de ventilation</li>
                                    <li>Vérification et nettoyage du groupe compresseur</li>
                                    <li>Vérification et nettoyage de l’échangeur si nécessaire</li>
                                    <li>
                                        Vérification du groupe de sécurité si défectueux = 25€ pour
                                        le remplacer.
                                    </li>
                                    <li>Vérification et nettoyage du filtre à tamis si présent</li>
                                    <li>Vérification du raccord diélectrique</li>
                                    <li>Vérification des paramètres de fonctionnement</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset id="confort">
                <legend className="bold black">
                    <h3>Contrat Confort</h3>
                </legend>
                <div className="subtitle">Ce contrat comprend la totalité du CONTRAT ESSENTIEL <br/>En cas de panne, la main d’œuvre et le déplacement sont offerts.
                </div>
                {/*<div>*/}
                {/*    En cas de panne, le déplacement sera facturé en fonction de la zone. un*/}
                {/*    diagnostic sera établi pour définir un devis le cas échéant. si le devis est*/}
                {/*    accordé par le client, le déplacement sera déduitde celui-ci et la main d’œuvre*/}
                {/*    sera gratuit*/}
                {/*</div>*/}
                {/*<div>Seront facturés en supplément&nbsp;:</div>*/}
                {/*<ul>*/}
                {/*    <li>a) les gicleurs sur brûleurs fuel,</li>*/}
                {/*    <li>*/}
                {/*        b) les pièces sorties de garantie, dont le remplacement seraient r econnues*/}
                {/*        nécessaire,*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        c) les remplacements de corps de chauffe de chaudières et les remplacements*/}
                {/*        de brûleurs,*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        d) les détar tr ages de serpentins ou d’échangeurs d’eau chaude sanitaire,*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        e) tous les déplacements suite à des appels injustifiés (manque de mazout,*/}
                {/*        panne de secteur,disjoncteur en sécurité, thermostat mal réglé, etc),*/}
                {/*        déplacements et travaux causés par le gel= prévoyez votre antigel en temps*/}
                {/*        utile.*/}
                {/*    </li>*/}
                {/*</ul>*/}
            </fieldset>

            {/*<fieldset id="tranquillite">*/}
            {/*    <legend className="bold black">*/}
            {/*        <h3>*/}
            {/*            Contrat Tranquillité *<span> sauf produit non installé par oméo</span>*/}
            {/*        </h3>*/}
            {/*    </legend>*/}
            {/*    <div>*/}
            {/*        Le contrat vous assure l’entretien de votre appareil de chauffage domestique une*/}
            {/*        fois dans l’annéeet la garantie main-d’oeuvre, déplacement ainsi que les pièces*/}
            {/*        détachées en cas de pannesurvenant éventuellement sur chaudières ou brûl eurs,*/}
            {/*        et ce, pendant les 36 mois de l’abonnement.Ce contrat ne concerne exclusivement*/}
            {/*        que les appareils et chaudières jusque 10 ans d’âge(année de fabrication faisant*/}
            {/*        foi).*/}
            {/*    </div>*/}
            {/*    <div>** Seront facturés en supplément&nbsp;:</div>*/}
            {/*    <ul>*/}
            {/*        <li>*/}
            {/*            a) le remplacement du corps de chauffe ou échangeur chauffage et le*/}
            {/*            remplacement des brûleurs,*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            b) les détar tr ages de serpentins ou d’échangeurs d’eau chaude sanitaire,*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            c) tous les déplacements suite à des appels injustifiés (manque de mazout,*/}
            {/*            panne de secteur, disjoncteuren sécurité, thermostat mal réglé, etc.),*/}
            {/*            déplacements et travaux causés par le gel= prévoyez votre antigel en temps*/}
            {/*            utile.*/}
            {/*            <br />*/}
            {/*            (Les clients ayant souscrit une ext ension de garantie chaufferie à 5 ans,*/}
            {/*            ne sont concernéspar ce contrat « Tranquillité » qu’à l’expiration de cette*/}
            {/*            ext ension de garantie).*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</fieldset>*/}

            <div>
                <table className="table-sign">
                    <tbody>
                        <tr>
                            <th style={getSignatureStyle(sellerSignature)}>
                                Signature du conseiller
                            </th>

                            <td>
                                Fait à&nbsp;: {place}
                                <br />
                                <br />
                                Le {newDate}
                            </td>
                            <th style={getSignatureStyle(customerSignature)}>
                                Signature du souscripteur
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

SpecificConditions.defaultProps = {
    sellerSignature: null,
    customerSignature: null,
    place: '',
};
SpecificConditions.propTypes = {
    place: PropTypes.string,
    sellerSignature: PropTypes.string,
    customerSignature: PropTypes.string,
};

export default SpecificConditions;
