import React, {Component} from "react";
import {
	getFormSyncErrors,
	reduxForm,
	formValueSelector,
	getFormValues
} from "redux-form";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	ModalBody,
	ModalFooter,
	Alert,
	Row,
	Form,
	Col, Modal, ModalHeader, Button
} from "reactstrap";
import LaddaButton, {EXPAND_LEFT} from "react-ladda";
import classnames from "classnames";
import isEmpty from "lodash.isempty";

import {formName} from "../constants";
import {ROLE_ADMIN} from "../../../constants/roles";
import CustomerForm from "./Views/CustomerForm";
import FormContract from "./Views/ContractForm";
import ValidationForm from "./Views/ValidationForm";
import Materials from "./Views/Materials";
import * as PriceService from "../../../services/catalog/price";
import SectorForm from "./Views/SectorForm";
import {init} from "../../../services/catalog/price";
import EventAppointmentList from "../../EventAppointment/EventAppointmentList";
import {redirectToContractToSync, redirectToPreContrat} from "../../../utils/redirectTo";
import {bindActionCreators} from "redux";
import {syncOneContract} from "../redux";

const CUSTOMER_TAB = "customer";
const MATERIALS_TAB = "materials";
const CONTRACT_TAB = "contract";
const SECTOR_TAB = "sector";
const ENTRETIEN_TAB = "entretien"

class FullContractForm extends Component {
	state = {
		activeTab: CUSTOMER_TAB,
		calculate: false,
		modalBrouillonOpen: false,
		require: true,
		saveErrors: false
	};

	next = () => {
		let next = "";
		const {activeTab} = this.state;
		if (activeTab == CUSTOMER_TAB) next = MATERIALS_TAB;
		if (activeTab == MATERIALS_TAB) next = CONTRACT_TAB;

		this.setState({activeTab: next});
	};

	toggle = tab => () => {
		if (this.state.activeTab !== tab) {
			this.setState({activeTab: tab});
		}
	};

	async componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.contractInformation !== undefined && this.props.levelService !== undefined && this.state.calculate === false) {
			await init().then(() => this.amountCalculator())
		}

		if (this.props.errors !== prevProps.errors && this.state.saveErrors) {
			this.props.handleSubmit()
			redirectToContractToSync()
		}
	}

	componentDidMount() {
		if (this.props.initialValues.preContrat && this.props.local) {
			this.setState({require: false})
		}
	}

	changeTabIfError = errors => {
		const {activeTab} = this.state;
		if (isEmpty(errors)) {
			return;
		}

		const couldGoOnContractTab =
			["paymentType", "levelService", "identity", "bic", "iban"].filter(
				x => Object.keys(errors).includes(x)
			).length > 0;

		const couldGoOnCustomerTab = !!errors.customer;

		const couldGoOn = {
			[CONTRACT_TAB]: couldGoOnContractTab,
			[CUSTOMER_TAB]: couldGoOnCustomerTab
		};

		let otherTab;
		if ([CONTRACT_TAB, CUSTOMER_TAB].includes(activeTab)) {
			otherTab = activeTab === CUSTOMER_TAB ? CONTRACT_TAB : CUSTOMER_TAB;
		} else {
			otherTab = couldGoOn[CUSTOMER_TAB] ? CUSTOMER_TAB : CONTRACT_TAB;
		}

		if (couldGoOn[otherTab] && !couldGoOn[activeTab]) {
			this.toggle(otherTab)();
		}
	};

	amountCalculator = (levelService = this.props.levelService, promotion) => {
		if (this.props.contractInformation && this.props.levelService) {
			this.setState({calculate: true})
		}
		PriceService.calculator(
			this.props.contractInformation,
			levelService,
			promotion,
		).then(amount => {
			this.props.change("monthly", amount);
		});
	};

	styleTab = value => classnames({active: this.state.activeTab === value});

	handleCloseModalBrouillon = (save) => {
		if (save) {
			this.props.handleSubmit()
		}
		this.setState({modalBrouillonOpen: false})
	}
	modalBrouillon = () => {
		this.setState({modalBrouillonOpen: true})
	}
	saveBrouillon = () => {
		this.setState({require: false, saveErrors: true})
	}

	savePreContrat = async () => {
		await this.props.handleSubmit()
		await this.props.onSyncContract(this.props.initialValues.tmpID);
		redirectToPreContrat()
	}

	render() {

		const {
			user,
			id,
			invalid,
			submitFailed,
			handleSubmit,
			submitting,
			change,
			initialValues,
			oldContract,
			contractType,
			identityCapture,
			paymentType,
			ribCapture,
			isContractValid,
			errors,
			isArchived,
			local,
			sectorTechnique,
			equipe,
			isArchivedContract,
			userIsAdmin
		} = this.props;

		const {styleTab, toggle} = this;
		const {roles} = user;
		const {
			contractSignature,
			sepaSignature,
		} = initialValues;
		const hasSignedSmthg =
			!!contractSignature || !!sepaSignature;
		const isAdmin = id && user && roles.indexOf(ROLE_ADMIN) > -1
		const hasSubmitFailed = invalid && submitFailed;

		const translate = (error) => {
			switch (error) {
				case 'firstName' :
					return "Prénom"
				case 'lastName':
					return "Nom"
				case 'address':
					return "Adresse"
				case 'zipCode':
					return "Code postal"
				case 'city':
					return "Ville"
				case 'identity':
					return "N° pièce d'identité"
				default :
					return error
			}
		}
		return (
			<>
				<Modal isOpen={this.state.modalBrouillonOpen} fade={false}>
					<ModalHeader
						toggle={() => this.handleCloseModalBrouillon(false)}>{"Eléments manquants"}</ModalHeader>
					<div>
						{this.state.modalBrouillonOpen && Object.keys(errors).length >= 1 &&
							<div className={"modal-brouillon"}>
								{errors["customer"] &&
									<div className={"brouillon-part"}>
										<h4>Page client</h4>
										{Object.keys(errors["customer"]).map((error, key) => {
											return (
												<div key={key}> {translate(error)} </div>
											)
										})}
									</div>}
								{(errors["bic"] || errors["iban"] || errors["iban"]) &&
									<div className={"brouillon-part"}>
										<h4>Page Contract</h4>
										{Object.keys(errors).map((error, key) => {
											if (error !== 'customer') {
												return (
													<div key={key}> {translate(error)} </div>
												)
											}
										})}
									</div>}
							</div>
						}
						<LaddaButton
							className="btn btn-primary btn-ladda btn-block"
							onClick={() => this.handleCloseModalBrouillon(true)}
							loading={submitting}
							data-style={EXPAND_LEFT}
							type="submit"
						>
							<i className="fa fa-save"/> Continuer le contrat
						</LaddaButton>

						<LaddaButton
							className="btn btn-primary btn-ladda btn-block"
							onClick={() => this.saveBrouillon()}
							loading={submitting}
							data-style={EXPAND_LEFT}
							type="submit"
						>
							<i className="fa fa-save"/> Mode brouillon
						</LaddaButton>
					</div>
				</Modal>

				<Form onSubmit={handleSubmit} className="form-vertical contract-form-card">
					<ModalBody>
						{hasSubmitFailed && (
							<Alert color="danger">
								<div>Le formulaire contient des erreurs</div>
							</Alert>
						)}

						<Nav tabs>
							<NavItem>
								<NavLink
									className={styleTab(CUSTOMER_TAB)}
									onClick={toggle(CUSTOMER_TAB)}
								>
									<i className="fa fa-user"/> Client
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink
									className={styleTab(MATERIALS_TAB)}
									onClick={toggle(MATERIALS_TAB)}
								>
									<i className="fa fa-cog"/> Materiels
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink
									className={styleTab(CONTRACT_TAB)}
									onClick={toggle(CONTRACT_TAB)}
								>
									<i className="fa fa-file"/> Contrat
								</NavLink>
							</NavItem>
							{!local &&
								<NavItem>
									<NavLink
										className={styleTab(ENTRETIEN_TAB)}
										onClick={toggle(ENTRETIEN_TAB)}
									>
										<i className="fa fa-calendar-check-o"/> Entretiens
									</NavLink>
								</NavItem>
							}
							<NavItem>
								<NavLink
									className={styleTab(SECTOR_TAB)}
									onClick={toggle(SECTOR_TAB)}
								>
									<i className="fa fa-map-pin"/> Secteurs
								</NavLink>
							</NavItem>
							{isAdmin && (
								<NavItem>
									<NavLink
										onClick={toggle("validation")}
										className={styleTab("validation")}
									>
										<i className="fa fa-check"/> Validation

									</NavLink>
								</NavItem>
							)}
						</Nav>

						<TabContent
							activeTab={this.state.activeTab}
							style={{border: "none"}}
						>
							<TabPane tabId={CUSTOMER_TAB} style={{padding: 0}}>
								<Row>
									<Col lg={12} md={12} xs={12}>
										<CustomerForm
											require={this.state.require}
											initialValues={initialValues}
											next={this.next}
											isArchivedContract={isArchivedContract}
											userIsAdmin={userIsAdmin}
										/>
									</Col>
								</Row>
							</TabPane>

							<TabPane tabId={MATERIALS_TAB} style={{padding: 0}}>
								<Row>
									<Col lg={12} md={12} xs={12}>
										<Materials
											amountCalculator={this.amountCalculator}
											materials={
												initialValues.contractInformation
											}
											initialValues={initialValues}
											change={change}
											next={this.next}
											isArchivedContract={isArchivedContract}
											userIsAdmin={userIsAdmin}
										/>
									</Col>
								</Row>
							</TabPane>

							<TabPane tabId={CONTRACT_TAB} style={{padding: 0}}>
								<Row>
									<Col lg={12} md={12} xs={12}>
										<FormContract
											require={this.state.require}
											amountCalculator={this.amountCalculator}
											oldContract={oldContract}
											initialValues={{
												...initialValues,
												levelService: initialValues.levelService
													? initialValues.levelService[
														"@id"
														]
													: null,
											}}
											paymentType={paymentType}
											contractType={contractType}
											change={change}
											identityCapture={identityCapture}
											ribCapture={ribCapture}
											next={this.next}
											isArchivedContract={isArchivedContract}
											userIsAdmin={userIsAdmin}
										/>
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId={ENTRETIEN_TAB} style={{padding: 0}}>
								<Row>
									<Col lg={12} md={12} xs={12}>
										<EventAppointmentList
											local={local}
											contract={initialValues}
											user={user}
											isArchivedContract={isArchivedContract}
											userIsAdmin={userIsAdmin}
										/>
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId={SECTOR_TAB} style={{padding: 0}}>
								<Row>
									<Col lg={12} md={12} xs={12}>
										<SectorForm
											sectorTechnique={sectorTechnique}
											equipe={equipe}
											local={local}
											initialValues={initialValues}
											user={user}
											isArchivedContract={isArchivedContract}
											userIsAdmin={userIsAdmin}
										/>
									</Col>
								</Row>
							</TabPane>
							{isAdmin && (
								<TabPane tabId="validation" style={{padding: 0}}>
									<Row>
										<Col lg={12} md={12} xs={12}>
											<ValidationForm
												paymentType={paymentType}
												change={change}
												contractSigned={!!contractSignature}
												sepaSigned={!!sepaSignature}
												id={id}
												next={this.next}
												isArchivedContract={isArchivedContract}
												userIsAdmin={userIsAdmin}
											/>
										</Col>
									</Row>
								</TabPane>
							)}
						</TabContent>

						{hasSubmitFailed && (
							<Alert color="danger">
								<div>Le formulaire contient des erreurs</div>
							</Alert>
						)}


					</ModalBody>
					{/*{!isArchived && (*/}
					<ModalFooter>
						{(Object.keys(errors).length === 0 || !local) && !initialValues.preContrat &&
							<LaddaButton
								className="btn btn-primary btn-ladda btn-block button-border"
								onClick={() => this.changeTabIfError(errors)}
								loading={submitting}
								data-style={EXPAND_LEFT}
								type="submit"
							>
								<i className="fa fa-save"/> Enregistrer
							</LaddaButton>
						}
						{!local && initialValues.preContrat &&
							<LaddaButton
								className="btn btn-primary btn-ladda btn-block button-border"
								onClick={() => this.changeTabIfError(errors)}
								loading={submitting}
								data-style={EXPAND_LEFT}
								type="submit"
							>
								<i className="fa fa-save"/> Enregistrer
							</LaddaButton>
						}
						{Object.keys(errors).length === 0 && local && initialValues.preContrat &&
							<Button
								className="btn btn-primary-perso btn-ladda btn-block button-border"
								onClick={() => {
										this.savePreContrat()
								}}>
								<i className="fa fa-save"/> Enregistrer
							</Button>
						}
						{local && Object.keys(errors).length >= 1 &&
							<div
								className="btn btn-primary btn-ladda btn-block button-border"
								onClick={() => this.modalBrouillon()}
								data-style={EXPAND_LEFT}
							>
								<i className="fa fa-save"/> Enregistrer
							</div>
						}
					</ModalFooter>
					{/*)}*/}
				</Form>
			</>
		);
	}
}

FullContractForm.defaultProps = {
	levelService: undefined,
	equipe: undefined,
	sectorTechnique: undefined,
	sepaSigned: undefined,
	contractSigned: undefined,
	signed: undefined,
	identityCapture: undefined,
	contractType: undefined,
	submitting: undefined,
	contractInformation: undefined,
	ribCapture: undefined,
	id: undefined,
	paymentType: undefined,
	currentValues: undefined,
	errors: undefined,
	isContractValid: false,
	isArchivedContract: false
};

FullContractForm.propTypes = {
	change: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	levelService: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
	user: PropTypes.shape({}).isRequired,
	initialValues: PropTypes.shape({}).isRequired,
	currentValues: PropTypes.shape({}),
	errors: PropTypes.shape({}),
	contractInformation: PropTypes.arrayOf(PropTypes.shape({})),
	id: PropTypes.number,
	onSyncContract: PropTypes.func.isRequired,
	contractType: PropTypes.string,
	identityCapture: PropTypes.string,
	paymentType: PropTypes.string,
	ribCapture: PropTypes.string,
	submitting: PropTypes.bool,
	invalid: PropTypes.bool.isRequired,
	submitFailed: PropTypes.bool.isRequired,
	isContractValid: PropTypes.bool,
	isArchived: PropTypes.bool.isRequired,
	sectorTechnique: PropTypes.string,
	equipe: PropTypes.string,
	isArchivedContract: PropTypes.bool,
};

const selector = formValueSelector(formName);

const ReduxFormWHForm = reduxForm({form: formName, enableReinitialize: true})(
	FullContractForm
);
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			onSyncContract: syncOneContract,
		},
		dispatch
	);

const mapStateToProps = state => ({
	contractType: selector(state, "contractType"),
	levelService: selector(state, "levelService"),
	promotion: selector(state, "promotion"),
	contractInformation: selector(state, "contractInformation"),
	identityCapture: selector(state, "identityCapture"),
	ribCapture: selector(state, "ribCapture"),
	paymentType: selector(state, "paymentType"),
	contractSigned: selector(state, "contractSigned"),
	sepaSigned: selector(state, "sepaSigned"),
	id: selector(state, "id"),
	signed: selector(state, "signed"),
	sectorTechnique: selector(state, 'sector'),
	equipe: selector(state, 'teamTechnique'),
	oldContract: selector(state, "oldContract"),
	currentValues: getFormValues(formName)(state),
	errors: getFormSyncErrors(formName)(state),
});


export default connect(
	mapStateToProps,
	mapDispatchToProps
)
(ReduxFormWHForm);
