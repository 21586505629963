import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { render } from "react-snapshot";
import { Provider } from "react-redux";
import store, { history, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/lib/integration/react";
// import './init/reactotron.js';
import "./styles";
import * as serviceWorker from "./serviceWorker";
import OnlineStatus from "./components/OnlineStatus";
import Layout from "./modules/Layout/";
import Login from "./modules/Authentication/";
import Page404 from "./modules/Exception/Page404/";
import Page500 from "./modules/Exception/Page500/";
import Loading from "./components/Loading";
import { ROOT, LOGIN } from "./constants/paths";

render(
	<Provider store={store}>
		<Fragment>
			<OnlineStatus />
			<PersistGate loading={<Loading />} persistor={persistor}>
				<ConnectedRouter history={history}>
					<Switch>
						<Route
							exact
							path={LOGIN}
							name="Login Page"
							component={Login}
						/>
						<Route
							exact
							path="/404"
							name="Page 404"
							component={Page404}
						/>
						<Route
							exact
							path="/500"
							name="Page 500"
							component={Page500}
						/>
						<Route path={ROOT} name="Home" component={Layout} />
					</Switch>
				</ConnectedRouter>
			</PersistGate>
		</Fragment>
	</Provider>,
	document.getElementById("root"),
);
//serviceWorker.register();
