import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, Button } from "reactstrap";

import WHTable from "../../components/WHTable";
import CreateEditPopup from "./CreateEditPopup";
import * as teamService from "../../services/team";


import PropTypes from "prop-types";
import Swal from "sweetalert2";

class Teams extends Component {
	createEditPopup = createRef();
	exportPopup = createRef();

	state = {
		data: [],
		pages: null,
		loaded: true
	};

	columns = () => [
		{
			Header: "",
			accessor: "id",
			filterable: false,
			Cell: row => (
				<div>
					<Button
						color="info"
						onClick={() =>
							this.createEditPopup.current.handleOpen(row.value)
						}
					>
						<i className="fa fa-edit" />
					</Button>
					<Button
						color="danger"
						onClick={() => this.remove(row.value)}
					>
						<i className="fa fa-trash" />
					</Button>
				</div>
			),
			maxWidth: 90
		},
		{
			Header: "Nom d'équipe",
			accessor: "name"
		},
		{
			Header: "Secteur",
			accessor: "sector.name"
		},
		{
			Header: "Responsable",
			accessor: "userMaster.name"
		},
		{
			Header: this.props.sectorType === 'technical' ? 'Techniciens' : 'Commerciales',
			accessor: "users",
			Cell: row => (
				<div className="">
					{row.value.map((option, key) => (
						<p key={key}>{option.name}</p>
					))}
				</div>
			),
		}
	];

	load = state => {
		this.setState({
			loaded: false,

		});
		return teamService
			.search({
				...state,
				filtered: [
					...state.filtered,
					{ id: "sector.sectorType", value: this.props.sectorType }
				]
			})
			.then(data => {
			this.setState(
				{
					data: []
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize
						),
						loaded: true
					});
				}
			);
		});
	};

	remove = toDelete => {

		Swal.fire({
			title: "Supprimer",
			text: 'Êtes-vous sûr de vouloir supprimer cette équipe ?',
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#3085d6',
			denyButtonColor: '#d33',
			confirmButtonText: 'Supprimer',
			denyButtonText: 'Annuler',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(
					"L'équipe a bien été supprimé",
					'',
					'success'
				)

				this.setState({
					loaded: false
				});

				teamService
					.remove(toDelete)
					.then(() => {
						const lst = this.state.data;

						const index = lst.findIndex(el => el.id === toDelete);

						if (index > -1) {
							lst.splice(index, 1);

							this.setState({
								data: lst,
								loaded: true
							});
						}
					})

			} else if (result.isDenied) {
				Swal.fire(
					"L'équipe n'a pas été supprimé",
					'',
					'error'
				)
			}
		})

		/*if (
			window.confirm("Êtes-vous sûr de vouloir supprimer cette équipe ?")
		) {
			this.setState({
				loaded: false
			});

			teamService
				.remove(toDelete)
				.then(() => {
					const lst = this.state.data;

					const index = lst.findIndex(el => el.id === toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({
							data: lst,
							loaded: true
						});
					}
				})
				.catch(() => {
					alert(
						"Il existe des dépendances sur cette équipe , elle ne peut donc être supprimée"
					);
				});
		}*/
	};

	newData = data => {
		const lst = this.state.data;

		const index = lst.findIndex(el => el.id === data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({
			data: lst
		});
	};

	render() {
		const { loaded, data, pages } = this.state;
		return (
			<div className="animated fadeIn">
				<CreateEditPopup
					ref={this.createEditPopup}
					newData={this.newData}
					sectorType={this.props.sectorType}
				/>
				<Card className="border-card">
					<CardHeader className="card-header-contract">
						<h2>Gestion des Equipes {loaded && this.props.sectorType==="technical" ? "Techniques": "Commerciales" }</h2>
						<div className="card-actions">
							<button
								onClick={() =>
									this.createEditPopup.current.handleOpen()
								}
								className="button-create button-text"
							>
								<i className="fa fa-plus fa-lg icone-button-text" />
								&nbsp;Nouveau
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	}
}

Teams.propTypes = {};

export const mapStateToProps = state => ({});

export default connect(mapStateToProps)(Teams);
