// badge addon to NavItem
import React from 'react';
import { Badge } from 'reactstrap';
import classNames from 'classnames';

export const badge = badge => {
    if (badge) {
        const classes = classNames(badge.class);
        return (
            <Badge className={classes} color={badge.variant}>
                {badge.text}
            </Badge>
        );
    }
};

// simple wrapper for nav-title item
export const wrapper = item =>
    item.wrapper && item.wrapper.element
        ? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)
        : item.name;

// nav list section title
export const title = (title, key) => {
    const classes = classNames('nav-title', title.class);
    return (
        <li key={key} className={classes}>
            {wrapper(title)}
        </li>
    );
};

// nav list divider
export const divider = (divider, key) => {
    const classes = classNames('divider', divider.class);
    return <li key={key} className={classes} />;
};

export const handleClick = e => {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
};

export const activeRoute = (routeName, props) =>
    props.location.pathname.indexOf(routeName) > -1
        ? 'nav-item nav-dropdown open'
        : 'nav-item nav-dropdown';

export const hideMobile = () => {
    if (document.body.classList.contains('sidebar-mobile-show')) {
        document.body.classList.toggle('sidebar-mobile-show');
    }
};
