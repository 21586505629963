import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';

const filterMethod = (filter, row) => {
	const id = filter.pivotId || filter.id;
	const value = String(row[id].toLowerCase());
	const search = String(filter.value.toLowerCase());

	return !row[id] || !filter.value || value.indexOf(search) > -1;
};

const WHTable = ({ data, pages, loading, columns, filterable, onFetchData, manual, actualPage, onPageChange, onSortedChange }) => {
	return (
		<ReactTable
			manual={manual}
			defaultFilterMethod={filterMethod}
			data={data}
			page={actualPage}
			pages={pages}
			loading={loading}
			columns={columns}
			filterable={filterable}
			onFetchData={onFetchData}
			className="-striped -highlight"
			previousText="Précédent"
			nextText="Suivant"
			loadingText="Chargement ..."
			noDataText="Pas de données"
			ofText="sur"
			rowsText="éléments"
			onPageChange={onPageChange}
			onSortedChange={onSortedChange}
		/>
	);
}

WHTable.defaultProps = {
	onFetchData: () => {},
};
WHTable.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	pages: PropTypes.number,
	loading: PropTypes.bool.isRequired,
	manual: PropTypes.bool,
	filterable: PropTypes.bool.isRequired,
	onFetchData: PropTypes.func,
	columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
WHTable.defaultProps = {
	manual: true,
	pages: 1,
};

export default WHTable;
