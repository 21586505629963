import React, {Component} from 'react';
import {Button, Col, Input, Label, Modal, ModalHeader} from "reactstrap";
import AppointmentForm from "./AppointmentForm";
import * as AppointmentService from "../../../../services/appointment";
import * as members from "../../../../services/member";

class AppointmentPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			data: [],
			loaded: true
		};

	}
	componentDidMount() {
		this.fetchTechnicians();
	}

	fetchTechnicians = () => {
		members.search({filtered: [{id: "roles", value: "TECHNICIEN_SAV"},{id: "enabled", value: true},{id: 'pagination', value: false}]}).then(result => {
			this.setState({ technicians: result['hydra:member'] })
		})
	}

	handleOpen=()=> {
		this.setState({
			open: true
		});
	}


	handleClose=()=> {
		this.setState({
			open: false,
			data: []
		});
		this.props.reload()
	}

	render() {
		const {loaded, data} = this.state;

		return (

			<Modal isOpen={this.state.open}  fade={false} size="lg">
				<ModalHeader toggle={() => this.setState({
					open: false,
					data: []
				})}>
					<h2>Vous avez appelé le client</h2>
				</ModalHeader>


				<AppointmentForm technicians={this.state.technicians} appointment={this.props.appointment} handleClose={this.handleClose}/>

			</Modal>

		);

	}
}

export default AppointmentPopup;
