import saveAs from 'file-saver';

import configApi from '../../constants/ApiConfig';
import { getJwtToken } from '../../utils/session';


const endPointsAppointment = "/api/appointment";
const endPoints = '/api/contracts';

export const exportCSV = data =>
    fetch(`${configApi.url}${endPoints}/export-csv`, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        },
        responseType: 'blob',
        body: JSON.stringify(data),
    })
        .then(response => response.blob())
        .then(blob => saveAs(blob, 'contracts.csv'));


export const exportAllCSV = data =>
    fetch(`${configApi.url}${endPoints}/export-all-csv`, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        },
        responseType: 'blob',
        body: JSON.stringify(data),
    })
        .then(response => response.blob())
        .then(blob => saveAs(blob, 'contracts.csv'));


export const exportArchivedCSV = data =>
    fetch(`${configApi.url}${endPoints}/export-archived-csv`, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        },
        responseType: 'blob',
        body: JSON.stringify(data),
    })
        .then(response => response.blob())
        .then(blob => saveAs(blob, 'contracts.csv'));

export const exportAppointmentCSV = data =>
	fetch(`${configApi.url}${endPointsAppointment}/exportCSV`, {
		mode: 'cors',
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getJwtToken()}`,
		},
		responseType: 'blob',
		body: JSON.stringify(data),
	})
		.then(response => response.blob())
		.then(blob => saveAs(blob, 'appointment.csv'));

