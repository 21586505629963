import React, {Component, useCallback} from 'react';
import {Button, Form} from 'reactstrap';
import {Field, reduxForm} from 'redux-form'
import {ModalBody, ModalFooter, Alert} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';
import {FieldTxt} from "../../../../../../components/Form/wh-field";
import * as contractService from '../../../../../../services/contract/index';
import debounce from 'lodash.debounce';
import {getDefaultData} from "../../../../utils";
import Loading from "../../../../../../components/Loading";

class PopupContractForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			client: {},
			firstName: "",
			lastName: "",
			showClient: false,
			open: false,
			loading: false,
		};
	}

	render() {
		const {error, handleSubmit, submitting} = this.props;

		const debouncedSave = debounce((value, type) => {
			search(value, type)
		}, 250);

		const search = (name, type) => {
			this.setState({[type]: name, showClient: false}, () =>
				contractService.search({
					filtered: [
						{id: "pageSize", value: 5},
						{id: "status", value: 300},
						{id: "createContrat", value: 1},
						{id: "customer.firstName", value: this.state.firstName},
						{id: "customer.lastName", value: this.state.lastName},
					]
				}).then((data) => {
					this.setState({
						client: data["hydra:member"],
						showClient: true
					})
				})
			)
		}

		const replaceContract = async (id) => {
			this.setState({loading: true})
			const contract = await contractService.view(id)
			contract.status = 900
			await contractService.update(contract)
			const dataToSend = getDefaultData()
			dataToSend.customer = contract.customer
			dataToSend.replaceDocument = contract.document
			dataToSend.contractInformation = contract.contractInformation
			dataToSend.sector = contract.sector ? contract.sector['@id'] : null
			dataToSend.teamTechnique = contract.teamTechnique ? contract.teamTechnique['@id'] : null
			await this.props.create(dataToSend)
			this.setState({loading: false})
		}

		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				{this.state.loading && <Loading/>}
				<ModalBody>
					<div>
						<Field
							label="Nom"
							name="lastName"
							onChange={value => debouncedSave(value.target.value, "lastName")}
							component={FieldTxt}
							type="text"
						/>
						<Field
							label="Prénom"
							name="firstName"
							onChange={value => debouncedSave(value.target.value, "firstName")}
							component={FieldTxt}
							type="text"
						/>
					</div>

					{this.state.showClient &&
						<div>
							<table className="table table-hover table-bordered">
								<tbody>
								<tr>
									<th className="col-popup-annul" scope="col">Nom</th>
									<th className="col-popup-annul" scope="col">Prénom</th>
									<th className="col-popup-annul" scope="col">Adresse</th>
									<th className="col-popup-annul" scope="col">Ville</th>
									<th className="col-popup-annul" scope="col">Contrat</th>
								</tr>
								{this.state.client && this.state.client.map((item, key) => (
									<tr key={key}>
										<th className="col-popup-annul" scope="col">{item.customer.lastName}</th>
										<th className="col-popup-annul" scope="col">{item.customer.firstName}</th>
										<th className="col-popup-annul" scope="col">{item.customer.address}</th>
										<th className="col-popup-annul" scope="col">{item.customer.city}</th>
										<th className="col-popup-annul" scope="col">
											<Button
												className="bg-dark  text-white rounded col-popup-annul"
												onClick={() =>
													replaceContract(item.id)
												}
											>Annule et remplace
											</Button>
										</th>
									</tr>
								))}
								</tbody>
							</table>
						</div>}

					{error &&
						<Alert color="danger">
							<div dangerouslySetInnerHTML={{__html: error}}/>
						</Alert>
					}


				</ModalBody>

				<ModalFooter>

					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={'submit'}
					>
						<i className="fa fa-save"></i>
						&nbsp;Créer le contrat
					</LaddaButton>

				</ModalFooter>

			</Form>
		)
	}
}

PopupContractForm = reduxForm({
	form: 'PopupContractForm'
})(PopupContractForm);


export default PopupContractForm
