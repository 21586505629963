import React, { Component } from 'react';
import {Row, Col, Button, CardHeader, Card} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { fetchEquipments } from '../Catalog/Equipment/redux';
import { fetchPrices } from '../Catalog/Price/redux';
import { fetchFamily } from '../Catalog/Family/redux';
import {
	syncAllContracts,
	getAllContractsToSyncList,
	fetchOnGoingContracts,
	getStatusFilter,
} from '../Contract/redux';
import { getIsOnline } from '../../components/OnlineStatus/redux';
import { loopOnTestSync } from './utils';
import { getCredentials } from '../Authentication/redux';
import callIfAuthenticated from '../../utils/callIfAuthenticated';
import ifContractIsToBeSynced from '../../utils/ifContractIsToBeSynced';
import WHTable from "../../components/WHTable";

class Sync extends Component {
	onSync = async () => {
		const {
			onFamilySync,
			onEquipmentsSync,
			onPricesSync,
			onContractsSync,
			onContractsFetch,
		} = this.props;

		const isThereContractsToSync =
			this.props.contracts.filter(ifContractIsToBeSynced).length > 0;

		const toBeSynced = [
			{
				method: onFamilySync,
				successMessage: 'Familles synchronisées',
				errorMessage: 'problème lors de la synchronisation des familles',
			},
			{
				method: onEquipmentsSync,
				successMessage: 'Equipements synchronisés',
				errorMessage: 'problème lors de la synchronisation des équipements',
			},
			{
				method: onPricesSync,
				successMessage: 'Tarifs synchronisés',
				errorMessage: 'problème lors de la synchronisation des tarifs',
			},
			{
				method: isThereContractsToSync ? onContractsSync : onContractsFetch,
				successMessage: 'Contrats synchronisés',
				errorMessage: 'problème lors de la synchronisation des contrats',
			},
		];

		callIfAuthenticated(() => loopOnTestSync(toBeSynced));
	};

	render() {
		const { isOnline } = this.props;
		const buttonColor = isOnline ? 'success' : 'danger';
		return (
			<div className="animated fadeIn">

				<Card className="border-card">
					<CardHeader>
						<h2>Synchronisation</h2>

					</CardHeader>

					<Button
						color={buttonColor}
						className="button-all-synced"
						size="lg"
						onClick={this.onSync}
						disabled={!isOnline}>
						<i className="fa fa-refresh fa-lg icone-button-text"> </i> Lancer la Synchronisation
					</Button>
				</Card>

			</div>
		);
	}
}
Sync.defaultProps = {
	contracts: [],
};
Sync.propTypes = {
	onFamilySync: PropTypes.func.isRequired,
	onEquipmentsSync: PropTypes.func.isRequired,
	onPricesSync: PropTypes.func.isRequired,
	onContractsSync: PropTypes.func.isRequired,
	isOnline: PropTypes.bool.isRequired,
	credentials: PropTypes.shape({}).isRequired,
	contracts: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
	isOnline: getIsOnline(state),
	credentials: getCredentials(state),
	contracts: getAllContractsToSyncList(state),
	statusFilter: getStatusFilter(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			onFamilySync: fetchFamily,
			onEquipmentsSync: fetchEquipments,
			onPricesSync: fetchPrices,
			onContractsSync: syncAllContracts,
			onContractsFetch: fetchOnGoingContracts,
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Sync);
