import React, {Component} from 'react';
import {Card, CardHeader, CardBody} from 'reactstrap';
import {Field, getFormValues} from 'redux-form';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {FieldSelect, FieldTxt} from '../../../../components/Form/wh-field';
import {getContractsStatus} from '../../redux';
import {formName} from "../../constants";

class ValidationForm extends Component {
	render() {
		const {
			status,
			isArchivedContract,
			isArchived,
			currentValues,
			userIsAdmin
		} = this.props;

		/*const fieldDisabled = this.props.currentValues.archived && this.props.currentValues.synchronised ? true : false;*/
		const fieldDisabled = isArchivedContract && !userIsAdmin

		return (
			<Card>
				<CardHeader>
					<h5 className="title-card-form"> <i style={{marginRight: 8}} className="fa fa-check"/> Validation</h5>
				</CardHeader>

				<CardBody>
					<Field
						label="Statut du dossier :"
						name="status"
						component={FieldSelect}
						options={status}
						disabled={fieldDisabled}
					/>

					<Field
						label="Note interne :"
						name="internNote"
						component={FieldTxt}
						type="textarea"
						disabled={false}
					/>

					<Field
						label="Rangement du dossier :"
						name="archived"
						component={FieldSelect}
						options={[
							{value: "false", name: 'Synchroniser'},
							{value: "true", name: 'Archiver'},
						]}
						disabled={fieldDisabled}

					/>
				</CardBody>
			</Card>
		)
	}
};

ValidationForm.propTypes = {
	status: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		})
	).isRequired,
	isArchived: PropTypes.bool.isRequired,
	currentValues: PropTypes.shape({}),
};

export const mapStateToProps = state => ({
	status: getContractsStatus(state),
	currentValues: getFormValues(formName)(state),
});

export default connect(mapStateToProps)(ValidationForm);
